import React from 'react';
import '../assets/css/userdashborad.css';
import Navbar from './Navbar'
import profileicon from '../assets/images/navbar/profileIcon.svg';
import dashboardicon from '../assets/images/profile/dashboard-icon.png'
import search from '../assets/images/profile/dashboard-search-icon.svg'
import caseIllustration from '../assets/images/profile/case-illustration.png'
import notificationIcon from '../assets/images/profile/user-dashboard-notification.png'
import filterIcon from '../assets/images/profile/Filter-icon.png'
import viewIcon from '../assets/images/profile/viewIcon.png'
import editIcon from '../assets/images/profile/editIcon.png'
import deleteIcon from '../assets/images/profile/deleteIcon.png'

function UserDashboard() {
    return (
        <>
            <Navbar />
            <div className="app">
                <div className='dashboard-main-container'>
                    <Sidebar />
                    <CasesDashboard />
                </div>
            </div>
        </>
    );
}

function Header() {
    return (
        <div className="dashboard-header">
            <SearchBar />
        </div>
    );
}

function SearchBar() {
    return (
        <div className="search-bar1">
            <div class="dashboard-search-container">
                <span class="dashboard-searc-icon ">
                    <img src={search} alt="Search Icon" class="icon-img" />
                </span>
                <input type="search" placeholder="Search cases by case number, court, or status" className="search-bar2" />
                <button className='dashboard-search'>
                    Search
                </button>
                <img src={notificationIcon} alt="" className='notificationIcon'/>
            </div>
        </div>
    );
}

function Sidebar() {
    return (
        <div className="user-dashboard-sidebar">
            <aside>
                <h6>Welcome</h6>
                <h1>Tushar Atri</h1>
                <div className="dashboard-button">
                    <button><img src={profileicon} alt="" />View Profile</button>
                    <button className="active"> <img src={dashboardicon} alt="" />Cases Dashboard</button>
                </div>
            </aside>
        </div>
    );
}

function CasesDashboard() {
    return (
        <div className="cases-dashboard">
            <Header />
            <FilterBar />
            <div className="case-cards">
                <CaseCard />
                {/* <CaseCard /> */}
            </div>
        </div>
    );
}

function FilterBar() {
    return (
        <div className="filter-bar">
            <img src={filterIcon} alt="" />
            <select>
                <option>Court Type</option>
                <option>Supreme Court</option>
                <option>High Court</option>
                <option>District Court</option>
            </select>
            <select>
                <option>Status</option>
                <option>Pending </option>
                <option>Closed</option>
                <option>Ongoing</option>

            </select>
            <select>
                <option>Date Range</option>
                <option> Last 7 days</option>
                <option>Last 30 days</option>
                <option>Custom</option>
            </select>
        </div>
    );
}

function CaseCard() {
    return (
        <div className="case-card">
            <div className="case-card-section">
                <div className="case-card-left-section">
                    <h3>Case Number</h3>
                    <p>Case Title</p>
                    <h3>Court</h3>
                    <p>Next date of hearing</p>
                    <p>Previous date of hearing</p>
                    <p>Status</p>
                </div>
                <div className="case-card-right-section">
                    <h3>SCG419</h3>
                    <p>State Vs Mehta</p>
                    <h3>Supreme Court</h3>
                    <p>12th December, 2024</p>
                    <p>4th June, 2024</p>
                    <p>Pending</p>
            <img src={caseIllustration} alt="" />
                </div>
            </div>
            <div className="actions">
                <button ><img src={viewIcon} alt="" />View</button>
                <button><img src={editIcon} alt="" />Edit</button>
                <button><img src={deleteIcon} alt="" />Delete</button>
            </div>
        </div>
    );
}

export default UserDashboard;