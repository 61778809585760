import React, { useState } from 'react';
import '../assets/css/CourtSelection.css';
import Navbar from './Navbar'
import rightcorner from '../assets/images/selectcourt/right-corner.png'
import leftcorner from '../assets/images/selectcourt/left-corner-illustration.png'
import center from '../assets/images/selectcourt/center-back.png'
import { useNavigate } from 'react-router-dom';
import mobileFlags from '../assets/images/mycases/mobileFlags.svg'
import NewNotificationBar from './NewNotificationBar'
import panjabhighcourt from '../assets/images/selectcourt/panjab_high_court.svg'
import '../assets/css/allhighcourt.css'
import { toast } from 'react-toastify';

function Allhighcourt() {
    const navigate = useNavigate();


    const handlehighcourt = () => {
        navigate('/high-court')
    }

    const handlePanjabHighCourt = () => {

        navigate('/ph_high-court')
    }

   

    const courts = [
        "Allahabad High Court",
        "Bombay High Court",
        "Calcutta High Court",
        "Gauhati High Court",
        "High Court for State of Telangana",
        "High Court of Andhra Pradesh",
        "High Court Of Chhattisgarh",
        "High Court of Gujarat",
        "High Court of Himachal Pradesh",
        "High Court of Jammu and Kashmir",
        "High Court of Jharkhand",
        "High Court of Karnataka",
        "High Court of Kerala",
        "High Court of Madhya Pradesh",
        "High Court of Manipur",
        "High Court of Meghalaya",
        "High Court of Orissa",
        // "High Court of Punjab and Haryana",
        "High Court of Rajasthan",
        "High Court of Sikkim",
        "High Court of Tripura",
        "High Court of Uttarakhand",
        "Madras High Court",
        "Patna High Court"
    ];

    const benches = {
        "Allahabad High Court": ["Allahabad High Court", "Allahabad High Court Lucknow Bench"],
        "Bombay High Court": ["Appellate Side,Bombay", "Bench at Aurangabad", "Bench at Nagpur", "High court of Bombay at Goa", "Original Side,Bombay"],
        "Calcutta High Court": ["Appellate side", "Circuit Bench At Jalpaiguri", "Circuit Bench At Port Blair", "Original Side"],
        "Gauhati High Court": ["Aizawl Bench", "Itanagar Bench", "Kohima Bench","Principal Seat at Guwahati"],
        "High Court  for State of Telangana": ["Principal Bench at Hyderabad"], 
        "High Court of Andhra Pradesh": ["Principlal Bench at Andhra Pradesh"],
        "High Court Of Chhattisgarh": ["Principlal Bench Chhattisgarh"],
        "High Court of Gujarat": ["Gujarat High Court"],
        "High Court of Himachal Pradesh": ["High Court of Himachal Pradesh"],
        "High Court of Jammu and Kashmir": ["Jammu Wing", "Srinagar Wing"],
        "High Court of Jharkhand": ["Principal Bench Jharkhand"],
        "High Court of Karnataka": ["Bench at Dharwad", "Bench at Kalburagi", "Principal Bench at Bengaluru"],
        "High Court of Kerala": ["High Court of Kerala"],
        "High Court of Madhya Pradesh": ["High Court Of Madhya Pradesh Gwalior","High Court Of Madhya Pradesh Indore","High Court Of Madhya Pradesh Jabalpur"],
        "High Court of Meghalaya": ["High Court of Meghalaya"],
        "High Court of Orissa": ["High Court of Orissa"],
        "High Court of Punjab and Haryana": ["High Court of Punjab and Haryana"],
        "High Court of Rajasthan": ["High Court Bench at Jaipur", "Rajasthan High Court Principal Seat Jodhpur"],
        "High Court of Sikkim": ["High Court of Sikkim"],
        "High Court of Tripura": ["High Court of Tripura"],
        "High Court of Uttarakhand": ["High Court of Uttarakhand"],
        "Madras High Court": ["Madras High Court","Madurai Bench of Madras High Court"],
        "Patna High Court": ["Principlal Bench Patna"],
        "High Court of Manipur": ["High Court of Manipur"]
    };

    const [selectedCourt, setSelectedCourt] = useState('');
    const [selectedBench, setSelectedBench] = useState('');
    const [benchOptions, setBenchOptions] = useState([]);

    const handleCourtChange = (e) => {
        const court = e.target.value;
        setSelectedCourt(court);
        setBenchOptions(benches[court] || []);
    };

    const handleBenchChange = (e) => {
        setSelectedBench(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.preventDefault();
        if (!selectedCourt || !selectedBench ) {
            toast('Please fill in all fields');
            return;
        }
        navigate('/all-high-court-privilege', { state: { court: selectedCourt, bench: selectedBench } });
    };


    return (
        <>
            <Navbar />
            <NewNotificationBar />
            <div className="container">
                <div className="header">
                    <h1><span style={{ color: '#5D54F1' }}>Choose the court </span>for your case</h1>
                    <p>Track Cases In Real Time. Stay Informed And Organised. Save Valuable Time.</p>
                </div>
                <div className="main-high-court-container">
                    <div className="all-dropdown">
                        <h6>All High Courts</h6>
                        <div className="all-high-court-form">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <select
                                        id="court"
                                        name="court"
                                        value={selectedCourt}
                                        onChange={handleCourtChange}
                                    >
                                        <option value="">Select High Court</option>
                                        {courts.map((court, index) => (
                                            <option key={index} value={court}>{court}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select
                                        id="bench"
                                        name="bench"
                                        value={selectedBench}
                                        onChange={handleBenchChange}
                                    >
                                        <option value="">Select Bench</option>
                                        {benchOptions.map((bench, index) => (
                                            <option key={index} value={bench}>{bench}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="submit-laptop1">
                                    <div className="laptop-submit1" >
                                        <button type="submit" >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="all-high-court-cards">
                        <div className="high_court_section">
                            <div class="card-stack main-panjab" onClick={handlehighcourt}>
                                <div class="card background-card2">
                                    <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/delhi_high_court.jpeg"
                                        alt="Background Image" c
                                        class="court-background-image"
                                        loading="lazy"
                                    />
                                    <div class="court-background-overlay"></div>
                                    <p>High Court of Delhi</p>
                                </div>
                                <div class="card foreground-card2">
                                    <div class="card-content">
                                        <p>High Court of Delhi</p>
                                    </div>
                                </div>
                            </div>
                            <h2 className='mobile-heading'>High Court of Delhi</h2>
                            <div class="card-stack main-panjab" onClick={handlePanjabHighCourt}>
                                <div class="card background-card2">
                                    <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/panjab_high_court.svg"
                                        alt="Background Image"
                                        class="court-background-image"
                                        loading="lazy"
                                    />
                                    <div class="court-background-overlay"></div>
                                    <p>High Court of <br /> Punjab & Haryana</p>
                                </div>
                                <div class="card foreground-card2">
                                    <div class="card-content">
                                        <p>High Court Of <br /> Punjab & Haryana</p>
                                    </div>
                                </div>
                            </div>
                            <h2 className='mobile-heading'>High Court of <br /> Panjab & Haryana</h2>
                        </div>
                        <img src={mobileFlags} alt="" className='mobileFlags' />
                    </div>
                </div>
            </div>
            <div className='court-bottom-image'>
                <img src={rightcorner} alt="" className='right-corner' />
                <img src={leftcorner} alt="" className='court-left-corner' />
                <img src={center} alt="" className='center-image' />
            </div>
        </>
    );
}

export default Allhighcourt;
