import React, { useState, useContext } from 'react';
import '../assets/css/Filteredlawyer.css';
import axiosInstance from '../utils/axiosInstance'; // Adjust the path as needed
import { useCaseContext } from '../CaseContext'; // Adjust the path as needed

function Filters({ isVisible, onClose }) {
    const [locations, setLocations] = useState([]);
    const [language, setLanguages] = useState([]);
    const [experience, setExperience] = useState('');
    const [minFee, setMinFee] = useState('');
    const [maxFee, setMaxFee] = useState('');
    const { setLawyersData } = useCaseContext();
    const access_token = sessionStorage.getItem('access_token');

    const handleCheckboxChange = (e, setState, state) => {
        const value = e.target.nextSibling.textContent;
        if (e.target.checked) {
            setState([...state, value]);
        } else {
            setState(state.filter(item => item !== value));
        }
    };

    const handleApply = async () => {
        const params = {};

        if (locations.length > 0) params.locations = locations.join(',');
        if (language.length > 0) params.languages = language.join(',');
        if (experience) params.experience = experience;
        if (minFee) params.minFee = minFee;
        if (maxFee) params.maxFee = maxFee;

        try {
            const response = await axiosInstance.get('search/searchlawyer/', {
                params,
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },
            });
            setLawyersData(response.data);
            onClose(); // Close the filter sidebar after applying filters
        } catch (error) {
            console.error('Error searching for lawyers:', error);
        }
    };

    return (
        <div className={`sidebar-search ${isVisible ? 'visible' : ''}`}>
            <button className="closeButton" onClick={onClose}>
                <i className="fas fa-times"></i>
            </button>
            <div className='searchOption'>
                <h4>Location</h4>
                <div className='firstHalf'>
                    <label>
                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, setLocations, locations)} />
                        All Locations
                    </label>
                    <label>
                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, setLocations, locations)} />
                        Nearby
                    </label>
                </div>
                <hr className='horizontalLine' />
                <div className="searchOption secondHalf">
                    <label>
                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, setLocations, locations)} />
                        Mumbai
                    </label>
                    <label>
                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, setLocations, locations)} />
                        Delhi
                    </label>
                    <label>
                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, setLocations, locations)} />
                        Chennai
                    </label>
                    <label>
                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, setLocations, locations)} />
                        Kolkata
                    </label>
                </div>
                <hr className='section-horizontalLine' />
            </div>
            <div className='searchOption'>
                <h4 className='mt-2'>Language</h4>
                <div className="firstHalf">
                    <label>
                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, setLanguages, language)} />
                        English
                    </label>
                    <label>
                        <input type="checkbox" onChange={(e) => handleCheckboxChange(e, setLanguages, language)} />
                        Hindi
                    </label>
                </div>
            </div>
            <hr className='horizontalLine' />
            <div className='searchOption'>
                <h4 className='mt-2'>Experience</h4>
                <div className="firstHalf">
                    <div className="experience-radio-buttons">
                        <label>
                            <input type="radio" name="experience" value="1" onChange={(e) => setExperience(e.target.value)} />
                            0-1 Year
                        </label>
                        <label>
                            <input type="radio" name="experience" value="3" onChange={(e) => setExperience(e.target.value)} />
                            1-3 Years
                        </label>

                    </div>
                </div>
            </div>
            <hr className='horizontalLine' />

            <div className='searchOption'>
                <h4 className='mt-2'>Consultation Fees</h4>
                <div className="firstHalf">
                    <label>Min: <input type="number" value={minFee} onChange={(e) => setMinFee(e.target.value)} /></label>
                    <label>Max: <input type="number" value={maxFee} onChange={(e) => setMaxFee(e.target.value)} /></label>
                    <div className="price-range-checkboxes">
                        <label>
                            <input type="checkbox" value="under-10000" />
                            Under 10,000
                        </label>
                        <label>
                            <input type="checkbox" value="10000-50000" />
                            10,000 to 50,000
                        </label>
                        <label>
                            <input type="checkbox" value="50000-100000" />
                            50,000 to 1,00,000
                        </label>
                        <label>
                            <input type="checkbox" value="100000-200000" />
                            1,00,000 to 2,00,000
                        </label>
                    </div>
                </div>
            </div>
            <div className="apply-button">
                <div className="filter-lawyer">
                    <button onClick={handleApply}>Apply</button>
                </div>
            </div>
        </div>
    );
}

export default Filters;
