// src/ScrollStack.js
import React from 'react';
import '../assets/css/CardStack.css';

const CardStack = ({ cards }) => {
    return (
        <div className="stack-card-main-container">
            <header className="fixed-container">
                <h2 className="centered-text"><span style={{color:'#5d54f1'}}>Why</span> Choose Us</h2>
            </header>
            <main className="feature-section">
                <div className="stack-comp">
                    <ul id="cards" >
                        {cards.map((card, index) => (
                            <li key={index} id={`card-${index + 1}`} className="stack-card" style={{ backgroundColor: card.backgroundColor }}>
                                <div className="card-content" style={{ backgroundColor: card.backgroundColor }}>
                                    <div className='card-right-side'>
                                        <h1 style={{ color: card.titleColor }}>{card.title}</h1>
                                        <p style={{color:card.contentColor }}>{card.content}</p>
                                    </div>
                                    <div className="card-left-side">
                                        <video
                                            src={card.video}
                                            alt={card.title}
                                            className='card-video'
                                            autoPlay
                                            loop
                                            muted
                                            playsInline // Ensures the video plays inline on mobile browsers
                                            preload="auto" // Ensures the video is preloaded
                                        />
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </main>
        </div>
    );
};

export default CardStack;
