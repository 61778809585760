import React, { useState } from 'react';
import '../assets/css/faq.css'
import Navbar from './Navbar';
import robot from '../assets/images/Chatbot/faq-chat-robo.svg'
import mobileRobot from '../assets/images/Chatbot/faq-mobile-robot.png'

const faqData = [
    {
        question: "What services does CaseMinister offer?",
        answer: "CaseMinister offers personalized legal consultation, case analysis, expert guidance, and access to a network of legal professionals. Users can also create tailored legal documents and track their cases through the platform."
    },
    {
        question: "What makes CaseMinister different from traditional legal consultation services?",
        answer: "CaseMinister combines technology with legal expertise to offer transparent and accessible legal guidance to everyone. Our platform streamlines the consultation process, making it user-friendly and efficient."
    },
    {
        question: "Can users track and manage their cases through CaseMinister’s platform?",
        answer: "Yes, CaseMinister provides a case management system that tracks and organizes relevant case details, allowing users to stay informed and in control of their legal proceedings."
    },
    // {
    //     question: "How does CaseMinister identify top-performing lawyers, and how are they matched with users?",
    //     answer: "CaseMinister uses a combination of data analytics and user feedback to identify top-performing lawyers. These lawyers are then matched with users based on their specific legal needs and preferences."
    // },
    {
        question: "How does CaseMinister ensure accessibility across different languages and cultures?",
        answer: "CaseMinister ensures accessibility by offering services in multiple languages and adapting the platform to be culturally sensitive and inclusive."
    }
];

const FAQItem = ({ question, answer, isOpen, onClick }) => {

    return (
        <>
            <div className="faq-item">
                <div className="faq-question" onClick={onClick}>
                    <span>{question}</span>
                    <span>{isOpen ? '−' : '+'}</span>
                </div>
                {isOpen && <div className="faq-answer">{answer}</div>}
            </div>
        </>
    );
};

const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleFaqClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <>
            <Navbar />
            <div className="faq-container">
                <div className='faq-left-part'>
                    <h1><span style={{ color: '#5D54F1' }}>Frequently</span> Asked Questions</h1>
                    <p>Welcome to our FAQ page! We've compiled a list of commonly asked questions to provide you with quick and informative answers.</p>
                    <div className="contact-box">
                        <p className='upper-part2' style={{ color: 'black', fontSize: '16px' }}>Still have a question?</p>
                        <p>You can submit your question or request through our contact form. Please provide as much detail as possible so that we can assist you effectively.</p>
                        <a href="mailto:info@caseminister.com?subject=Contact Request&body=Hi there, I would like to get in touch regarding..."  target="_blank">
                            <button>Get in touch</button>
                        </a>
                    </div>
                </div>
                <div className='faq-right-part'>
                    <div className="faq-list">
                        {faqData.map((item, index) => (
                            <FAQItem key={index}
                            question={item.question}
                            answer={item.answer} 
                            isOpen={openIndex===index}
                            onClick={()=>handleFaqClick(index)}
                            />
                        ))}
                    </div>
                </div>
                <div className="mobile-contact-box">
                    <p className='upper-part2' style={{ color: 'black', fontSize: '16px' }}>Still have a question?</p>
                    <p>You can submit your question or request through our contact form. Please provide as much detail as possible so that we can assist you effectively.</p>
                    <a href="mailto:info@caseminister.com?subject=Contact Request&body=Hi there, I would like to get in touch regarding..." target="_blank">
                        <button>Get in touch</button>
                    </a>
                </div>
                    <img src={mobileRobot} alt="" className='mobile-faq-bottom-image' />
        <img src={robot} alt="" className='faq-bottom-image' />
            </div>
        </>
    );
};

export default Faq;
