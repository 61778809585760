import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Navbar.css';
import logo from '../assets/images/navbar/legal-tech-logo.svg';
import 'aos/dist/aos.css';
import { debounce } from 'lodash';
import profileIcon from '../assets/images/navbar/profileVector.svg';
import notificationIcon from '../assets/images/navbar/notificationIcon.svg';
import signout from '../assets/images/Chatbot/Sign_out_squre.svg';
import profileicon from '../assets/images/navbar/profileIcon.svg';
import contactusIcon from '../assets/images/navbar/contactusIcon.svg';
import NotificationDropdown from './NotificationDropdown'
import aiDrivenMobile from '../assets/images/navbar/ai-divien-chatbot-mobile.svg';
import liveCaseTracking from '../assets/images/navbar/live-case-tracking-mobile.svg';
import performanceAnalytics from '../assets/images/navbar/performance_analytics-mobile.svg';
import documentationAndCustomization from '../assets/images/navbar/documentation_customization_mobile.svg';
import { useNavigate } from 'react-router-dom';
import { useCaseContext } from '../CaseContext';
import { useAuth } from '../Authcontext';
import aboutus from '../assets/images/navbar/about_us.svg'
import pressRelease from '../assets/images/navbar/press_release.svg'
import faq from '../assets/images/navbar/faq.svg'
import newsAndBlogs from '../assets/images/navbar/news.svg'
import { AbstractPage } from 'openai/core.mjs';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [notificationDropdownOpen, setNotificationDropdownOpen] = useState(false);
  const notificationRef = useRef(null);
  const { unreadNotifications, setUnreadNotifications, fetchNotifications, handleCategoryClick, filteredNotifications } = useCaseContext();
  const { login, isAuthenticated } = useAuth();

  const handleScroll = debounce(() => {
    setScrolled(window.scrollY > 0);
  }, 100);

  const access_token = sessionStorage.getItem('access_token');

  useEffect(() => {
    if (access_token) {
      fetchNotifications();
    }
  }, [access_token]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
        setDropdownOpen1(false)
      }
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setNotificationDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
    setClicked(!clicked);
  };

  const toggleDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDropdown1 = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDropdownOpen1(!dropdownOpen1);
  };
  const toggleNotificationDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setNotificationDropdownOpen(!notificationDropdownOpen);
  };

  const handleButtonClick = (path) => {
    if (!isAuthenticated) {
      sessionStorage.setItem('intendedURL', path);
      navigate('/signup');
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <div className='main-nav' style={{ color: 'black' }}>
        <nav className='nav-container'>
          <div className='main-logo'>
            <a href="/"><img src={logo} alt="logo" style={{ height: '50px', width: '75px' }} /></a>
          </div>
          <div>
            <ul id='navbar' className={clicked ? "#navbar active" : "#navbar"}>
              <li style={{ marginBottom: '0px' }}>
                <a href="/">Home</a>
              </li>

              <li className="dropdown" style={{ marginBottom: '0px' }}>
                <button onClick={toggleDropdown} className="dropbtn">
                  Features <i className={`fa ${dropdownOpen ? 'fa-caret-up' : 'fa-caret-down'}`} style={{ color: '#5D54F1' }}></i>
                </button>
                {dropdownOpen && (
                  <div ref={dropdownRef} className={`dropdown-content ${dropdownOpen ? 'show-dropdown' : ''}`}>
                    <div className='dropdown-menus' onClick={() => handleButtonClick('/talkaboutcase')}>
                      <a>
                        <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/drop1.svg"
                          alt="AI-Driven Chatbot"
                          className="mobileImage"
                          loading="lazy"
                          style={{ width: '95px' }} />
                      </a>
                      <img src={aiDrivenMobile} alt="" className="navbarLaptopImage" />
                      <button className='beta_access'>BETA Access</button>
                      <p style={{ cursor: 'pointer' }}>
                        AI-Driven Chatbot
                      </p>
                    </div>
                    <div className='dropdown-menus' onClick={() => handleButtonClick('/mycases')}>
                      <a>
                        <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/drop2.svg" alt="Live Case Tracking" className="mobileImage" loading="lazy" />
                      </a>
                      <img src={liveCaseTracking} alt="" className="navbarLaptopImage" />
                      <button className='beta_access'>BETA Access</button>
                      <p onClick={() => handleButtonClick('/mycases')} style={{ cursor: 'pointer' }}>
                        Live Case Tracking
                      </p>
                    </div>
                    <div className='dropdown-menus' onClick={() => { window.location.href = '/upload_judgement'; }}>
                      <a>
                        <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/document_analysis.svg" alt="Performance Analytics" className="mobileImage" loading="lazy" />
                      </a>
                      <img src={performanceAnalytics} alt="" className="navbarLaptopImage" />
                      <button className='beta_access'>BETA Access</button>
                      <p style={{ cursor: 'pointer' }}>
                        Document Analysis
                      </p>
                    </div>
                    <div className='dropdown-menus' onClick={() => { window.location.href = '/performance-analytic--coming-soon'; }}>
                      <a>
                        <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/drop3.svg" alt="Performance Analytics" className="mobileImage" loading="lazy" />
                      </a>
                      <img src={performanceAnalytics} alt="" className="navbarLaptopImage" />
                      <button className='coming-soon-feature'>Coming Soon</button>
                      <p style={{ cursor: 'pointer' }}>
                        Performance Analytics
                      </p>
                    </div>
                    <div className='dropdown-menus' onClick={() => { window.location.href = '/documentation-and-customization-coming-soon'; }}>
                      <a>
                        <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/drop4.svg" alt="Documentation & Customisation" className="mobileImage" loading="lazy" />
                      </a>
                      <img src={documentationAndCustomization} alt="" className="navbarLaptopImage" />
                      <button className='coming-soon-feature'>Coming Soon</button>
                      <p style={{ cursor: 'pointer' }}>
                        Documentation & Customisation
                      </p>
                    </div>
                  </div>
                )}
              </li>
              <li>
                <a href="/pricing">Pricing</a>
              </li>

              <li className="dropdown" style={{ marginBottom: '0px' }}>
                <button onClick={toggleDropdown1} className="dropbtn">
                  About Us <i className={`fa ${dropdownOpen1 ? 'fa-caret-up' : 'fa-caret-down'}`} style={{ color: '#5D54F1' }}></i>
                </button>
                {dropdownOpen1 && (
                  <div ref={dropdownRef} className={`dropdown-content1 ${dropdownOpen ? 'show-dropdown' : ''}`}>
                    <div className="dropdown-right">
                      <div className='dropdown-menus1' >
                        <div className="about-main-content" onClick={() => { window.location.href = '/'; }}>
                          <div className="about_dropdown-right">
                            <img src={aboutus} alt="" />
                          </div>
                          <div className="about_dropdown-left">
                            <h4>ABOUT US</h4>
                            <p>Know our story</p>
                          </div>
                        </div>
                        <div className='dropdown-menus1'>
                          <div className="about-main-content" onClick={() => { window.location.href = '/faq'; }}>
                            <div className="about_dropdown-right">
                              <img src={faq} alt="" />
                            </div>
                            <div className="about_dropdown-left">
                              <h4>FAQ</h4>
                              <p>Frequently Asked Questions</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-left">
                      <div className='dropdown-menus1' style={{ border: 'none' }}>
                        <div className="about-main-content" onClick={() => { window.location.href = '/'; }}>
                          <div className="about_dropdown-right">
                            <img src={pressRelease} alt="" />
                          </div>
                          <div className="about_dropdown-left">
                            <h4>PRESS RELEASE</h4>
                            <p>New announcement for media</p>
                          </div>
                        </div>
                      </div>
                      <div className='dropdown-menus1' style={{ border: 'none' }}>
                        <div className="about-main-content" onClick={() => { window.location.href = '/'; }}>
                          <div className="about_dropdown-right">
                            <img src={newsAndBlogs} alt="" />
                          </div>
                          <div className="about_dropdown-left">
                            <h4>NEWS & BLOGS</h4>
                            <p>Latest updates & insights</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
              <div className='menu-items-mobile'>
                <li className='contact'>
                  <img src={contactusIcon} alt="" />
                </li>
              </div>
              <div className='logout-container'>
                <button className='logout-button' onClick={handleLogout}>
                  <div className="text">
                    Log Out
                  </div>
                  <div className="logout-img">
                    <img src={signout} alt="sign out" />
                  </div>
                </button>
                <div className="profileImag">
                  <a href="contactus" style={{ textDecoration: 'none' }}>
                    <span style={{ color: "#5d54f1", fontSize: '18px', fontWeight: '500', margin: '10px' }}>Contact Us</span>
                    <img src={contactusIcon} alt="" />
                  </a>
                </div>
              </div>
            </ul>
            {!sessionStorage.getItem('isAuthenticated') ? (
              <>
                <div className='login-signup1'>
                  <a href="/signup"><button className='signup-button'>Signup</button></a>
                  <a href="/login"><button className='nav-login'>Login</button></a>
                </div>
              </>
            ) : (
              <div className='login-signup2'>
                <li>
                  <a href="/user-profile"><img src={profileIcon} alt="" className='profileImg' /></a>
                </li>
                <li>
                  <img src={notificationIcon} alt="" className='notificationImage' onClick={toggleNotificationDropdown} style={{ cursor: 'pointer' }} />
                  {unreadNotifications.length > 0 && (
                    <span className="notification-badge">{unreadNotifications.length}</span>
                  )}
                  <NotificationDropdown
                    isOpen={notificationDropdownOpen}
                    toggleDropdown={toggleNotificationDropdown}
                  />
                </li>
              </div>
            )}
          </div>
          <div className='left-corner'>
            <div className='menu-items'>
              <li>
                <a href='/contactus'><img src={contactusIcon} alt="" /></a>
              </li>
            </div>
            {!sessionStorage.getItem('isAuthenticated') ? (
              <>
                <div className='login-signup'>
                  <a href="/signup"><button className='signup-button'>Signup</button></a>
                  <a href="/login"><button className='nav-login'>Login</button></a>
                </div>
              </>
            ) : (
              <div className='login-signup'>
                {/* ({unreadNotifications.length}) */}
                <li className='dropdown' onClick={toggleNotificationDropdown}>
                  <img src={notificationIcon} alt="Notifications" className="navbarImage" style={{ marginRight: '-13px', cursor: 'pointer' }} />
                  {unreadNotifications.length > 0 && (
                    <span className="notification-badge">{unreadNotifications.length}</span>
                  )}
                  <NotificationDropdown
                    isOpen={notificationDropdownOpen}
                    toggleDropdown={toggleNotificationDropdown}
                  />
                </li>
                <li>
                  <a href="/user-profile"><img src={profileicon} alt="" /></a>
                </li>
                <a href="/" onClick={handleLogout}><button className='nav-login'>Logout</button></a>
              </div>
            )}
          </div>

          <div id='mobile' onClick={handleClick}>
            <i id='bar' className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
