import React, { useState, useEffect } from 'react';
import '../assets/css/NewLogin.css';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import google from '../assets/images/signup/google_2702602.png'
import { jwtDecode as jwt_decode } from 'jwt-decode';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Authcontext';
import logo from '../assets/images/navbar/legal-tech-logo.svg';
import { ToastContainer, toast } from 'react-toastify';
import leftimage from '../assets/images/signup/left-login-image.svg'
import loginvec from '../assets/images/signup/login-back-vector.svg'
import Navbar from './Navbar'
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);


    const { login } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const storedRememberMe = localStorage.getItem('rememberMe');
        const storedEmail = localStorage.getItem('rememberedEmail');
        const storedPassword = localStorage.getItem('rememberedPassword');

        if (storedRememberMe === 'true' && storedEmail && storedPassword) {
            setEmail(storedEmail);
            setPassword(storedPassword);
            setRememberMe(true);
        }
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const authHandler = (err, data) => {
        console.log(err, data);
    };

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };
    const responseFacebook = (response) => {
        console.log(response);
    }

    const handleFacebookLoginSuccess = (response) => {
        // Handle successful Facebook login
        console.log('Facebook login successful:', response);
        // Call additional callback function if needed
        additionalCallbackFunction(response);
    };

    const handleFacebookLoginFailure = (error) => {
        // Handle failed Facebook login
        console.error('Facebook login failed:', error);
        // Call additional callback function if needed
        additionalCallbackFunction(null, error);
    };

    const additionalCallbackFunction = (response, error) => {
        // Additional logic based on Facebook login result
        if (response) {
            // Perform actions for successful login
        } else {
            // Perform actions for failed login or error
        }
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        if (!email.includes("@")) {
            toast.error('Please enter the valid email address.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!password.trim()) {
            toast.error('Please fill in the password field.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        setIsLoading(true);
        // setError(null);
        setMessage('Logging in...');

        try {
            const result = await axiosInstance.post('api/signin/', { email, password }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                withCredentials: true,
            });
            // sessionStorage.setItem('user-info', JSON.stringify(result.data));
            sessionStorage.setItem('access_token', result.data.access);

            if (rememberMe) {
                localStorage.setItem('rememberedEmail', email);
                localStorage.setItem('rememberedPassword', password);
                localStorage.setItem('rememberMe', 'true');
            } else {
                localStorage.removeItem('rememberedEmail');
                localStorage.removeItem('rememberedPassword');
                localStorage.setItem('rememberMe', 'false');
            }

            const access_token = sessionStorage.getItem('access_token');
            const profileresult = await axiosInstance.get('api/profile/', {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Accept': 'application/json'
                }
            })
            sessionStorage.setItem('user-info', JSON.stringify(profileresult.data));
            const intendedURL = sessionStorage.getItem('intendedURL');
            sessionStorage.removeItem('intendedURL');
            login();
            navigate(intendedURL || "/");

        } catch (error) {
            console.log(error)
            if (error.response) {
                toast.error(`${error.response.data.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            else {
                // setError('Account does not exist with provided detail');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const googleSuccess = (response) => {
        const credentialResponseDecode = jwt_decode(response.credential);
        const id_token = response.credential
        // Using axios for sending data to the server
        axiosInstance.post('api/google-login/', { id_token }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                console.log('Server response:', response);
                sessionStorage.setItem('user-info', JSON.stringify(response.data));
                sessionStorage.setItem('access_token', response.data.access); // Make sure your backend is sending this data
                const intendedURL = sessionStorage.getItem('intendedURL');
                sessionStorage.removeItem('intendedURL');
                login();
                navigate(intendedURL || "/");
            })
            .catch(error => {
                console.error('Error:', error);

            });
    };

    const googleFailure = (error) => {

        toast.error(('Google sign in failed. Please try again.'), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const loginwithgoogle = GoogleLogin({
        onSuccess: googleSuccess,
        onError: (error) => console.error('Google Login Error:', error)
    });

    return (
        <>
        <Navbar/>
            <div className='whole-login'>
                <div className='Login-upper-section'>
                    <img src={logo} alt="" />
                    <h3><span style={{ color: '#5D54F1' }}>Welcome</span> Back!</h3>
                    <p>Please enter your Login credentials. It’s quick and easy.</p>
                </div>
                <div className='centered-box-container'>
                    <div class="background-image"></div>
                    <div class="centered-box">
                <div className="mobile-upper-section">
                    <h5><span style={{ color: '#5D54F1' }}>Welcome</span> Back!</h5>
                    <p>Please enter your Login credentials. It’s quick and easy.</p>
                </div>
                        <div className="login-container">
                            <div className='google-button mt-3'>
                                <GoogleLogin
                                    buttonText="Login with Google"
                                    onSuccess={googleSuccess}
                                    onError={googleFailure}
                                    render={renderProps => (
                                        <button
                                            onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                maxWidth: '400px',
                                                height: '50px',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '25px',
                                                padding: '0 25px',
                                                fontSize: '16px',
                                                cursor: 'pointer',
                                                boxShadow: '0 2px 4px rgba(0,0,0,0.25)',
                                                margin: '10px 0',
                                            }}
                                        >
                                            <img
                                                src={google}
                                                alt="Google Icon"
                                                style={{
                                                    marginRight: '12px',
                                                    height: '24px',
                                                    width: '24px'
                                                }}
                                            />
                                            <span className="google-text">Sign in With Google</span>
                                        </button>

                                    )}
                                />
                            </div>
                        </div>
                        <div className="or-login-container">
                            <span className="line"></span>
                            <span className="or-text">or</span>
                            <span className="line"></span>
                        </div>
                        <form class="login-form">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="example@gmail.com"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group" style={{ position: 'relative' }}>
                                <label htmlFor="password">Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    placeholder="Your Password"
                                    value={password}
                                    onChange={(e)=>setPassword(e.target.value)}
                                    style={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        paddingRight: '40px' // to make space for the icon
                                    }}
                                    required
                                />
                                <span
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-15%)',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                            </div>
                            {/* <div class="form-options">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="remember"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    /> Remember Me
                                </label>
                                <div class="forgot-password">
                                    <a href="#"><span style={{ color: '#000' }}>Forgot Password?</span></a><a href="#">Reset Password</a>
                                </div>
                            </div> */}
                            <div class="form-options">
                                <label class="login-checkbox-container">
                                    <input type="checkbox" name="terms"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    />
                                    <span class="login-checkbox-custom"></span>
                                    <span class="login-checkbox-text">Remember Me</span>
                                </label>
                                <div class="forgot-password">
                                    <a href="#"><span style={{ color: '#000' }}>Forgot Password?</span></a><a href="#"><span style={{fontWeight:'600'}}>Reset Password</span></a>
                                </div>
                            </div>
                            <div class="form-footer">
                                {/* <button type="submit" onClick={handleSignIn}>Log In</button> */}
                                <button type="submit" onClick={handleSignIn} disabled={isLoading}>
                                    {isLoading ? <div className="loader"></div> : "Log In"}
                                </button>
                                <p style={{ marginTop: '20px', marginBottom: '0px' }}>
                                    By clicking on Log In, you agree to our terms of service Privacy policy.
                                </p>
                            </div>
                            <div class="form-footer">
                                <p> <span style={{ color: '#000' }}>Don't have an account ? </span><a href="/signup"><span style={{ fontWeight: '600' }}>Signup here</span></a></p>
                            </div>
                        </form >
                        <div className="bottom-img">
                            <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/left-login-image.svg" alt="Decorative Image" class="login-left-image" />
                        </div>
                    </div>
                </div>
                <img src={loginvec} alt="" className='login-back-vec'/>
            </div>
        </>
    );
}

export default LoginPage;
