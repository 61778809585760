import React, { createContext, useContext, useState } from 'react';
import axiosInstance from './utils/axiosInstance';

const CaseContext = createContext();

export const useCaseContext = () => useContext(CaseContext);

export const CaseProvider = ({ children }) => {
    const [selecteSupremeCourtCase, setSelecteSupremeCourtCase] = useState(null);
    const [selectPanjabCourtCase, setSelectPanjabCourtCase] = useState(null);
    const [selectedHighCourtCase, setSelectedHighCourtCase] = useState(null);
    const [selectAllHighCourt, setSelectAllHighCourt] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [lawyersData, setLawyersData] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [category, setCategory] = useState('all');
    const [propmtsVisibel, setPropmtsVisibel] = useState(false);

    const accessToken = sessionStorage.getItem("access_token");

    const fetchNotifications = async () => {
        try {
            const allResponse = await axiosInstance.get('notify/notification/', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const allNotifications = allResponse.data;
            setNotifications(allNotifications);

            const unreadResponse = await axiosInstance.get('notify/notification/?read=false', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            const unreadNotifications = unreadResponse.data;
            setUnreadNotifications(unreadNotifications);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };
    const handleCategoryClick = (event, category) => {
        event.stopPropagation();
        setCategory(category);
    };

    const filteredNotifications = category === 'all' ? notifications : unreadNotifications;


    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };  

    const goBack=()=>{  
        setSelectedHighCourtCase(null)
    }
    
    const goBackSupremeCourt = () => {
        setSelecteSupremeCourtCase(null)
    }

    const goBackPanjabCourt =()=>{
        setSelectPanjabCourtCase(null)
    }

    const goBackAllHighCourt = () => {
        setSelectAllHighCourt(null)
    }

    return (
        <CaseContext.Provider value={{ selectedHighCourtCase, setSelectedHighCourtCase, goBack, toggleSidebar, isOpen, lawyersData, setLawyersData, unreadNotifications, setUnreadNotifications, fetchNotifications, notifications,
            handleCategoryClick,
            filteredNotifications,
            setPropmtsVisibel,
            propmtsVisibel,
            goBackSupremeCourt,
            setSelecteSupremeCourtCase,
            selecteSupremeCourtCase,
            selectPanjabCourtCase,
            setSelectPanjabCourtCase,
            goBackPanjabCourt,
            selectAllHighCourt,
            setSelectAllHighCourt,
            goBackAllHighCourt
         }}>
            {children}
        </CaseContext.Provider>
    );
};
