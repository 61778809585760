import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/LandingPage.css'; // Ensure the path is correct
import nvidia from '../assets/images/landingpage/nvidia.svg';
import microsoft from '../assets/images/landingpage/microsoft.svg';
import aws from '../assets/images/landingpage/aws.svg';

const images = [
    { id: 1, image: microsoft, height: '100px', margin: '0px', content: 'Microsoft' },
    { id: 2, image: nvidia, height: '100%', margin: '0px', content: 'NVIDIA' },
    // { id: 3, image: aws, height: '50px', margin: '25px', content: 'AWS' },
];

const LandingPage = () => {
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: false,
        centerPadding: '0',
        arrows: false, 
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                },
            },
        ],
    };

    return (
        <div className="integrated-with">
            <Slider {...settings}>
                {images.map((img) => (
                    <div key={img.id} className="slide">
                        <div className="integrated-image-box">
                            <img
                                src={img.image}
                                alt={img.content}
                                style={{ height: img.height, margin: img.margin }}
                                className="testimonial-image"
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default LandingPage;
