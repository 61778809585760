import axios from 'axios';

// Utility function to check if the token is expired
const isTokenExpired = () => {
    const token = sessionStorage.getItem('access_token');
    if (!token) return true;
    try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        return currentTime > payload.exp;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
};

const axiosInstance = axios.create({
    baseURL: 'https://api.caseminister.com/',
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        // Check specifically for a 401 Unauthorized response
        if (error.response && error.response.status === 401) {
            if (isTokenExpired()) {
                // Perform the session cleanup
                sessionStorage.removeItem('access_token');
                sessionStorage.removeItem('user-info');
                sessionStorage.removeItem('isAuthenticated');

                // Only navigate to login if the token has expired
                window.location.href = '/signup';
            }
        }
        // For all other errors, just return the promise rejection
        return Promise.reject(error);
    }
);

export default axiosInstance;
