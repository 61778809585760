import React, { useState, useContext, useEffect, useRef } from 'react';
import { FolderContext } from '../FolderContext';
import axiosInstance from '../utils/axiosInstance';
import Navbar from './Navbar';
import '../assets/css/upload_judgement.css';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import folderVec from '../assets/images/upload_judgement/folderVec.svg';
import fileIcon from '../assets/images/upload_judgement/fileIcon.svg';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteButton from '../assets/images/upload_judgement/DeleteButton.svg'

const CHUNK_SIZE = 1000 * 1024; // 1MB

const UploadJudgement = () => {
    const [folder_name, setFolderName] = useState('');
    const [files, setFiles] = useState([]);
    const [folderCreated, setFolderCreated] = useState(false);
    const [createKnowledgeBase, setCreateKnowledgeBase] = useState(false);
    const [folder_id, setFolderId] = useState('');
    const access_token = sessionStorage.getItem("access_token");
    const [showCreateChatroomButton, setShowCreateChatroomButton] = useState(false);
    const [knowledgeBase, setKnowledgeBase] = useState(false);
    const { folders, getFolders } = useContext(FolderContext);
    const navigate = useNavigate();
    const [Isloading, setIsLoading] = useState(false);
    const [Isloading1, setIsLoading1] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Overall upload progress
    const [folderdata, setFoldersData] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [folderName, setfolderName] = useState('');
    const [visibleOptions, setVisibleOptions] = useState(null);
    const fileInputRef = useRef(null);

    
    const handleFolderNameChange = (e) => {
        setFolderName(e.target.value);
    };


    useEffect(() => {
        if (folders.length > 0 && !selectedFolder) {
            const initialFolder = folders[0];
            setSelectedFolder(initialFolder);
            setFolderId(initialFolder.id);
            handleFolder1(initialFolder.id, initialFolder.folder_name);
        }
    }, [folders, selectedFolder]);

    useEffect(() => {
        getFolders();
        handleFolder1(folder_id, folder_name);
    }, []);

    useEffect(() => {
        if (folders.length > 0) {
            setShowCreateChatroomButton(true);
        } else {
            setShowCreateChatroomButton(false);
        }
    }, [folders]);

    const handleFolder1 = async (folderId, folderName) => {
        const folder = folders.find(f => f.id === folderId);
        setFolderId(folderId);
        setUploadProgress(0);
        try {
            const result = await axiosInstance.get(`/assistant/userfile/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    folder_id: folderId
                }
            });

            console.log(result.data);
            setFoldersData(result.data);
        } catch (err) {
            console.log(err);
        }
        setSelectedFolder(folder);
        setfolderName(folderName);
    };

    const handleCreateFolder = async () => {
        if (!folder_name) {
            toast('Please enter a folder name.');
            return;
        }
        setIsLoading1(true)
        try {
            const response = await axiosInstance.post('/assistant/userfolder/', { folder_name }, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            });

            setFolderName('');

            if (response.data.id) {
                setFolderCreated(true);
                setSelectedFolder(response.data.id)
                setFolderId(response.data.id);
                handleFolder1(response.data.id, response.data.name)
                getFolders(); 
                toast("folder is created successfully");
            } else {
                toast('Error creating folder');
            }
        } catch (error) {
            console.error('Error creating folder:', error);
            toast('Error creating folder');
        }
        finally{
            setIsLoading1(false);
        }
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);
        if (selectedFiles.length > 0) {
            handleSubmit(selectedFiles);
        }
        fileInputRef.current.value = null;
    };

    const handleSubmit = async (selectedFiles) => {
        if (selectedFiles.length === 0) {
            toast.error('Please select at least one file to upload.');
            return;
        }

        setIsLoading(true);

        let totalUploaded = 0;
        const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];

            if (file.size > CHUNK_SIZE) {
                await uploadFileInChunks(file, (uploaded) => {
                    totalUploaded += uploaded;
                    setUploadProgress(Math.round((totalUploaded / totalSize) * 100));
                });
            } else {
                await uploadSingleFile(file, (uploaded) => {
                    totalUploaded += uploaded;
                    setUploadProgress(Math.round((totalUploaded / totalSize) * 100));
                });
            }
            handleFolder1(folder_id, folderName)
        }

        setIsLoading(false);
        setUploadProgress(0);
        setFolderCreated(true);
        setFiles([]);
        toast('Your files have been uploaded successfully');
    };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setCreateKnowledgeBase(isChecked);
        if (isChecked) {
            handleCreateKnowledgeBase();
        }
    };

    const handleCreateKnowledgeBase = async () => {
        try {
            const response = await axiosInstance.post('/assistant/vectordb/', { folder_id }, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            });

            if (response.data.folder_id) {
                toast('Knowledge base created successfully');
                setKnowledgeBase(true);
                setCreateKnowledgeBase(false);
            } else {
                toast('Error creating knowledge base');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.folder_id) {
                const errorMessage = error.response.data.folder_id[0];
                toast(`Error creating knowledge base: ${errorMessage}`);
            } else {
                console.error('Error creating knowledge base:', error);
                toast('Error creating knowledge base');
            }
        }
    };

    const handleCreateChatroom = async () => {
        try {
            const response = await axiosInstance.get('chatapp/chat-room/', {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                }
            });

            const result = await axiosInstance.post('chatapp/chat-room/', {
                folder_id: folder_id,
                is_assistant: true
            }, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                }
            });

            console.log("Chat room created:", response.data);
            navigate('/talkaboutcase');
        } catch (err) {
            console.log(err);
        }
    };

    async function uploadFileInChunks(file, onProgress) {
        const fileId = uuidv4();
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        let totalUploaded = 0;

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('file', chunk);

            const chunkInfo = {
                chunk_number: chunkIndex,
                total_chunks: totalChunks,
                file_name: file.name,
                file_id: fileId,
                folder_id: folder_id,
            };

            formData.append('chunk_info', JSON.stringify(chunkInfo));

            console.log(`Uploading chunk ${chunkIndex + 1} of ${totalChunks}`);

            try {
                const response = await axiosInstance.post('/assistant/userfile/upload_chunk/', formData, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        totalUploaded += progressEvent.loaded;
                        onProgress(progressEvent.loaded);
                    }
                });

                if (response.status !== 200) {
                    throw new Error(`Error uploading chunk ${chunkIndex + 1}/${totalChunks}`);
                }
                console.log(`Chunk ${chunkIndex + 1}/${totalChunks} uploaded successfully`);
            } catch (error) {
                console.error(`Error uploading chunk ${chunkIndex + 1}/${totalChunks}:`, error);
                return;
            }
        }

        console.log(`File ${file.name} uploaded successfully`);
        onProgress(file.size - totalUploaded);
    }

    const uploadSingleFile = async (file, onProgress) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folder_id', folder_id);
        formData.append('file_name', file.name);
        try {
            const response = await axiosInstance.post('/assistant/userfile/', formData, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    onProgress(progressEvent.loaded);
                }
            });

            if (response.status !== 200) {
                throw new Error(`Error uploading file ${file.name}`);
            }
            console.log(`File ${file.name} uploaded successfully`);
        } catch (error) {
            console.error(`Error uploading file ${file.name}:`, error);
        }
    };

    const handleOptionToggle = (index) => {
        setVisibleOptions((prev) => (prev === index ? null : index));
    };

    const confirmDeleteCase = (caseId) => {
        confirmAlert({
            title: 'Confirm To Delete',
            message: 'Are you sure you want to delete this folder?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDeleteFolder(caseId)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleDeleteFolder = async (chatId) => {
        try {
            const result = await axiosInstance.delete(`/assistant/userfolder/${chatId}/`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },
            });

            toast("folder deleted")
            getFolders()
            handleFolder1(folder_id, folderName)
        } catch (err) {
            console.error('Error deleting chat room:', err);
        }
        console.log(`Deleted chat: ${chatId}`);
    };

    const confirmDeletefile = (fileId) => {
        confirmAlert({
            title: 'Confirm To Delete',
            message: 'Are you sure you want to delete this file?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDeleteFile(fileId)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleDeleteFile = async (fileId) => {
        try {
            const result = await axiosInstance.delete(`/assistant/userfile/${fileId}/`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    folder_id:folder_id
                }
            });
            if (result.status === 204) {
                console.log('File deleted successfully');
                handleFolder1(folder_id, folder_name);
                toast('Your files have been Deleted successfully');
                // Optionally, you could refresh the file list or update the state here
            } else {
                console.log('Failed to delete file', result.status);
            }
        } catch (err) {
            console.log('Error deleting file:', err);
        }
    };



    const truncateText = (text, length) => {
        if (text.length <= length) {
            return text;
        }
        return text.substring(0, length) + "...";
    };

    const filetruncateText = (text, length) => {
        if (text.length <= length) {
            return text;
        }
        return text.substring(0, length) + "...";
    };

    return (
        <>
            <Navbar />
            <div className='upload-files-container'>
                <div className="upload-file-contain">
                    <h2><span style={{ color: '#5d54f1' }}>Court Ruling </span>Assistant</h2>
                    <div className="upload-para">
                        <p>Upload your legal documents and judgments for AI-driven analysis and detailed insights.</p>
                    </div>
                    <h5 style={{ fontWeight: '700', fontFamily: 'raleway', marginTop: '20px' }}>NEXT STEPS</h5>
                    <div className="upload-judgement-container">
                        <div className="judgementUploadLeftSide-top">
                            <div className="head-container">
                                <p>Create a folder with your documents</p>
                            </div>
                            <div className="judgementUploadLeftSide">
                                <div className="circle">
                                    <span>1</span>
                                </div>
                                <div className="upload-form">
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Enter folder name"
                                                value={folder_name}
                                                onChange={handleFolderNameChange}

                                            />
                                            <button onClick={handleCreateFolder} className='Judgement_chatRoom_Button'>
                                            {Isloading1 ? "please wait":"Create Folder"}</button>
                                        </>
                                 
                                        {/* <form onSubmit={handleSubmit}>
                                            <input
                                                type="file"
                                                multiple
                                                onChange={handleFileChange}
                                            />
                                            <button type="submit" className='Judgement_upload_button'>
                                                {Isloading ? "please wait" : "Upload"}
                                            </button>
                                        </form> */}
                                </div>
                                <div className="right-right-section">

                                    <div className="folder_names">
                                        <p>Folder List:</p>
                                        <div className="insider-name">
                                            {folders.map((folder) => (
                                                <>
                                                    <div
                                                        key={folder.id}
                                                        className={`folder-item ${selectedFolder && selectedFolder.id === folder.id ? 'selected' : ''}`}
                                                        onClick={() => {
                                                            handleFolder1(folder.id, folder.folder_name);
                                                        }}
                                                    >
                                                        <div className="firstStep">
                                                            <img src={folderVec} alt="" />
                                                            {folder.folder_name && truncateText(folder.folder_name, 10)}
                                                        </div>
                                                        <button
                                                            className="upload-options-toggle-button"
                                                            // onClick={() => setVisibleOptions(visibleOptions === folder.id ? null : folder.id)}
                                                        >
                                                            <img src={DeleteButton} alt="" onClick={(e) => { e.stopPropagation(); confirmDeleteCase(folder.id); }} />
                                                        </button>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="fileList">
                                        <div className="fileList-header">
                                            <h6>All files</h6>
                                            <div className="upload-btn-wrapper">
                                                <button className="filebtn">
                                                    <FontAwesomeIcon icon={faPlus} /> 
                                                    {Isloading ? "please wait" : "Upload"}
                                                </button>
                                                <input
                                                    type="file"
                                                    multiple
                                                    onChange={handleFileChange}
                                                    ref={fileInputRef}
                                                />
                                            </div>
                                        </div>
                                        <div className='upload_judgement_horizantal_line'>
                                            <hr className="styled-hr" />
                                        </div>
                                        <div className="folders_data">
                                            {folderdata.map((foldersdata) => (
                                                <div className="folder-data" key={foldersdata.id}>
                                                    <div className="Filename">
                                                    <img src={fileIcon} alt="" />
                                                        {foldersdata.file_name && filetruncateText(foldersdata.file_name, 30)}
                                                    </div>
                                                    <img src={DeleteButton} alt="" onClick={(e) => { e.stopPropagation(); confirmDeletefile(foldersdata.id); }} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="judgement-progress-bar">
                                            {uploadProgress > 0 && (
                                                <>
                                                    <ProgressBar>
                                                        <ProgressBar animated now={uploadProgress} key={1} />
                                                    </ProgressBar>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="uploadJudgementRight">
                            <div className='Upload_checkBoxes-head'>
                                <p>Check the box to ‘Create Knowledge base’</p>
                                <div className={`Upload_checkBoxes ${!showCreateChatroomButton ? 'disabled' : ''}`}>
                                    <div className="circle">
                                        <span>2</span>
                                    </div>
                                    <input
                                        type="checkbox"
                                        checked={createKnowledgeBase}
                                        onChange={handleCheckboxChange}
                                        disabled={!showCreateChatroomButton}
                                    />
                                    <div className="createKnowledgeBase">
                                        <label>Please check this box to create the knowledge base</label>
                                    </div>
                                </div>
                            </div>
                        
                            <div className={`createRoom-head ${!showCreateChatroomButton ? 'disabled' : ''}`}>
                                <p>Analyse all your documents with ease and precision and discuss with our chatbot</p>
                                <div className="createRoom">
                                    <div className="circle">
                                        <span>3</span>
                                    </div>
                                    <button
                                    onClick={handleCreateChatroom}
                                    className='Judgement_chatRoom_Button'
                                    disabled={!knowledgeBase}
                                    >Create New Chatroom</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadJudgement;
