import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import '../assets/css/mycases.css'
import { toast } from 'react-toastify';
import axiosInstance from '../utils/axiosInstance';
import HighCourtCaseDetails from './HighCourtCaseDetails';
import illustration from '../assets/images/selectcourt/select-court-illustration.png';
import '../assets/css/privileged.css'
import '../assets/css/highcourt.css'
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { ChatProvider } from '../ChatContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backimg from '../assets/images/mycases/back-image.svg'
import unselected from '../assets/images/mycases/unselected-for-arrow.svg'
import unselecteddelete from '../assets/images/mycases/unselected-delete.svg'
import infoimage from '../assets/images/mycases/info-image.svg'
import search from '../assets/images/mycases/search-vector.svg'
import bottomimage from '../assets/images/mycases/bottom-add-case.svg'
import backbutton from '../assets/images/mycases/back-button.svg'
import mainback from '../assets/images/selectcourt/main-background.svg';
import { useCaseContext } from '../CaseContext';
import mobilePriviledge from '../assets/images/mycases/mobilePriviledge.svg'
import { FaTimes } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css




const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const HighCourtPriviledge = () => {

    const [cnr_number, setCnrNumber] = useState('')
    const [case_nickname, setCaseName] = useState('')
    const { selectedHighCourtCase, setSelectedHighCourtCase } = useCaseContext();
    const [cases, setCases] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoadingCases, setIsLoadingCases] = useState(true);
    const [pollingCaseId, setPollingCaseId] = useState(null);
    const [showAddCase, setShowAddCase] = useState(false);
    const [caseType, setCaseType] = useState('');
    const [name, setName] = useState('');
    const [caseNumber, setCaseNumber] = useState('');
    const [year, setYear] = useState('');

    console.log(cases);

    const access_token = sessionStorage.getItem('access_token');

    const storedUserInfo = sessionStorage.getItem("user-info");
    const parsedUserInfo = storedUserInfo ? JSON.parse(storedUserInfo) : null;


    const caseTypes = [
        "ADMIN.REPORT", "ARB. A. (COMM.)", "ARB.A.", "ARB.P.", "BAIL APPLN.",
        "C.A.(COMM.IPD-GI)", "C.A.(COMM.IPD-PAT)", "C.A.(COMM.IPD-PV)", "C.A.(COMM.IPD-TM)", "C.O.",
        "C.O. (COMM.IPD-TM)", "C.O.(COMM.IPD-CR)", "C.O.(COMM.IPD-GI)", "C.O.(COMM.IPD-PAT)", "C.R.P.",
        "C.REF.(O)", "C.RULE", "CA", "CA (COMM.IPD-CR)", "CAVEAT(CO.)", "CC(ARB.)", "CCP(CO.)", "CCP(REF)",
        "CEAC", "CEAR", "CM(M)", "CM(M)-IPD", "CMI", "CO.A(SB)", "CO.APP.", "CO.APPL.(C)", "CO.APPL.(M)",
        "CO.EX.", "CO.PET.", "CONT.APP.(C)", "CONT.CAS(C)", "CONT.CAS.(CRL)", "CRL.A.", "CRL.L.P.",
        "CRL.M.(CO.)", "CRL.M.C.", "CRL.M.I.", "CRL.O.", "CRL.O.(CO.)", "CRL.REF.", "CRL.REV.P.",
        "CRP-IPD", "CS(COMM)", "CS(OS)", "CS(OS) GP", "CUS.A.C.", "CUS.A.R.", "CUSAA", "CUSTOM A.",
        "DEATH SENTENCE REF.", "DEMO", "EDC", "EDR", "EFA(OS)", "EFA(OS) (COMM)", "EFA(OS)(IPD)",
        "EL.PET.", "ETR", "EX.F.A.", "EX.P.", "EX.S.A.", "FAO", "FAO (COMM)", "FAO(OS)", "FAO(OS) (COMM)",
        "FAO(OS)(IPD)", "FAO-IPD", "GCAC", "GCAR", "GTA", "GTC", "GTR", "I.P.A.", "ITA", "ITC", "ITR",
        "ITSA", "LA.APP.", "LPA", "MAC.APP.", "MAT.", "MAT.APP.", "MAT.APP.(F.C.)", "MAT.CASE", "MAT.REF.",
        "MISC. APPEAL(PMLA)", "O.M.P.", "O.M.P. (COMM)", "O.M.P. (E)", "O.M.P. (E) (COMM.)", "O.M.P. (J) (COMM.)",
        "O.M.P. (MISC.)", "O.M.P. (T) (COMM.)", "O.M.P.(EFA)(COMM.)", "O.M.P.(I)", "O.M.P.(I) (COMM.)",
        "O.M.P.(MISC.)(COMM.)", "O.M.P.(T)", "O.REF.", "OA", "OCJA", "OMP (CONT.)", "OMP (ENF.) (COMM.)",
        "RC.REV.", "RC.S.A.", "RF(OS)(IPD)", "RFA", "RFA(COMM)", "RFA(OS)", "RFA(OS)(COMM)", "RFA-IPD",
        "RSA", "SCA", "SDR", "SERTA", "ST.APPL.", "ST.REF.", "STC", "SUR.T.REF.", "TEST.CAS.",
        "TR.P.(C)", "TR.P.(C.)", "TR.P.(CRL.)", "VAT APPEAL", "W.P.(C)", "W.P.(C)-IPD", "W.P.(CRL)",
        "WP(C)(IPD)", "WTA", "WTC", "WTR"
    ];


    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2000 + 1 }, (v, k) => 2000 + k);

    useEffect(() => {
        fetchCases();
    }, []);

    const fetchCases = async () => {
        try {
            const response = await axiosInstance.get('highcourt/cases/', {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                }
            });
            setCases(response.data);
        } catch (error) {
            console.error('Failed to fetch cases:', error);
        } finally {
            setIsLoadingCases(false);
        }
    }

    const confirmDeleteCase = (caseId) => {
        confirmAlert({
            title: 'Confirm To Delete',
            message: 'Are you sure you want to delete this case?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteCase(caseId)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const DeleteCase = async (caseId) => {
        try {
            const response = await axiosInstance.delete(`/highcourt/cases/${caseId}/`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },

            });
            fetchCases();
            toast("Case Deleted Successfully");
        } catch (err) {
            console.log(err);
        }
    }

    const handleCaseDetails = async (case_number) => {
        try {
            const response = await axiosInstance.get(`highcourt/hcbank/${case_number}/`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    "Content-Type": 'application/json'
                }
            });
            setSelectedHighCourtCase(response.data);
        } catch (err) {
            console.log(err.message);
        }
    }

    // const startPolling = (caseId) => {
    //     const interval = setInterval(async () => {
    //         try {
    //             const response = await axiosInstance.get(`highcourt/hcbank/${caseId}/status/`, {
    //                 headers: { "Authorization": `Bearer ${access_token}` },
    //             });
    //             if (response.data.status === 'Completed_Extraction' || response.data.status === 'COMPLETED') {
    //                 setCases(prevCases => {
    //                     const existingIndex = prevCases.findIndex(c => c.id === response.data.id);
    //                     if (existingIndex > -1) {
    //                         const updatedCases = [...prevCases];
    //                         updatedCases[existingIndex] = response.data;
    //                         return updatedCases;
    //                     }
    //                     return [...prevCases, response.data];
    //                 });
    //                 clearInterval(interval);
    //                 setPollingCaseId(null);
    //                 // fetchCases();
    //                 toast.success('Case added successfully. Please check.', {
    //                     position: "top-right",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //             }
    //         } catch (error) {
    //             console.error('Failed to fetch case status:', error);
    //         }
    //     }, 5000);
    //     return interval;
    // };

    // useEffect(() => {
    //     let interval;
    //     if (pollingCaseId) {
    //         interval = startPolling(pollingCaseId);
    //     }
    //     return () => {
    //         if (interval) {
    //             clearInterval(interval);
    //         }
    //     };
    // }, [pollingCaseId]);


    const handlecase = async (e) => {
        e.preventDefault();
        if (!caseType || !caseNumber || !year) {
            toast.error('Please fill in all fields');
            return;
        }
        const data = {
            case_type: caseType,
            caseno: caseNumber,
            year: year,
            case_nickname: name
        };

        setLoading(true);

        try {
            const response = await axiosInstance.post('highcourt/cases/', data, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                }
            });
            setCaseType('');
            setCaseNumber('');
            setYear('');
            setName('');
            fetchCases();
            toast.success('Case added successfully. We are processing your case.');
        } catch (err) {
            if (err.response && err.response.data && err.response.data.detail) {
                toast.error(`Failed to submit form: ${err.response.data.detail}`);
            } else {
                toast.error('Failed to submit form');
            }
        } finally {
            setLoading(false);
        }
    };


    if (isLoadingCases) {
        return (
            <div className="loading">
                <Navbar />
                <div style={{ height: "70vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <ClipLoader color="#ffffff" size={150} css={override} />
                    <p style={{ color: "#ffffff", marginTop: 20, fontSize: 20, textAlign: "center" }}>
                        Loading case details...<br />Please wait as we retrieve the latest information.
                    </p>
                </div>
            </div>
        );
    }

    if (selectedHighCourtCase) {
        return <HighCourtCaseDetails />;
    }

    if (cases.length > 0) {
        return (
            <>
                <Navbar />
                <ToastContainer />
                <div className="main-case-priviledge">
                    <div className="cases-privileged-container">
                        <div className="add-case-section">
                            <img src={backbutton} alt="" className='back-button' onClick={() => { window.location.href = '/mycases'; }} />
                            <h2> <span style={{ color: '#5D54F1' }}>Add</span> Case</h2>
                            <div className="form-group">
                                <select
                                    id="caseType"
                                    name="caseType"
                                    value={caseType}
                                    onChange={(e) => setCaseType(e.target.value)}
                                >
                                    <option value="">Select Case Type</option>
                                    {caseTypes.map((caseType, index) => (
                                        <option key={index} value={caseType}>{caseType}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="case-name"
                                    placeholder="Case Number"
                                    name="Case Number"
                                    value={caseNumber}
                                    onChange={(e) => setCaseNumber(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <select
                                    id="year"
                                    name="year"
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                >
                                    <option value="">Select Year</option>
                                    {years.map((year, index) => (
                                        <option key={index} value={year}>{year}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <input
                                    id="year"
                                    name="year"
                                    placeholder='Case Name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                >
                                </input>
                            </div>
                            <button className="submit-btn" onClick={handlecase}>
                                {loading ? <div className="loader"></div> : 'Submit'}
                            </button>
                            <p className="info-text mt-5">
                                <span className="icon"><img style={{ height: '20px', width: '20px' }} src={infoimage} alt="" /></span> You can access a detailed version of your case, complete with summary and timeline, by clicking on the tab labeled with the Case number.
                            </p>
                            <img src={bottomimage} alt="Book" className=".case-bottom-right-image" />
                        </div>
                        <div className="my-cases-section">
                            <div className='header-container'>
                                <h2><span style={{ color: '#5D54F1' }}>My</span> Cases</h2>
                                <div class="search-container">
                                    <input type="search" placeholder="Search Case" className="search-bar" />
                                    <span class="search-icon">
                                        <img src={search} alt="Search Icon" class="icon-img" />
                                    </span>
                                </div>
                            </div>
                            <div className="case-list">
                                {cases.map((caseItem) => (
                                    <div className="cases-item" style={{ display: 'flex' }}>
                                        <div key={caseItem.id} className='case-items' onClick={() => handleCaseDetails(caseItem.highcourtcase)} style={{ display: 'flex', gap: '10px', width: '100%' }}>
                                            <div className='case-item'>
                                                <span>CASE TYPE: {caseItem.case_type}</span>
                                                <span>CASE NO: {caseItem.caseno}</span>
                                                <span>CASE NAME: {caseItem.case_nickname}</span>
                                            </div>
                                        </div>
                                        <div className="case-actions">
                                            <button className="action-btn" aria-label="View Case">
                                                <img src={unselected} alt="View" className='case-img' />
                                            </button>
                                            <button className="action-btn" aria-label="Delete Case" onClick={(e) => { e.stopPropagation(); confirmDeleteCase(caseItem.id); }}>
                                                <img src={unselecteddelete} alt="Delete" className='case-img' />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* mobile add case section */}
                            <div className="mobilePrivildgeContainer">
                                <img src={backimg} alt="" className="back-image" />
                                <div className="addcaseBut">
                                    <button onClick={() => setShowAddCase(true)}>Add Case</button>
                                </div>
                            </div>
                            <div className={`mobile-top-privilegde ${showAddCase ? 'show' : ''}`}>
                                <div className='mobile-privileged-main-side '>
                                    <button className="close-button" onClick={() => setShowAddCase(false)}>
                                        <FaTimes />
                                    </button>                                   
                                     <h1> <span style={{ color: '#5D54F1' }}>Add </span>Case</h1>
                                    <div className="high-court-mobile-privileged-centered-box">
                                        <div class="mobile-privileged-container">
                                            <div className="high-court-form-container">
                                                <form onSubmit={handlecase}>
                                                    <div className="form-group">
                                                        <label htmlFor="caseType">Case Type</label>
                                                        <select
                                                            id="caseType"
                                                            name="caseType"
                                                            value={caseType}
                                                            onChange={(e) => setCaseType(e.target.value)}
                                                        >
                                                            <option value="">Select Case Type</option>
                                                            {caseTypes.map((caseType, index) => (
                                                                <option key={index} value={caseType}>{caseType}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="caseNumber">Case Number</label>
                                                        <input
                                                            type="text"
                                                            id="caseNumber"
                                                            placeholder='Case Number'
                                                            name="caseNumber"
                                                            value={caseNumber}
                                                            onChange={(e) => setCaseNumber(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="year">Year</label>
                                                        <select
                                                            id="year"
                                                            name="year"
                                                            value={year}
                                                            onChange={(e) => setYear(e.target.value)}
                                                        >
                                                            <option value="">Select Year</option>
                                                            {years.map((year, index) => (
                                                                <option key={index} value={year}>{year}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="year">Case Name</label>
                                                        <input
                                                            id="year"
                                                            name="year"
                                                            placeholder='Case Name'
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                        >
                                                        </input>
                                                    </div>
                                                    <div className="form-group laptop-submit">
                                                        <button type="submit" disabled={loading} onClick={handlecase}>
                                                            {loading ? <div className="loader"></div> : 'Submit'}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className='mobile-footer-content'>
                                                <div className="mobile-footer-image">
                                                    <button className="form-group mobile-submit" onClick={handlecase}>
                                                        {loading ? <div className="loader"></div> : 'Submit'}
                                                    </button>
                                                    <img src={mobilePriviledge} alt="" className='mobileFooterImage' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <Navbar />
                <ToastContainer />
                <div
                    className="high-court-container"
                >
                    <div className="high-court-container-content">
                        <div className="img-text">
                            <img src={backbutton} alt="Back" style={{ cursor: 'pointer' }} onClick={() => { window.location.href = '/mycases'; }} />
                            <h1>
                                <span style={{ color: '#5D54F1' }}>Enter the following to</span> know your case filing status
                            </h1>
                        </div>
                        <p>Access Case Summaries Within Seconds.</p>
                        <div className="form-container">
                            <form onSubmit={handlecase}>
                                <div className="form-group">
                                    <label htmlFor="caseType">Case Type</label>
                                    <select
                                        id="caseType"
                                        name="caseType"
                                        value={caseType}
                                        onChange={(e) => setCaseType(e.target.value)}
                                    >
                                        <option value="">Select Case Type</option>
                                        {caseTypes.map((caseType, index) => (
                                            <option key={index} value={caseType}>{caseType}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="caseNumber">Case Number</label>
                                    <input
                                        type="text"
                                        id="caseNumber"
                                        name="caseNumber"
                                        value={caseNumber}
                                        onChange={(e) => setCaseNumber(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="year">Year</label>
                                    <select
                                        id="year"
                                        name="year"
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                    >
                                        <option value="">Select Year</option>
                                        {years.map((year, index) => (
                                            <option key={index} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                                {/* <button className="mobile-btn" onClick={handlecase}>
                                    {loading ? <div className="loader"></div> : 'Submit'}
                                </button> */}
                                <div className="form-group">
                                    <label htmlFor="year">Case Name</label>
                                    <input
                                        id="year"
                                        name="year"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    >
                                    </input>
                                </div>
                                <div className="submit-laptop">
                                    <div className="laptop-submit" onClick={handlecase}>
                                        <button type="submit" disabled={loading}>
                                            {loading ? <div className="loader"></div> : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <button className="form-group mobile-submit" onClick={handlecase}>
                            {loading ? <div className="loader"></div> : 'Submit'}
                        </button>
                    </div>
                    <div className="high-court-top-container">
                        <img
                            src={illustration}
                            alt="New top image"
                            className="high-court-landing-top-image"
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default HighCourtPriviledge;