import React, { useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Chat from "./Chat";
import Sidebarprofile from "./Sidebarprofile";
import { ChatProvider } from '../ChatContext'; // Import the context provider
import '../assets/css/ChatBot.css'
import NotificationBar from "./NotificationBar";

const ChatBot = () => {
  const [comparedLawyers, setComparedLawyers] = useState([]);

  const handleCompare = (lawyerId) => {
    if (!comparedLawyers.includes(lawyerId)) {
      setComparedLawyers([...comparedLawyers, lawyerId]);
    }
  };

  return (
    <div>
    <div className="firstNavbar">
      <Navbar />
    </div>
      <ChatProvider>
        <div className="full-screen-border-box">
          <Sidebar />
          <div className="chat-component">
            <Chat />
          </div>
        </div>
      </ChatProvider>
    </div>
  );
};

export default ChatBot;


