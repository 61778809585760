import React, { forwardRef, useState, useEffect, useRef } from 'react';
import '../assets/css/ourservices.css';
import rightarrow from '../assets/images/ourservices/feature-arrow.svg';
import chatbotImage from '../assets/images/ourservices/ai-feture-image.png';
import livecaseImage from '../assets/images/ourservices/live-case-tracking.png';
import performanceImage from '../assets/images/ourservices/performance-analysis.png';
import documentImage from '../assets/images/ourservices/documentation_cunsmization.png';
import expertLawyerImage from '../assets/images/ourservices/expert_lawyer_matching.svg';
import predictiveImage from '../assets/images/ourservices/predictive_analytics.png';

const Ourservices = forwardRef((props, ref) => {
    const [selectedFeature, setSelectedFeature] = useState('chatbot');
    const isInitialRender = useRef(true);

    // Image preloading effect
    useEffect(() => {
        [chatbotImage, livecaseImage, performanceImage, documentImage, expertLawyerImage, predictiveImage].forEach(image => {
            const img = new Image();
            img.src = image;
        });
    }, []);

    const featureDetails = {
        chatbot: {
            key: 'chatbot',
            title: 'AI-Driven Chatbot',
            subtitle: 'Chat with our Minister for instant legal advice through our smart conversational AI.',
            description: [
                "Introducing a conversational interface that allows users to seek legal advice, clarify legal concepts, and receive tailored guidance on various legal matters.",
                "The chatbot's extensive legal knowledge base encompasses a wide range of practice areas, ensuring accurate and up-to-date information on diverse legal topics.",
                "Case Minister continuously learns and adapts from user interactions, expanding its knowledge base and refining its responses to better serve the evolving needs of users."
            ],
            mobileDescription: [
                "Case Minister provides users with legal advice, clarifies legal concepts, and offers tailored guidance on various legal matters.",
                "It boasts an extensive, up-to-date knowledge base covering diverse legal topics for accurate information.",
                "Continuously learning from interactions, Case Minister adapts and refines its responses to meet evolving user needs."
            ],
            button: (
                <button onClick={() => { window.location.href = '/talkaboutcase'; }} className='feature-talk-with-chatbot'>
                    Get started for <span style={{ fontWeight: 'bold' }}>FREE</span>
                </button>
            ),
            button2:(
                <button className='beta_access' style={{position:'absolute', top:'8%'}}>BETA Access</button>
            ),
            image: chatbotImage,
            imageClass: 'feature-image-chatbot'
        },
        tracking: {
            key: 'tracking',
            title: 'Live Case Tracking',
            subtitle: 'Stay on top of your cases through our case management system that tracks and organizes relevant case details.',
            description: [
                "Real-Time Updates & Alerts: Receive instant notifications and set custom alerts to monitor key case milestones and deadlines.",
                "Comprehensive Overview & Document Management: Access all case details in one dashboard and securely manage case-related documents.",
                "Analytics & Mobile Access: Generate detailed reports and manage cases on-the-go with mobile access for continuous connectivity."
            ],
            mobileDescription:[
                "Get real-time updates and custom alerts for key case milestones and deadlines.",
                "Access comprehensive case details and securely manage documents in one dashboard.",
                "Generate detailed reports and manage cases on-the-go with mobile access."
            ],
            button: (
                <button onClick={() => { window.location.href = '/mycases'; }} className='feature-talk-with-chat'>
                    Unlock <span style={{ fontWeight: 'bold' }}>NOW</span>
                </button>
            ),
            button2: (
                <button className='beta_access' style={{ position: 'absolute', top: '8%' }}>BETA Access</button>
            ),
            image: livecaseImage,
            imageClass: 'feature-image-livecase'
        },
        Analysis: {
            key: 'Analysis',
            title: 'Document Analysis',
            subtitle: 'Effortlessly transform your document management process with advanced AI-driven tools designed to handle everything from bulk uploads to insightful analysis.',
            description: [
                "Bulk Document Upload: Upload and process multiple large documents simultaneously, enhancing efficiency.",
                "AI-Driven Analysis: Leverage AI to extract, summarize, and organize essential data for comprehensive insights",
                "Knowledge Base Creation: Automatically create a well-structured knowledge base, enabling easy access and swift document retrieval."
            ],
            mobileDescription: [
                "Bulk Document Upload: Upload and process multiple large documents simultaneously, enhancing efficiency.",
                "AI-Driven Analysis: Leverage AI to extract, summarize, and organize essential data for comprehensive insights",
                "Knowledge Base Creation: Automatically create a well-structured knowledge base, enabling easy access and swift document retrieval."
            ],

            button: (
                <button onClick={() => { window.location.href = '/upload_judgement'; }} className='feature-talk-with-chat'>
                    Unlock <span style={{ fontWeight: 'bold' }}>NOW</span>
                </button>
            ),
            button2: (
                <button className='beta_access' style={{ position: 'absolute', top: '8%' }}>BETA Access</button>
            ),
            image: expertLawyerImage,
            imageClass: 'feature-image-expertLawyer'
        },
        analytics: {
            key: 'analytics',
            title: 'Performance Analytics',
            subtitle: 'Identifying top-performing lawyers by leveraging advanced data tracking and reporting capabilities to make informed decisions',
            description: [
                "Data Tracking & Reports: Collect detailed data and create custom reports to understand how well lawyers are performing in different areas.",
                "Dashboard & Real-Time Monitoring: Use a central dashboard to get real-time insights and keep track of important performance indicators.",
                "Client Feedback & Predictions: Include client feedback in performance reviews and use data to predict future performance, helping you make informed decisions."
            ],
            mobileDescription: [
                "Collect detailed data and create custom reports on lawyer performance.",
                "Monitor real-time insights and key performance indicators through a central dashboard.",
                "Use client feedback and data predictions for informed performance reviews and decisions."
            ],
            button: (
                <button onClick={() => { window.location.href = '/performance-analytic--coming-soon'; }} className='feature-talk-with-chat'>
                    Unlock <span style={{ fontWeight: 'bold' }}>NOW</span>
                </button>
            ),
            button2:(
                <button className='coming-soon-feature' style={{ position: 'absolute', top: '8%' }}>Coming Soon</button>
            ),
            image: performanceImage,
            imageClass: 'feature-image-performance'
        },
        documentation: {
            key: 'documentation',
            title: 'Documentation & Customisation',
            subtitle: 'Tailored Legal Documents: Craft personalised legal documents effortlessly with our guided tools',
            description: [
                "Data Tracking & Reports: Collect detailed data and create custom reports to understand how well lawyers are performing in different areas.",
                "Dashboard & Real-Time Monitoring: Use a central dashboard to get real-time insights and keep track of important performance indicators.",
                "Client Feedback & Predictions: Include client feedback in performance reviews and use data to predict future performance, helping you make informed decisions."
            ],
            mobileDescription:[
                "Create and customize legal documents with guided assistance and templates.",
                "Save time with automated data input and ensure legal compliance.",
                "Collaborate in real-time, securely store, and easily retrieve documents."
            ],
            button: (
                <button onClick={() => { window.location.href = '/documentation-and-customization-coming-soon'; }} className='feature-talk-with-chat'>
                    Unlock <span style={{ fontWeight: 'bold' }}>NOW</span>
                </button>
            ),
            button2: (
                <button className='coming-soon-feature' style={{ position: 'absolute', top: '8%' }}>Coming Soon</button>
            ),
            image: documentImage,
            imageClass: 'feature-image-document'
        },
        
        analysis: {
            key: 'analysis',
            title: 'Predictive Analysis',
            subtitle: 'Generate comprehensive reports outlining the projected chances of success and potential challenges for strategic planning',
            description: [
                "Success Chances and Risks: Our tools estimate the likelihood of success and identify potential risks for your plans.",
                "Detailed, Easy-to-Understand Reports: Get clear reports with charts and graphs that show predictions and possible challenges.",
                "Actionable Insights and Real-Time Updates: Receive practical recommendations and real-time updates to make smart decisions based on the latest data."
            ],
            mobileDescription:[
                "Our tools assess success likelihood and identify potential risks for your plans.",
                "Get clear, detailed reports with charts and graphs showing predictions and challenges.",
                "Receive actionable insights and real-time updates for smart, data-driven decisions."
            ],
            button: (
                <button onClick={() => { window.location.href = '/predictive-analysis-coming-soon'; }} className='feature-talk-with-chat'>
                    Unlock <span style={{ fontWeight: 'bold' }}>NOW</span>
                </button>
            ),
            button2: (
                <button className='coming-soon-feature' style={{ position: 'absolute', top: '8%' }}>Coming Soon</button>
            ),
            image: predictiveImage,
            imageClass: 'feature-image-predictive'
        },
    };

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            const selectedElement = document.getElementById(selectedFeature);
            if (selectedElement) {
                // selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [selectedFeature]);

    return (
        <div className='product-feature'>
            <h2 className="text-center"> <span style={{ color: '#5D54F1' }}>Product</span> Features</h2>
            <div className="feature-container" ref={ref}>
                <div className="feature-sidebar">
                    {Object.keys(featureDetails).map((featureId, index) => (
                        <button
                            key={featureId}
                            id={featureId}
                            className={`feature-btn ${selectedFeature === featureId ? 'selected' : ''}`}
                            onClick={() => setSelectedFeature(featureId)}
                        >
                            <div className="titleAndImg">
                                <div className="index">{index + 1}. </div>{featureDetails[featureId].title}
                                <img src={rightarrow} alt="arrow" className="feature-arrow" />
                            </div>
                        </button>
                    ))}
                </div>
                <div className="feature-content">
                    <div className='feature-main-header'>
                    <div className="ourservices-upper-content">
                        <h2>{featureDetails[selectedFeature].title}</h2>
                        <button>{featureDetails[selectedFeature].button2}</button>
                    </div>
                        <p className='subtitle'>{featureDetails[selectedFeature].subtitle}</p>
                        <ul className="desktop-description">
                            {featureDetails[selectedFeature].description.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                            <div className='feature-button'>
                                {featureDetails[selectedFeature].button}
                            </div>
                        </ul>
                        <ul className="mobile-description">
                            {featureDetails[selectedFeature].mobileDescription.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                            <div className='feature-button'>
                                {featureDetails[selectedFeature].button}
                            </div>
                        </ul>

                        {/* <ul>
                            {featureDetails[selectedFeature].description.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        <div className='feature-button'>
                            {featureDetails[selectedFeature].button}
                        </div>
                        </ul> */}
                    </div>
                    <div className='feature-image'>
                        <img src={featureDetails[selectedFeature].image} alt={featureDetails[selectedFeature].title} className={featureDetails[selectedFeature].imageClass} loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Ourservices;
