// src/ProtectedRoute.js

import React from 'react';
import {Navigate } from 'react-router-dom';
import { useAuth } from './Authcontext';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    
    if (!isAuthenticated) {
        return <Navigate to="/signup" />;
    }

    return children;
};

export default ProtectedRoute;
