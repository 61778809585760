import React, { createContext, useContext, useEffect, useState } from 'react';
import axiosInstance from './utils/axiosInstance';

export const NotificationContext = createContext();

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const access_token = sessionStorage.getItem("access_token");
    
    useEffect(() => {
        const fetchFeedbackStatus = async () => {
            try {
            //     const response = axiosInstance.get('feedback/feedback/has_given_feedback/',{
            //         headers:{
            //             'Authorization': `Bearer ${access_token}`,
            //             'Content-Type': 'application/json'
            //         }
            //     });
            //     const data = response.json();
             
            //     if (data.has_given_feedback === false) {
                    const userLoggedInTime = sessionStorage.getItem('userLoggedInTime');
                    const popupShown = sessionStorage.getItem('popupShown');

                    if (userLoggedInTime && !popupShown) {
                        const currentTime = new Date().getTime();
                        const timeDifference = currentTime - userLoggedInTime;

                        if (timeDifference >= 2 * 60 * 1000) {
                            setIsPopupVisible(true);
                            sessionStorage.setItem('popupShown', 'true');
                        } else {
                            const remainingTime = 2 * 60 * 1000 - timeDifference;
                            const timer = setTimeout(() => {
                                setIsPopupVisible(true);
                                sessionStorage.setItem('popupShown', 'true');
                            }, remainingTime);

                            return () => clearTimeout(timer);
                        }
                    }
                }
            // }
             catch (error) {
                console.error('Error fetching feedback status:', error);
            }
        };

        fetchFeedbackStatus();
    }, []);

    return (
        <NotificationContext.Provider value={{ isPopupVisible, setIsPopupVisible }}>
            {children}
        </NotificationContext.Provider>
    );
};
