import React, { useState } from 'react';
import '../assets/css/Findlaywer.css';
import Navbar from './Navbar';
import laptop from '../assets/images/Findlaywer/device-macbook.jpg';
import BackVec from '../assets/images/Findlaywer/g216.svg';
import mobielseconfback from '../assets/images/Findlaywer/mobile-seconf-back.png';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import { useCaseContext } from '../CaseContext';
import BetaNotification from './BetaNotification'

function Searchlaywer() {
    const [name, setLegalIssue] = useState('');
    const [state, setCity] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const navigate = useNavigate();
    const { setLawyersData } = useCaseContext();


    const handleSearch = async () => {
        try {
            if (!name || !state) {
                toast.error('Please Fill in all fields.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            setIsLoading(true);
            const access_token = sessionStorage.getItem('access_token');

            const response = await axiosInstance.get('search/searchlawyer/', {
                params: {
                    name,
                    state
                },
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },
            });

            const data = response.data;
            console.log(data);
            setLawyersData(response.data)
            navigate('/Filteredlawyer');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally{
            setIsLoading(false);
        }
    };


    return (
        <>
            <Navbar />
            <div className="">
                <BetaNotification />
                <div className="centered-image mt-3">
                    <img src={laptop} alt="" className='SearchLaptopScreen' />
                    <div className="searchlaywer-content">
                        <div className="mobile-content">
                            <h5><span style={{ color: '#5D54F1' }}>Lawyers</span>  You Can Trust, <span style={{ color: '#5D54F1' }}>Advice</span> You Can Rely On</h5>
                            <p>With comprehensive profiles and real user reviews, discover legal experts who are as invested in your case as you are.</p>
                        </div>
                        <img src={mobielseconfback} alt="" className='LawyerMobileImage' />
                        <div className="mobileBelowContent">
                            <form className="searchlawyer-login-form">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setLegalIssue(e.target.value)}
                                        placeholder="Lawyer Name"
                                        required
                                    />
                                </div>
                                <div >
                                    <select 
                                        name='city'
                                        value={state}
                                        onChange={(e) => setCity(e.target.value)}
                                    >
                                        {[
                                            "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
                                            "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand",
                                            "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
                                            "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab",
                                            "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
                                            "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands",
                                            "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu",
                                            "Lakshadweep", "Delhi", "Puducherry"
                                        ].map((city) => (
                                            <option key={city} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className="searchlawyer-button">
                            <button className='searchlawyer-form' onClick={handleSearch}>
                            {isLoading ? "Please wait" : "Search"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Searchlaywer;
