import React, { useState } from 'react';
import Navbar from './Navbar';
import '../assets/css/mycases.css';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import '../assets/css/nonpriviliege.css';
import nonpriback from '../assets/images/mycases/non-pri-vec.svg';
import nonPriMobile from '../assets/images/mycases/non-mobile-back.png';

const NonPrivilegedPage = () => {
    const [contact_num, setContactNo] = useState('');
    const [state, setCity] = useState('');
    const [profession, setProfession] = useState('');
    const access_token = sessionStorage.getItem('access_token');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        if (!state.trim()) {
            toast.error('Please enter the city field.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!profession.trim()) {
            toast.error('Please enter the profession field.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!contact_num.trim()) {
            toast.error('Please enter the mobile number.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setLoading(true);

        try {
            const response = await axiosInstance.post('casedetail/privileges/', { contact_num, state, profession }, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                }
            });


            window.location.reload();
            navigate('/mycases');

        } catch (err) {
            if (err.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error('Response error:', err.response);
                toast.error(`Error: ${err.response.data.detail}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (err.request) {
                // The request was made but no response was received
                console.error('Request error:', err.request);
                toast.error('No response received from the server.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', err.message);
                toast.error(`Error: ${err.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div className="non-pri-centered-box">
                <div className="left-side">
                    <h2>Gain real-time visibility into your legal proceedings with our</h2>
                    <div className="headAndPara">
                        <h1><span style={{ color: '#5D54F1' }}>Exclusive Live Case</span> Tracking Feature</h1>
                        <p>Enjoy a <span style={{ fontWeight: 'bold' }}>15-days free trial</span> and join our waitlist</p>
                    </div>
                    <div className="mobile-right-side">
                        <img src={nonPriMobile} alt="" />
                    </div>
                    <p>You’re one step away! Provide us with some more information</p>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="contact-no">Contact No.</label>
                        <input
                            type="text"
                            id="contact-no"
                            placeholder="+9199XXXXXX80"
                            value={contact_num}
                            onChange={(e) => setContactNo(e.target.value)}
                            required
                        />
                        <label htmlFor='city'>City</label>
                        <select className="form-control"
                            name='city'
                            value={state}
                            onChange={(e) => setCity(e.target.value)}
                        >
                            {[
                                "Agartala", "Agra", "Ahmedabad", "Ahmednagar", "Aizawl",
                                "Ajmer", "Akola", "Aligarh", "Allahabad", "Alwar",
                                "Ambala", "Ambattur", "Ambernath", "Amravati", "Amritsar",
                                "Anand", "Anantapur", "Anantapuram", "Arrah", "Asansol",
                                "Aurangabad", "Avadi", "Bagasara", "Bahraich", "Ballia",
                                "Bally", "Bangalore", "Baran", "Baranagar", "Barasat",
                                "Bareilly", "Bardhaman", "Bathinda", "Beawar", "Begusarai",
                                "Belgaum", "Bellary", "Bhagalpur", "Bhalswa Jahangir Pur", "Bharatpur",
                                "Bharuch", "Bhavnagar", "Bhilai", "Bhilwara", "Bhiwandi",
                                "Bhiwani", "Bhopal", "Bhubaneswar", "Bhuj", "Bidhannagar",
                                "Bidar", "Bihar Sharif", "Bijapur", "Bikaner", "Bilaspur",
                                "Bilimora", "Bokaro", "Bulandshahr", "Burhanpur", "Chandigarh",
                                "Chandrapur", "Chennai", "Chhatarpur", "Chhindwara", "Chittoor",
                                "Chittorgarh", "Coimbatore", "Cuddalore", "Cuttack", "Dabhoi",
                                "Damoh", "Darbhanga", "Davanagere", "Dewas", "Dhanbad",
                                "Dhule", "Dindigul", "Durg", "Durgapur", "Erode",
                                "Etawah", "Faizabad", "Faridabad", "Farrukhabad", "Fatehpur",
                                "Firozabad", "Gandhidham", "Gandhinagar", "Gaya", "Ghaziabad",
                                "Gopalpur", "Gorakhpur", "Gulbarga", "Guna", "Guntur",
                                "Gurgaon", "Guwahati", "Gwalior", "Haldia", "Haldwani",
                                "Hapur", "Haridwar", "Hazaribagh", "Himatnagar", "Hindupur",
                                "Hisar", "Hospet", "Howrah", "Hubballi-Dharwad", "Hyderabad",
                                "Ichalkaranji", "Imphal", "Indore", "Jabalpur", "Jaipur",
                                "Jalandhar", "Jalgaon", "Jalna", "Jamnagar", "Jamshedpur",
                                "Jhansi", "Jodhpur", "Junagadh", "Kadapa", "Kaithal",
                                "Kakinada", "Kalyan-Dombivli", "Kamarhati", "Kancheepuram", "Kanchrapara",
                                "Kannur", "Kanpur", "Kapurthala", "Karimnagar", "Karnal",
                                "Karawal Nagar", "Katni", "Khammam", "Khandwa", "Khanna",
                                "Kharagpur", "Khora, Ghaziabad", "Kochi", "Kohima", "Kolar",
                                "Kolhapur", "Kolkata", "Kollam", "Korba", "Kota",
                                "Kottayam", "Kozhikode", "Kumbakonam", "Kurnool", "Kurukshetra",
                                "Lakhimpur", "Latur", "Loni", "Lucknow", "Ludhiana",
                                "Madurai", "Mahesana", "Maheshtala", "Malegaon", "Mangalore",
                                "Mathura", "Mau", "Meerut", "Moradabad", "Mormugao",
                                "Morena", "Muzaffarnagar", "Muzaffarpur", "Mysore", "Nadiad",
                                "Nagercoil", "Nagpur", "Naihati", "Nalgonda", "Nanded",
                                "Nashik", "Navsari", "Navi Mumbai", "Nawada", "Nellore",
                                "New Delhi", "Nizamabad", "Ongole", "Orai", "Palanpur",
                                "Palghar", "Pali", "Panaji", "Panipat", "Parbhani",
                                "Patiala", "Patna", "Pilibhit", "Pimpri-Chinchwad", "Pondicherry",
                                "Porbandar", "Proddatur", "Pudukkottai", "Pune", "Puri",
                                "Purnia", "Raebareli", "Raichur", "Raiganj", "Raipur",
                                "Rajahmundry", "Rajapalayam", "Rajgarh", "Rajkot", "Rajpur Sonarpur",
                                "Ramachandrapuram", "Ramagundam", "Rampur", "Ranchi", "Ratlam",
                                "Raurkela", "Rewa", "Rohtak", "Roorkee", "Rourkela",
                                "Sagar", "Saharanpur", "Salem", "Sambalpur", "Sambhal",
                                "Sangli-Miraj & Kupwad", "Satna", "Secunderabad", "Sehore", "Shahdol",
                                "Shahjahanpur", "Shillong", "Shimla", "Shimoga", "Shivpuri",
                                "Siliguri", "Singrauli", "Sitapur", "Solapur", "Sonipat",
                                "South Dumdum", "Sri Ganganagar", "Srinagar", "Surat", "Surendranagar",
                                "Tadepalligudem", "Tenali", "Thane", "Thanjavur", "Thiruvananthapuram",
                                "Thoothukudi", "Thrissur", "Tiruchirappalli", "Tirunelveli", "Tirupati",
                                "Tiruppur", "Tumkur", "Udaipur", "Udupi", "Ujjain",
                                "Ulhasnagar", "Uluberia", "Unnao", "Vadodara", "Valsad",
                                "Varanasi", "Vasai-Virar", "Vellore", "Veraval", "Vidisha",
                                "Vijayawada", "Vijayanagaram", "Vikarabad", "Villupuram", "Visakhapatnam",
                                "Warangal", "Wardha", "Yamunanagar"
                            ].map((city) => (
                                <option key={city} value={city}>
                                    {city}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="profession">Profession</label>
                        <input
                            type="text"
                            id="profession"
                            placeholder="Profession"
                            value={profession}
                            onChange={(e) => setProfession(e.target.value)}
                            required
                        />
                        <div className="nonPriButton">
                            <button type="submit" className='mt-3' disabled={loading}>
                                {loading ? 'Submitting...' : 'Complete Now'}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="right-side">
                    <img src={nonpriback} alt="" />
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default NonPrivilegedPage;
