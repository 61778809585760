import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/LandingPage.css'; // Ensure the path is correct
import delhi_high_court from '../assets/images/landingpage/delhihighcourt.svg';
import delhi_bar_association from '../assets/images/landingpage/delhibarcounsil.svg';
import punjab_high_court from '../assets/images/landingpage/punjab_bar.png'
import punjab_bar_association from '../assets/images/landingpage/punjab_bar_association.svg'

const images = [
    { id: 2, image: delhi_high_court, height: '100%', margin: '0px', content: 'NVIDIA' },
    { id: 1, image: delhi_bar_association, height: '100%', margin: '0px', content: 'Microsoft' },
    { id: 3, image: punjab_high_court, height: '100%', margin: '0px', content: 'NVIDIA' },
    { id: 4, image: punjab_bar_association, height: '100%', margin: '0px', content: 'NVIDIA' },
];

const SupportrdWith = () => {
    const settings = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: false,
        centerPadding: '0',
        arrows: false, 
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1, 
                    slidesToScroll: 1, 
                    centerMode: false,
                },
            },
        ],
    };

    return (
        <div className="integrated-with">
            <Slider {...settings}>
                {images.map((img) => (
                    <div key={img.id} className="slide">
                        <div className="integrated-image-box">
                            <img
                                src={img.image}
                                alt={img.content}
                                style={{ height: img.height, margin: img.margin }}
                                className="testimonial-image"
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SupportrdWith;
