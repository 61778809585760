import React, { useState, useEffect } from "react";
import "../assets/css/Sidebar.css";
import forword from "../assets/images/Chatbot/new-chat.svg";
import axiosInstance from "../utils/axiosInstance";
import { useChat } from '../ChatContext';
import signout from '../assets/images/Chatbot/Sign_out_squre.svg';
import { useNavigate } from 'react-router-dom';
import mobileHomeIcon from '../assets/images/Chatbot/mobile-home-icon.svg';
import profileIcon from '../assets/images/navbar/profileVector.svg';
import contactusIcon from '../assets/images/navbar/contactusIcon.svg';
import { useCaseContext } from '../CaseContext';
import mobileHostoryArrow from '../assets/images/chat/mobileHistoryArrow.svg';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Sidebar = () => {
    const { isOpen, toggleSidebar } = useCaseContext();
    const access_token = sessionStorage.getItem('access_token');
    const { handleSelectChat, handleNewChat, chatRooms, setShowPrompts, showPrompts, fetchChatHistory } = useChat();
    const [userCases, setUserCases] = useState([]);
    const [newChat, setNewChat] = useState(null);
    const [selectedChatId, setSelectedChatId] = useState('');
    const [visibleOptions, setVisibleOptions] = useState(null);

    const navigate = useNavigate();

    const truncateText = (text, length) => {
        if (text.length <= length) {
            return text;
        }
        return text.substring(0, length) + "...";
    };

    const startNewChat = () => {
        setShowPrompts(true);
        handleNewChat(true);
        setSelectedChatId('');
    };
    useEffect(() => {
        console.log('showPrompts updated:', showPrompts);
    }, [showPrompts]);

    

    const logout = () => {
        localStorage.removeItem('authToken');
        sessionStorage.clear(); 
        navigate('/login'); 
    };


    const handleChatButtonClick = (chatId) => {
        handleSelectChat(chatId);
        setNewChat(null);
        setSelectedChatId(chatId);
    };

    const confirmDeleteCase = (caseId) => {
        confirmAlert({
            title: 'Confirm To Delete',
            message: 'Are you sure you want to delete this chat?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDeleteChat(caseId)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleDeleteChat = async (chatId) => {
        try {
            const result = await axiosInstance.delete(`/chatapp/chat-room/${chatId}/`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (result.status !== 204) throw new Error('Failed to delete chat room');
            fetchChatHistory();
            // toast
        } catch (err) {
            console.error('Error deleting chat room:', err);
        }
        console.log(`Deleted chat: ${chatId}`);
    };





    useEffect(() => {
        if (chatRooms.length > 0 && selectedChatId === null) {
            setSelectedChatId('');
            setShowPrompts(true); 
        }
    }, [chatRooms]);

    return (
        <div className={`sidebar ${isOpen ? "open" : ""}`}>
            <ul className="sidebar-list">
                <div className="mobile-home-button">
                    <div className="chatHistory-toggle" onClick={toggleSidebar}>
                        <i className={`fas ${isOpen ? "fa-chevron-left" : "fa-bars"}`}></i>
                    </div>
                    <div className="profile-and-home" style={{ display: 'flex', position: 'relative', right: '5%', gap: '15px', flexDirection: 'row-reverse' }}>
                        <img src={mobileHomeIcon} alt="" onClick={() => { window.location.href = '/'; }} style={{ width: '35px' }} />
                        <div className="profileImag">
                            <img src={profileIcon} alt="" style={{ width: '35px' }} onClick={() => { window.location.href = '/user-profile'; }} />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <button className="chat-button" onClick={startNewChat}>
                        <div className="icon-box">
                            <img src={forword} alt="" style={{ height: '22px' }} />
                        </div>
                        New Chat
                    </button>
                </div>
                <div className="chatroom-mobile-container">
                    <h4>History</h4>
                    <div className="mobile-all-history">
                        {chatRooms.map((chat, index) => (
                            <button
                                key={index}
                                className={`mt-2 ml-0 chat-button1 ${chat.id === selectedChatId ? 'selected' : ''}`}
                                onClick={() => handleChatButtonClick(chat.id)}
                            >
                                <div className="mobile-history-side-content" onClick={toggleSidebar}>
                                <div className="mobile-chat-name">
                                    {chat.chat_name && truncateText(chat.chat_name, 19)}
                                </div>
                                <div className="mobilehistoryimage">
                                    <img src={mobileHostoryArrow} alt="" />
                                </div>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
                <div className="laptop-history" style={{ color: '#2C5966' }}>
                    <h6 className="mb-0 chat-section" style={{ marginTop: '25px' }}>TODAY</h6>
                    <div className="all-history">
                        {chatRooms.map((chat, index) => (
                            <div key={index} className="chat-button-container">
                                <button
                                    className={`mt-2 ml-0 chat-button1 ${chat.id === selectedChatId ? 'selected' : ''}`}
                                    onClick={() => handleChatButtonClick(chat.id)}
                                >
                                    {chat.chat_name && truncateText(chat.chat_name, 15)}
                                <button
                                    className="options-toggle-button"
                                    onClick={() => setVisibleOptions(visibleOptions === chat.id ? null : chat.id)}
                                >
                                    ...
                                </button>
                                </button>
                                {visibleOptions === chat.id && (
                                    <div className="chat-options">
                                        {/* <button onClick={() => console.log('Share chat:', chat.id)} className="option-button">Share</button>
                                        <button onClick={() => console.log('Rename chat:', chat.id)} className="option-button">Rename</button>
                                        <button onClick={() => console.log('Archive chat:', chat.id)} className="option-button">Archive</button> */}
                                        <button onClick={(e) => { e.stopPropagation(); confirmDeleteCase(chat.id); }} className="option-button">Delete</button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                </div>
                <div className="laptop-logout-container">
                    <div className="horizontal-line"></div>
                    <button className="laptop-sidebar-logout-button" onClick={logout}>
                        <div className="text">
                            Log Out
                        </div>
                        <div className="logout-img">
                            <img src={signout} alt="sign out" />
                        </div>
                    </button>
                </div>
            </ul>
            <div className="logout-container">
                <div className="horizontal-line"></div>
                <button className="sidebar-logout-button" onClick={logout}>
                    <div className="text">
                        Log Out
                    </div>
                    <div className="logout-img">
                        <img src={signout} alt="sign out" />
                    </div>
                </button>
                <div className="profileImag">
                    <span style={{ color: "#5d54f1", fontSize: '18px', fontWeight: '500', margin: '10px' }}>Contact Us</span><img src={contactusIcon} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
