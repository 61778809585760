import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../utils/axiosInstance';
import '../assets/css/UserProfile.css'; // Make sure to create this CSS file and import it here
import image from '../assets/images/profile/lawyer-photo.png';
import email from '../assets/images/profile/lawyer-email.png';
import phone from '../assets/images/profile/lawyer-phone.png';
import address from '../assets/images/profile/lwayer-address.png';
import firstname from '../assets/images/profile/first_name.svg';
import { toast } from 'react-toastify';
import '../assets/css/LawyerProfile.css';
import lawyerProfileIllustration from '../assets/images/profile/lawyer-profile-illustration.png';
import editButton from '../assets/images/profile/edit-button.png';
import mobileImage from '../assets/images/profile/mobile-user-icon.png';
import email1 from '../assets/images/profile/email.svg';
import mobile from '../assets/images/profile/mobile.svg';
import address1 from '../assets/images/profile/addres.svg';
import experience from '../assets/images/profile/lawyer_erperience.svg';
import barcodeNumber from '../assets/images/profile/lawyer_barcode.svg';
import lawyer_language from '../assets/images/profile/lawyer_languages.svg'
import lawyer_ratings from '../assets/images/profile/lawyer_ratings.svg';
import calender from '../assets/images/profile/Calendar_duotone.svg'
import deleteIcon from '../assets/images/profile/deleteIcon.jpg'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from 'react-bootstrap/ProgressBar';



const LawyerProfile = () => {
    const access_token = sessionStorage.getItem('access_token');
    const userId = sessionStorage.getItem('userId'); // Assuming you have the user ID stored in sessionStorage
    const [activeTab, setActiveTab] = useState('personal-info');
    const tabRefs = useRef({});
    const [employmentDetails, setEmploymentDetails] = useState([{ profile: userId, employername: '', organisation: '', periodOfEmployment: '', job_title: '' }]);
    const [education, setEducationDetails] = useState([{ profile: userId, university: '', degree: '', passing_year: '' }]);
    const [showEducationButton, setShowEducationButton] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [DeleteButton, setDeleteButton] = useState(false);

    const handleAddNewField = () => {
        setEmploymentDetails([...employmentDetails, { profile: userId, employername: '', organisation: '', periodOfEmployment: '', job_title: '' }]);
        setDeleteButton(true);

    };

    const handleDeleteField = (id) => {
        setEmploymentDetails(employmentDetails.filter(field => field.id !== id));
        setDeleteButton(false);
    };

    const handleAddNewEducationField = () => {
        setEducationDetails([...education, { profile: userId, university: '', degree: '', passing_year: '' }]);
        setDeleteButton(true);


    };

    // Function to delete an education field by id
    const handleDeleteEducationField = (id) => {
        setEducationDetails(prevDetails => prevDetails.filter(field => field.id !== id));
        setDeleteButton(false);

    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (window.innerWidth <= 768) {
            tabRefs.current[tab].scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
    };


    const [userData, setUserData] = useState({
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        dob: '',
        mobile_number: '',
        address: '',
        state: '',
        city: '',
        pincode: '',
        bio: '',
        practice_area: '',
        court_of_practice: '',
        barcode_number: '',
        language: '',
        experience: '',
        consultation_fees: '',
        university: '',
        degree: '',
        passing_year: '',
        facebook: '',
        linkedin: '',
        whatsapp_number: '',
        twitter: '',
        published_works: '',
        honors_and_awards: ''
    });

    const [initialUserData, setInitialUserData] = useState({});
    const [editableFields, setEditableFields] = useState({
        personalInfo: false,
        professionalInfo: false,
        employmentDetails: false,
        education: false,
        onlinePresence: false,
        additionalInfo: false
    });

    const fetchUserData = async () => {

        try {
            const response = await axiosInstance.get('api/profile/', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            const userDetails = response.data[0];
            const user = userDetails.user;

            const fetchedUserData = {
                userId: userDetails.id || '',
                firstName: user.first_name || '',
                lastName: user.last_name || '',
                email: user.email || '',
                dob: userDetails.dob || '',
                mobile_number: userDetails.mobile_number || '',
                address: userDetails.address || '',
                state: userDetails.state || '',
                city: userDetails.city || '',
                pincode: userDetails.pincode || '',
                bio: userDetails.bio || '',
                practice_area: userDetails.practice_area || '',
                court_of_practice: userDetails.court_of_practice || '',
                barcode_number: userDetails.barcode_number || '',
                language: userDetails.language || '',
                experience: userDetails.experience || '',
                consultation_fees: userDetails.consultation_fees || '',
                university: userDetails.university || '',
                degree: userDetails.degree || '',
                passing_year: userDetails.passing_year || '',
                facebook: userDetails.facebook || '',
                linkedin: userDetails.linkedin || '',
                twitter: userDetails.twitter || '',
                whatsapp_number: userDetails.whatsapp_number || '',
                published_works: userDetails.published_works || '',
                honors_and_awards: userDetails.honors_and_awards || ''
            };
            setUserData(fetchedUserData);
            setInitialUserData(fetchedUserData);
            console.log(userDetails.id);
            sessionStorage.setItem('userId', userDetails.id)
            console.log(setDeleteButton);
            if (userDetails.employment_details && userDetails.employment_details.length > 1) {
                console.log("true")
                setDeleteButton(true)
            }
            if (userDetails.employment_details && userDetails.employment_details.length > 0) {
                setEmploymentDetails(userDetails.employment_details.map((detail, index) => ({
                    id: index,
                    ...detail,
                    profile: userId
                })));
                setShowButton(true);
            }

            if (userDetails.education_details && userDetails.education_details.length > 1) {
                setDeleteButton(true);
            }
            if (userDetails.education_details && userDetails.education_details.length > 0) {
                setEducationDetails(userDetails.education_details.map((detail, index) => ({
                    id: index,
                    ...detail,
                    profile: userId
                })));
                setShowButton(true);
                setShowEducationButton(false);
            }

        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [access_token]);

    const handleEmploymentChange = (id, field, value) => {
        setEmploymentDetails(prevDetails =>
            prevDetails.map(detail =>
                detail.id === id ? { ...detail, [field]: value } : detail
            )
        );
    };
    const handleEducationChange = (id, field, value) => {
        setEducationDetails(prevDetails =>
            prevDetails.map(detail =>
                detail.id === id ? { ...detail, [field]: value } : detail
            )
        );
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const toggleEditable = (section) => {
        setEditableFields(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    };

    const handleSubmit = async (section, e) => {
        e.preventDefault();
        const updatedFields = {};
        for (const field in userData) {
            if (userData[field] !== initialUserData[field]) {
                updatedFields[field] = userData[field];
            }
        }

        updatedFields.employment_details = employmentDetails;
        updatedFields.education_details = education;
        // updatedFields.profile = userId; 

        try {
            const response = await axiosInstance.patch('api/profile/500/', updatedFields, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            toast.success('Profile updated successfully');
            fetchUserData();
        } catch (err) {
            if (err.response && err.response.data) {
                const { data } = err.response;
                if (data.experience) {
                    toast.error(`Experience: ${data.experience.join(', ')}`);
                }
                if (data.consultation_fees) {
                    toast.error(`Consultation Fees: ${data.consultation_fees.join(', ')}`);
                }
            } else {
                console.error('Error updating profile:', err);
                toast.error('Failed to update profile');
            }
        }
    };

    const calculateProgress = () => {
        let totalFields = 0;
        let completedFields = 0;

        // Personal Info section fields
        const personalInfoFields = ['firstName', 'lastName', 'email', 'dob', 'mobile_number', 'address', 'state', 'city', 'pincode'];
        totalFields += personalInfoFields.length;
        for (const field of personalInfoFields) {
            if (userData[field]) {
                completedFields++;
            }
        }

        // Professional Info section fields
        const professionalInfoFields = ['practice_area', 'court_of_practice', 'barcode_number', 'language', 'experience', 'consultation_fees'];
        totalFields += professionalInfoFields.length;
        for (const field of professionalInfoFields) {
            if (userData[field]) {
                completedFields++;
            }
        }

        // Employment Details section fields
        const employmentFields = ['employername', 'organisation', 'periodOfEmployment', 'job_title'];
        totalFields += employmentFields.length * employmentDetails.length;
        for (const employment of employmentDetails) {
            for (const field of employmentFields) {
                if (employment[field]) {
                    completedFields++;
                }
            }
        }

        // Education Details section fields
        const educationFields = ['university', 'degree', 'passing_year'];
        totalFields += educationFields.length * education.length;
        for (const edu of education) {
            for (const field of educationFields) {
                if (edu[field]) {
                    completedFields++;
                }
            }
        }

        // Online Presence section fields (example, adjust as per your fields)
        const onlinePresenceFields = ['facebook', 'linkedin', 'twitter', 'whatsapp_number'];
        totalFields += onlinePresenceFields.length;
        for (const field of onlinePresenceFields) {
            if (userData[field]) {
                completedFields++;
            }
        }

        // Additional Info section fields (example, adjust as per your fields)
        const additionalInfoFields = ['published_works', 'honors_and_awards'];
        totalFields += additionalInfoFields.length;
        for (const field of additionalInfoFields) {
            if (userData[field]) {
                completedFields++;
            }
        }

        // Calculate progress percentage
        const progress = (completedFields / totalFields) * 100;
        return progress.toFixed(2);
    };


    const progress = calculateProgress();

    const renderContent = () => {
        switch (activeTab) {
            case 'personal-info':
                return (
                    <>
                        <div className="mt-3 d-flex justify-content-end g-3 edit" style={{ gap: '10px' }} onClick={() => toggleEditable('personalInfo')}>
                            <img src={editButton} alt="" className='editButton' />
                            <i className='edit-button' >
                                {editableFields.personalInfo ? 'Cancel' : 'Edit'}
                            </i>
                        </div>
                        <div className="row mt-3">
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        First Name
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    name='firstName'
                                    placeholder='John'
                                    value={userData.firstName}
                                    onChange={handleInputChange}
                                    disabled={!editableFields.personalInfo} />
                                <span className="lawyer-input-icon"><img src={firstname} alt="" /></span>
                            </div>
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Last Name
                                    </label>
                                </div>
                                <input type="text" className="form-control" name='lastName'
                                    placeholder='Doe'
                                    value={userData.lastName} onChange={handleInputChange} disabled={!editableFields.personalInfo} />
                                <span className="lawyer-input-icon"><img src={firstname} alt="" /></span>
                            </div>
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Date Of Birth
                                    </label>
                                </div>
                                <input type="date" name='dob'
                                    placeholder='15-05-1980'
                                    className="form-control" value={userData.dob} onChange={handleInputChange} disabled={!editableFields.personalInfo} />
                                {/* <span className="lawyer-input-icon"><img src={calender} alt="" /></span> */}
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Email
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='johndoe@gmail.com'
                                    name='email' value={userData.email} onChange={handleInputChange} disabled={!editableFields.personalInfo} />
                                <span className="lawyer-input-icon"><img src={email1} alt="" /></span>
                            </div>
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Mobile Number
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='+91-9876543210'
                                    name='mobile_number' value={userData.mobile_number} onChange={handleInputChange} disabled={!editableFields.personalInfo} />
                                <span className="lawyer-input-icon"><img src={mobile} alt="" /></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-12">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Address
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='123, Marine Drive, Mumbai'
                                    name='address' value={userData.address} onChange={handleInputChange} disabled={!editableFields.personalInfo} />
                                <span className="lawyer-input-icon"><img src={address1} alt="" /></span>

                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        City
                                    </label>
                                </div>
                                <select className="form-control"
                                    name='city'
                                    value={userData.city}
                                    onChange={handleInputChange}
                                    disabled={!editableFields.personalInfo}>
                                    {[
                                        "Agartala", "Agra", "Ahmedabad", "Ahmednagar", "Aizawl",
                                        "Ajmer", "Akola", "Aligarh", "Allahabad", "Alwar",
                                        "Ambala", "Ambattur", "Ambernath", "Amravati", "Amritsar",
                                        "Anand", "Anantapur", "Anantapuram", "Arrah", "Asansol",
                                        "Aurangabad", "Avadi", "Bagasara", "Bahraich", "Ballia",
                                        "Bally", "Bangalore", "Baran", "Baranagar", "Barasat",
                                        "Bareilly", "Bardhaman", "Bathinda", "Beawar", "Begusarai",
                                        "Belgaum", "Bellary", "Bhagalpur", "Bhalswa Jahangir Pur", "Bharatpur",
                                        "Bharuch", "Bhavnagar", "Bhilai", "Bhilwara", "Bhiwandi",
                                        "Bhiwani", "Bhopal", "Bhubaneswar", "Bhuj", "Bidhannagar",
                                        "Bidar", "Bihar Sharif", "Bijapur", "Bikaner", "Bilaspur",
                                        "Bilimora", "Bokaro", "Bulandshahr", "Burhanpur", "Chandigarh",
                                        "Chandrapur", "Chennai", "Chhatarpur", "Chhindwara", "Chittoor",
                                        "Chittorgarh", "Coimbatore", "Cuddalore", "Cuttack", "Dabhoi",
                                        "Damoh", "Darbhanga", "Davanagere", "Dewas", "Dhanbad",
                                        "Dhule", "Dindigul", "Durg", "Durgapur", "Erode",
                                        "Etawah", "Faizabad", "Faridabad", "Farrukhabad", "Fatehpur",
                                        "Firozabad", "Gandhidham", "Gandhinagar", "Gaya", "Ghaziabad",
                                        "Gopalpur", "Gorakhpur", "Gulbarga", "Guna", "Guntur",
                                        "Gurgaon", "Guwahati", "Gwalior", "Haldia", "Haldwani",
                                        "Hapur", "Haridwar", "Hazaribagh", "Himatnagar", "Hindupur",
                                        "Hisar", "Hospet", "Howrah", "Hubballi-Dharwad", "Hyderabad",
                                        "Ichalkaranji", "Imphal", "Indore", "Jabalpur", "Jaipur",
                                        "Jalandhar", "Jalgaon", "Jalna", "Jamnagar", "Jamshedpur",
                                        "Jhansi", "Jodhpur", "Junagadh", "Kadapa", "Kaithal",
                                        "Kakinada", "Kalyan-Dombivli", "Kamarhati", "Kancheepuram", "Kanchrapara",
                                        "Kannur", "Kanpur", "Kapurthala", "Karimnagar", "Karnal",
                                        "Karawal Nagar", "Katni", "Khammam", "Khandwa", "Khanna",
                                        "Kharagpur", "Khora, Ghaziabad", "Kochi", "Kohima", "Kolar",
                                        "Kolhapur", "Kolkata", "Kollam", "Korba", "Kota",
                                        "Kottayam", "Kozhikode", "Kumbakonam", "Kurnool", "Kurukshetra",
                                        "Lakhimpur", "Latur", "Loni", "Lucknow", "Ludhiana",
                                        "Madurai", "Mahesana", "Maheshtala", "Malegaon", "Mangalore",
                                        "Mathura", "Mau", "Meerut", "Moradabad", "Mormugao",
                                        "Morena", "Muzaffarnagar", "Muzaffarpur", "Mysore", "Nadiad",
                                        "Nagercoil", "Nagpur", "Naihati", "Nalgonda", "Nanded",
                                        "Nashik", "Navsari", "Navi Mumbai", "Nawada", "Nellore",
                                        "New Delhi", "Nizamabad", "Ongole", "Orai", "Palanpur",
                                        "Palghar", "Pali", "Panaji", "Panipat", "Parbhani",
                                        "Patiala", "Patna", "Pilibhit", "Pimpri-Chinchwad", "Pondicherry",
                                        "Porbandar", "Proddatur", "Pudukkottai", "Pune", "Puri",
                                        "Purnia", "Raebareli", "Raichur", "Raiganj", "Raipur",
                                        "Rajahmundry", "Rajapalayam", "Rajgarh", "Rajkot", "Rajpur Sonarpur",
                                        "Ramachandrapuram", "Ramagundam", "Rampur", "Ranchi", "Ratlam",
                                        "Raurkela", "Rewa", "Rohtak", "Roorkee", "Rourkela",
                                        "Sagar", "Saharanpur", "Salem", "Sambalpur", "Sambhal",
                                        "Sangli-Miraj & Kupwad", "Satna", "Secunderabad", "Sehore", "Shahdol",
                                        "Shahjahanpur", "Shillong", "Shimla", "Shimoga", "Shivpuri",
                                        "Siliguri", "Singrauli", "Sitapur", "Solapur", "Sonipat",
                                        "South Dumdum", "Sri Ganganagar", "Srinagar", "Surat", "Surendranagar",
                                        "Tadepalligudem", "Tenali", "Thane", "Thanjavur", "Thiruvananthapuram",
                                        "Thoothukudi", "Thrissur", "Tiruchirappalli", "Tirunelveli", "Tirupati",
                                        "Tiruppur", "Tumkur", "Udaipur", "Udupi", "Ujjain",
                                        "Ulhasnagar", "Uluberia", "Unnao", "Vadodara", "Valsad",
                                        "Varanasi", "Vasai-Virar", "Vellore", "Veraval", "Vidisha",
                                        "Vijayawada", "Vijayanagaram", "Vikarabad", "Villupuram", "Visakhapatnam",
                                        "Warangal", "Wardha", "Yamunanagar"
                                    ].map((city) => (
                                        <option key={city} value={city}>
                                            {city}
                                        </option>
                                    ))}
                                </select>
                                <span className="lawyer-input-icon">
                                    <img src={address1} alt="" />
                                </span>
                            </div>


                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Pin Code
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='400020'
                                    name='pincode' value={userData.pincode} onChange={handleInputChange} disabled={!editableFields.personalInfo} />
                            </div>
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        State
                                    </label>
                                </div>
                                <select className="form-control"
                                    name='state'
                                    value={userData.state}
                                    onChange={handleInputChange}
                                    disabled={!editableFields.personalInfo}>
                                    {[
                                        "Andaman and Nicobar Islands", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar",
                                        "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli and Daman and Diu", "Delhi", "Goa",
                                        "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka",
                                        "Kerala", "Lakshadweep", "Madhya Pradesh", "Maharashtra", "Manipur",
                                        "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Puducherry",
                                        "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana",
                                        "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"
                                    ].map((state) => (
                                        <option key={state} value={state}>
                                            {state}
                                        </option>
                                    ))}
                                </select>
                                <span className="lawyer-input-icon">
                                    <img src={address1} alt="" />
                                </span>
                            </div>

                        </div>
                        <div className="saveButton">
                            {editableFields.personalInfo && (
                                <button className="submitButton ml-2" onClick={(e) => handleSubmit('personalInfo', e)}>Save Changes</button>
                            )}
                        </div>
                    </>
                );
            case 'professional-info':
                return (
                    <>
                        <div className="mt-3 d-flex justify-content-end g-3 edit" style={{ gap: '10px' }} onClick={() => toggleEditable('professionalInfo')}>
                            <img src={editButton} alt="" className='editButton' />
                            <i className='edit-button' >
                                {editableFields.professionalInfo ? 'Cancel' : 'Edit'}
                            </i>
                        </div>
                        <div className="row first-row">
                            <div className="form-group col-md-6">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Practice Area
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='Corporate Law, Intellectual Property Law'
                                    name='practice_area' value={userData.practice_area} onChange={handleInputChange} disabled={!editableFields.professionalInfo} />
                            </div>
                            <div className="form-group col-md-6">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Court Of Practice
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='District Court And High Court'
                                    name='court_of_practice'
                                    value={userData.court_of_practice}
                                    onChange={handleInputChange}
                                    disabled={!editableFields.professionalInfo} />
                            </div>

                            <div className="form-group">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Bio
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='An experienced lawyer specializing in corporate and IP law.'
                                    name='bio' value={userData.bio} onChange={handleInputChange} disabled={!editableFields.professionalInfo}></input>
                            </div>
                        </div>
                        <div className="row second-row">
                            <div className="form-group col-md-3">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Experience
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='10 years'
                                    name='experience' value={userData.experience} onChange={handleInputChange} disabled={!editableFields.professionalInfo} />
                                <span className="lawyer-input-icon"><img src={experience} alt="" /></span>
                            </div>
                            <div className="form-group col-md-3">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Barcode Number
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='D-2591/2017'
                                    name='barcode_number' value={userData.barcode_number} onChange={handleInputChange} disabled={!editableFields.professionalInfo} />
                                {/* <span className="lawyer-input-icon"><img src={barcodeNumber} alt="" /></span> */}
                            </div>
                            <div className="form-group col-md-3">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Language
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='English, Hindi'
                                    name='language' value={userData.language} onChange={handleInputChange} disabled={!editableFields.professionalInfo} />
                                <span className="lawyer-input-icon"><img src={lawyer_language} alt="" /></span>
                            </div>
                        </div>
                        <div className="saveButton">
                            {editableFields.professionalInfo && (
                                <button className="submitButton ml-2" onClick={(e) => handleSubmit('professionalInfo', e)}>Save Changes</button>
                            )}
                        </div>
                    </>
                );
            case 'employment-details':
                return (
                    <>
                        <div className="mt-3 d-flex justify-content-end g-3 edit" style={{ gap: '10px' }} onClick={() => toggleEditable('employmentDetails')}>
                            <img src={editButton} alt="" className='editButton' />
                            <i className='edit-button' >
                                {editableFields.employmentDetails ? 'Cancel' : 'Edit'}
                            </i>
                        </div>
                        {employmentDetails.map((field, index) => (
                            <div className="">
                                <div className="row" key={field.id}>
                                    <div className="second-row">
                                        <div className="form-group col-md-4">
                                            <div className="label-container">
                                                <label>
                                                    <span className="label-icon"></span>
                                                    Previous Employer Name
                                                </label>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='Senior Advocate'
                                                value={field.employername}
                                                onChange={(e) => handleEmploymentChange(field.id, 'employername', e.target.value)}
                                                disabled={!editableFields.employmentDetails} />
                                            <span className="lawyer-input-icon"><img src={firstname} alt="" /></span>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <div className="label-container">
                                                <label>
                                                    <span className="label-icon"></span>
                                                    Previous Organization
                                                </label>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='ABC Law Firm'
                                                value={field.organisation}
                                                onChange={(e) => handleEmploymentChange(field.id, 'organisation', e.target.value)}
                                                disabled={!editableFields.employmentDetails}
                                            />
                                            {/* <span className="lawyer-input-icon"><img src={firstname} alt="" /></span> */}
                                        </div>
                                    </div>
                                    <div className="second-row">


                                        <div className="form-group col-md-4">
                                            <div className="label-container">
                                                <label>
                                                    <span className="label-icon"></span>
                                                    Period Of Employment
                                                </label>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='5 years'
                                                value={field.periodOfEmployment}
                                                onChange={(e) => handleEmploymentChange(field.id, 'periodOfEmployment', e.target.value)}
                                                disabled={!editableFields.employmentDetails}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <div className="label-container">
                                                <label>
                                                    <span className="label-icon"></span>
                                                    Job title
                                                </label>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='XYZ Law Associates'
                                                value={field.job_title}
                                                onChange={(e) => handleEmploymentChange(field.id, 'job_title', e.target.value)}
                                                disabled={!editableFields.employmentDetails}
                                            />
                                            {/* <span className="lawyer-input-icon"><img src={firstname} alt="" /></span> */}
                                        </div>
                                    </div>
                                </div>

                                {DeleteButton && (

                                    <div className="lawyer-profile-delete-button">
                                        <button type="button" className="delete" onClick={() => handleDeleteField(field.id)}>
                                            <FontAwesomeIcon icon={faTrash} /> Delete
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                        {showButton && (
                            <div className="add-new-fields" onClick={handleAddNewField}>
                                <p>
                                    <FontAwesomeIcon icon={faPlus} /> Add new field
                                </p>
                            </div>
                        )}
                        <div className="saveButton">
                            {editableFields.employmentDetails && (
                                <button className="submitButton ml-2" onClick={(e) => handleSubmit('employmentDetails', e)}>Save Changes</button>
                            )}
                        </div>
                    </>
                );
            case 'education':
                return (
                    <>
                        <div className="mt-3 d-flex justify-content-end g-3 edit" style={{ gap: '10px' }} onClick={() => toggleEditable('education')}>
                            <img src={editButton} alt="" className='editButton' />
                            <i className='edit-button' >
                                {editableFields.education ? 'Cancel' : 'Edit'}
                            </i>
                        </div>
                        {education.map((field, index) => (
                            <div className="row third-row" key={field.id}>
                                <div className="form-group col-md-3">
                                    <div className="label-container">
                                        <label>
                                            <span className="label-icon"></span>
                                            University
                                        </label>
                                    </div>
                                    <input type="text" className="form-control"
                                        placeholder='University of Mumbai'
                                        name='university'
                                        value={field.university}
                                        onChange={(e) => handleEducationChange(field.id, 'university', e.target.value)}
                                        disabled={!editableFields.education} />
                                </div>
                                <div className="form-group col-md-3">
                                    <div className="label-container">
                                        <label>
                                            <span className="label-icon"></span>
                                            Degree
                                        </label>
                                    </div>
                                    <input type="text" className="form-control"
                                        placeholder='LLB'
                                        name='degree'
                                        value={field.degree}
                                        onChange={(e) => handleEducationChange(field.id, 'degree', e.target.value)}
                                        disabled={!editableFields.education} />
                                </div>
                                <div className="form-group col-md-3">
                                    <div className="label-container">
                                        <label>
                                            <span className="label-icon"></span>
                                            Passing year
                                        </label>
                                    </div>
                                    <input type="text" className="form-control"
                                        placeholder='2005'
                                        name='passing_year'
                                        value={field.passing_year}
                                        onChange={(e) => handleEducationChange(field.id, 'passing_year', e.target.value)}
                                        isabled={!editableFields.education} />
                                    <span className="lawyer-input-icon"><img src={calender} alt="" /></span>
                                </div>

                                {DeleteButton && (
                                    <>
                                        <div className="lawyer-profile-delete-button">
                                            <button type="button" className="delete" onClick={() => handleDeleteEducationField(field.id)}>
                                                <FontAwesomeIcon icon={faTrash} /> Delete
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                        {showButton && (
                            <div className="add-new-fields" onClick={handleAddNewEducationField}>
                                <p>
                                    <FontAwesomeIcon icon={faPlus} /> Add new field
                                </p>
                            </div>
                        )}
                        <div className="saveButton">
                            {editableFields.education && (
                                <button className="submitButton ml-2" onClick={(e) => handleSubmit('education', e)}>Save Changes</button>
                            )}
                        </div>
                    </>
                );
            case 'online-presence':
                return (
                    <>
                        <div className="mt-3 d-flex justify-content-end g-3 edit" style={{ gap: '10px' }} onClick={() => toggleEditable('onlinePresence')}>
                            <img src={editButton} alt="" className='editButton' />
                            <i className='edit-button' >
                                {editableFields.onlinePresence ? 'Cancel' : 'Edit'}
                            </i>
                        </div>
                        <div className="row sixth-row">
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Facebook
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='https://www.facebook.com/johndoe'
                                    name='facebook' value={userData.facebook} onChange={handleInputChange} disabled={!editableFields.onlinePresence} />
                            </div>
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        LinkedIn
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='https://www.linkedin.com/in/johndoe'
                                    name='linkedin' value={userData.linkedin} onChange={handleInputChange} disabled={!editableFields.onlinePresence} />
                            </div>
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Twitter
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='https://twitter.com/johndoe'
                                    name='twitter' value={userData.twitter} onChange={handleInputChange} disabled={!editableFields.onlinePresence} />
                            </div>
                            <div className="form-group col-md-4">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Work Mobile Number
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='+91-9876543210'
                                    name='whatsapp_number'
                                    value={userData.whatsapp_number}
                                    onChange={handleInputChange}
                                    disabled={!editableFields.onlinePresence} />
                                <span className="lawyer-input-icon"><img src={mobile} alt="" /></span>

                            </div>
                            <div className="saveButton">
                                {editableFields.onlinePresence && (
                                    <button className="submitButton ml-2" onClick={(e) => handleSubmit('onlinePresence', e)}>Save Changes</button>
                                )}
                            </div>
                        </div>
                    </>
                );
            case 'additional-info':
                return (
                    <>
                        <div className="mt-3 d-flex justify-content-end g-3 edit" style={{ gap: '10px' }} onClick={() => toggleEditable('additionalInfo')}>
                            <img src={editButton} alt="" className='editButton' />
                            <i className='edit-button' >
                                {editableFields.additionalInfo ? 'Cancel' : 'Edit'}
                            </i>
                        </div>
                        <div className="row sixth-row">
                            <div className="form-group col-md-12">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Published Work
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='Legal Aspects of Intellectual Property'
                                    name='published_works' value={userData.published_works} onChange={handleInputChange} disabled={!editableFields.additionalInfo} />
                            </div>
                            <div className="form-group col-md-12">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Honours and Rewards
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='Best Lawyer Award 2018'
                                    name='honors_and_awards' value={userData.honors_and_awards} onChange={handleInputChange} disabled={!editableFields.additionalInfo} />
                            </div>
                            <div className="form-group col-md-3">
                                <div className="label-container">
                                    <label>
                                        <span className="label-icon"></span>
                                        Consultation Fees
                                    </label>
                                </div>
                                <input type="text" className="form-control"
                                    placeholder='INR 5000 per hour'
                                    name='consultation_fees' value={userData.consultation_fees} onChange={handleInputChange} disabled={!editableFields.additionalInfo} />
                            </div>
                            <div className="saveButton">
                                {editableFields.additionalInfo && (
                                    <button className="submitButton ml-2" onClick={(e) => handleSubmit('additionalInfo', e)}>Save Changes</button>
                                )}
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="lawyer-profile-container">
                <h2 className='text-center'><span style={{ color: '#5D54F1' }}>Lawyer's</span> Profile</h2>
                <div className="lawyer-profile-left-section-top">
                    <div className="lawyer-profile-left-section">
                        <div className="lawyer-profile-sidebar">
                            <img src={image} alt="" className='laptopUserIcon' />
                            <img src={mobileImage} alt="" className='mobileUserIcon' />
                        </div>
                        <div className="lawyer-contact-details">
                            <h4>{userData.firstName} {userData.lastName}</h4>
                            <div className="horizontal-line"></div>
                            <h6 className='lawyer-contact'>Contact Details</h6>
                            <div className='image-text'>
                                <img src={email} alt="" className='lawyerProfileIContactImages' />
                                <div className="contact-item">
                                    <span className="value">{userData.email}</span>
                                </div>
                            </div>
                            <div className="contact-item">
                                <div className='image-text'>
                                    <img src={phone} alt="" className='lawyerProfileIContactImages' />
                                    <div className="contact-item">
                                        <span className="value">{userData.mobile_number}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-item">
                                <div className='image-text'>
                                    <img src={address} alt="" className='lawyerProfileIContactImages' />
                                    <div className="contact-item">
                                        <span className="value">{userData.address}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lawyer-profile-top-container">
                            <img
                                src={lawyerProfileIllustration}
                                alt="New top image"
                                className="lawyer-profile-landing-top-image"
                            />
                        </div>
                    </div>
                </div>

                <div className="right-side-container">
                    <div className="mobiletabs">
                        <button ref={(el) => tabRefs.current['personal-info'] = el} className={`tab-button ${activeTab === 'personal-info' ? 'active' : ''}`} onClick={() => handleTabClick('personal-info')}>Personal Information</button>
                        <button ref={(el) => tabRefs.current['professional-info'] = el} className={`tab-button ${activeTab === 'professional-info' ? 'active' : ''}`} onClick={() => handleTabClick('professional-info')}>Professional Information</button>
                        <button ref={(el) => tabRefs.current['employment-details'] = el} className={`tab-button ${activeTab === 'employment-details' ? 'active' : ''}`} onClick={() => handleTabClick('employment-details')}>Employment Details</button>
                        <button ref={(el) => tabRefs.current['education'] = el} className={`tab-button ${activeTab === 'education' ? 'active' : ''}`} onClick={() => handleTabClick('education')}>Education</button>
                        <button ref={(el) => tabRefs.current['online-presence'] = el} className={`tab-button ${activeTab === 'online-presence' ? 'active' : ''}`} onClick={() => handleTabClick('online-presence')}>Online Presence</button>
                        <button ref={(el) => tabRefs.current['additional-info'] = el} className={`tab-button ${activeTab === 'additional-info' ? 'active' : ''}`} onClick={() => handleTabClick('additional-info')}>Additional Information</button>
                    </div>
                    <div className='lawyer-right-section'>
                        <div className="laptop-tabs">
                            <button ref={(el) => tabRefs.current['personal-info'] = el} className={`tab-button ${activeTab === 'personal-info' ? 'active' : ''}`} onClick={() => handleTabClick('personal-info')}>1. Personal Information</button>
                            <button ref={(el) => tabRefs.current['professional-info'] = el} className={`tab-button ${activeTab === 'professional-info' ? 'active' : ''}`} onClick={() => handleTabClick('professional-info')}>2. Professional Information</button>
                            <button ref={(el) => tabRefs.current['employment-details'] = el} className={`tab-button ${activeTab === 'employment-details' ? 'active' : ''}`} onClick={() => handleTabClick('employment-details')}>3. Employment Details</button>
                            <button ref={(el) => tabRefs.current['education'] = el} className={`tab-button ${activeTab === 'education' ? 'active' : ''}`} onClick={() => handleTabClick('education')}>4. Education</button>
                            <button ref={(el) => tabRefs.current['online-presence'] = el} className={`tab-button ${activeTab === 'online-presence' ? 'active' : ''}`} onClick={() => handleTabClick('online-presence')}>5. Online Presence</button>
                            <button ref={(el) => tabRefs.current['additional-info'] = el} className={`tab-button ${activeTab === 'additional-info' ? 'active' : ''}`} onClick={() => handleTabClick('additional-info')}>6. Additional Information</button>
                        </div>
                        <div className="container-fluid">

                            <div className="tab-content">
                                {renderContent()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lawyer-progress-bar-contain">

                    <div className="laywerProfileProgressBar">
                        <ProgressBar>
                            <ProgressBar animated now={progress} key={1} />
                        </ProgressBar>
                        <p>{progress}% completed</p>
                    </div>
                </div>
                {/* <div className="progress-container">
                        <div className="progress-bar" style={{ width: `${calculateProgress()}%` }}></div>
                    </div> */}
            </div>
        </>
    );
};

export default LawyerProfile;
