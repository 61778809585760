import React, { useContext, useState } from 'react';
import axios from 'axios';
import '../assets/css/NotificationBar.css';
import feedbackIcon from '../assets/images/notification/feedbackIncon.svg';
import { useNotificationContext } from '../NotificationContext';
import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-toastify';

const NotificationBar = () => {
    const { isPopupVisible, setIsPopupVisible } = useNotificationContext();
    const [visible, setVisible] = useState(true);
    const [selectedRating, setSelectedRating] = useState(null);
    const [service, setPage] = useState('');
    const [description, setFeedback] = useState('');
    const [loading, setLoading] = useState('');
    const access_token = sessionStorage.getItem("access_token");

    const handleRatingChange = (event) => {
        setSelectedRating(event.target.value);
    };

    const handlePageChange = (event) => {
        setPage(event.target.value);
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleFeedbackClick = () => {
        setIsPopupVisible(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!service || !selectedRating) {
            toast.error('Please fill in all fields');
            return;
        }
        const feedbackData = {
            service,
            ratings: selectedRating,
            description
        };

        setLoading(true)
        try {
            const response = await axiosInstance.post('feedback/feedback/', feedbackData, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json'
                }
            });
            toast.success('Feedback submitted successfully:', response.data);
            setIsPopupVisible(false);
            service('')
            description('')
            selectedRating('');

        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
        finally {
            setLoading(false);
        }
    };

    if (!visible) return null;

    return (
        <div className={isPopupVisible ? 'blur-background' : ''}>

            {isPopupVisible && (
                <div className="popup">
                    <div className="popup-content">
                                        <button className="popup-close-button" onClick={() => setIsPopupVisible(false)}>×</button>
                        <div className="popup-beside-content">
                            <div className="notificationBar-content">
                                <div className="under-side-content">
                                    <img src={feedbackIcon} alt="" />
                                    <div className="text-content-under-side">
                                    
                                        <h2>Send us your feedback!</h2>
                                        <p>Do you have a suggestion or had any problems?
                                            Let us know in the fields below.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="offside-content">
                                <div className="inputs">
                                    <p>Choose the page you want to rate</p>
                                    <select className="select" onChange={handlePageChange} value={service}>
                                        <option value="">Select a page</option>
                                        <option value="HOMEPAGE">Homepage</option>
                                        <option value="CASE TRACKING">Case Tracking</option>
                                        <option value="CHATBOT">ChatBot</option>
                                    </select>
                                </div>
                                <div className="rating">
                                    <h6>Rate your experience with us</h6>
                                    <form>
                                        {[...Array(10)].map((_, i) => (
                                            <React.Fragment key={i + 1}>
                                                <input
                                                    type="radio"
                                                    id={`rate${i + 1}`}
                                                    name="rating"
                                                    value={i + 1}
                                                    checked={selectedRating === `${i + 1}`}
                                                    onChange={handleRatingChange}
                                                />
                                                <label htmlFor={`rate${i + 1}`}>{i + 1}</label>
                                            </React.Fragment>
                                        ))}
                                    </form>
                                </div>
                                <div className='under-input-section'>
                                    <h5>Tell us about your experience</h5>
                                    <textarea placeholder="Description" value={description} onChange={handleFeedbackChange} required></textarea>
                                    <div className="pop-up-submit">
                                        <button type="submit" onClick={handleSubmit}>
                                            {loading ? "Please Wait..." : "Submit"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationBar;
