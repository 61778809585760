import React, { useContext, useState } from 'react';
import axios from 'axios';
import '../assets/css/NotificationBar.css';
import feedbackIcon from '../assets/images/notification/feedbackIncon.svg';
import { useNotificationContext } from '../NotificationContext';
import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-toastify';

const NewNotificationBar = () => {
    const { isPopupVisible, setIsPopupVisible } = useNotificationContext();
    const [visible, setVisible] = useState(true);
    
    const handleFeedbackClick = () => {
        setIsPopupVisible(true);
    };

    if (!visible) return null;
    
    return (
        <div className={isPopupVisible ? 'blur-background' : ''}>
            <div className="notification-bar">
                <div className="notification-para">
                    <p>
                        This feature is a BETA version. Your <span className="feedback-link" onClick={handleFeedbackClick}>
                            feedback
                        </span> is valuable!{' '}
                    </p>
                </div>
                <button className="notification-close-button" onClick={() => setVisible(false)}>×</button>
            </div>
        </div>
    );
};

export default NewNotificationBar;
