// Preloader.js
import React, { useEffect } from 'react';
import '../assets/css/preloader.css';
import logoImage from '../assets/images/Preloader/background.svg';
import newSymbol from '../assets/images/Preloader/New_Symbol.png';
import gaval from '../assets/images/Preloader/gaval.svg';

const Preloader = () => {
    useEffect(() => {
        const handleLoad = () => {
            const preloader = document.querySelector('.preloader');
            if (preloader) {
                preloader.classList.add('hide');
            }
        };

        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    return (
        <div className="preloader">
            <div className="loader-content">
                <img src={logoImage} alt="Case Minister Logo" className="loader-logo-image" />
                <img src={newSymbol} alt="" className='newsymbol'/>
                <div className="loader-below-content">
                    <h1>CASE MINISTER</h1>
                    <p>Innovating Legal Solutions</p>
                    <img src={gaval} alt=""  style={{marginTop:'20px'}}/>
                </div>
            </div>
        </div>
    );
};

export default Preloader;
