import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./Protectedroute";
import { ToastContainer } from 'react-toastify';
import Spinner from "./Component/Spinner";

// Directly import components
import LandingPage from "./Component/LandingPage";
import SecondaryNavbar from "./Component/SecondaryNavbar";
import Ourservices from "./Component/Ourservices";
import Blog from "./Component/Blog";
import Testimonial1 from "./Component/Testimonial1";
import Homepage from "./Component/Homepage";
import ChatBotParent from "./Component/ChatBotParent";
import CaseDetails from "./Component/CaseDetails";
import Navbar from "./Component/Navbar";
import Mycases from "./Component/Mycases";
import NewLogin from "./Component/NewLogin";
import Newsignup from "./Component/Newsignup";
import Searchlawyer from "./Component/Searchlawyer";
import Lawyerfilter from "./Component/Lawyerfilter";
import UserProfile from "./Component/Userprofile";
import PrivilegedContent from "./Component/PrivilegedContent";
import Faq from "./Component/Faq";
import NonPrivilegedPage from "./Component/NonPrivilegedPage";
import Pricing from "./Component/Pricing";
import ContactUs from "./Component/ContactUs";
import LawyerProfile from "./Component/LawyerProfile";
import CourtSelection from "./Component/CourtSelection";
import HighCourt from "./Component/HighCourt";
import HighCourtCaseDetails from "./Component/HighCourtCaseDetails";
import HighCourtPriviledge from "./Component/HighCourtPriviledge";
import { CaseProvider } from './CaseContext';
import ReactGA from 'react-ga4';
import ComingSoon from "./Component/ComingSoon";
import PerformanceAnalytics from "./Component/PerformanceAnalytics";
import DocumentationAndCustomization from "./Component/DocumentationAndCustomization";
import ExpertLawyerMatching from "./Component/ExpertLawyerMatching";
import PredictiveAnalysis from "./Component/PredictiveAnalysis";
import { NotificationProvider } from './NotificationContext';
import BetaNotification from './Component/BetaNotification'
import PrivacyPolicy from "./Component/PrivacyPolicy";
import TermsAndConditions from "./Component/TermsAndCondition";
import UploadJudgement from "./Component/UploadJudgement";
import { FolderProvider } from './FolderContext';
import WaitList from './Component/WaitList';
import SupremeCourtPrivilede from './Component/SupremeCourtPrivilede';
import PanjabAndHaraynaHC from './Component/PanjabAndHaraynaHC';
import Allhighcourt from "./Component/Allhighcourt";
import Allhighcourtprivildge from "./Component/Allhighcourtprivildge"
import UserDashboard from "./Component/UserDashboard";

ReactGA.initialize('G-FJ8EC6ZWVF');

const App = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div>
      <NotificationProvider>
        <BetaNotification />
        <ToastContainer />
        <FolderProvider>
          <CaseProvider>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/login" element={<NewLogin />} />
              <Route path="/signup" element={<Newsignup />} />
              <Route path="/navbar" element={<Navbar />} />
              <Route path="/ourservices" element={<Ourservices />} />
              <Route path="/performance-analytic--coming-soon" element={<PerformanceAnalytics />} />
              <Route path="/documentation-and-customization-coming-soon" element={<DocumentationAndCustomization />} />
              <Route path="/expert-lawyer-matching-coming-soon" element={<ExpertLawyerMatching />} />
              <Route path="/predictive-analysis-coming-soon" element={<PredictiveAnalysis />} />
              <Route path="/searchlawyer" element={<ProtectedRoute> <Searchlawyer /></ProtectedRoute>} />
              <Route path="/mycases" element={<ProtectedRoute><Mycases /></ProtectedRoute>} />
              <Route path="/Filteredlawyer" element={<ProtectedRoute><Lawyerfilter /></ProtectedRoute>} />
              <Route path='/user-profile' element={<ProtectedRoute> <UserProfile /></ProtectedRoute>} />
              <Route path="/home" element={<SecondaryNavbar />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/testimonial" element={<Testimonial1 />} />
              <Route path="/privilege" element={<PrivilegedContent />} />
              <Route path="/landingpage" element={<LandingPage />} />
              <Route path="/coming-soon" element={<ComingSoon />} />
              <Route path="/casedetails" element={<ProtectedRoute><CaseDetails /></ProtectedRoute>} />
              <Route path="/talkaboutcase" element={<ProtectedRoute><ChatBotParent /></ProtectedRoute>} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/Contactus" element={<ContactUs />} />
              <Route path="/Lawyerprofile" element={<LawyerProfile />} />
              <Route path="/select-court" element={<CourtSelection />} />
              <Route path="/high-court" element={<HighCourtPriviledge />} />
              <Route path="/supreme-court" element={<SupremeCourtPrivilede />} />
              <Route path="/high-court-case-details" element={<HighCourtCaseDetails />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/upload_judgement" element={<ProtectedRoute><UploadJudgement /></ProtectedRoute>} />
              <Route path="/wait-list" element={<WaitList />} />
              <Route path="/ph_high-court" element={<PanjabAndHaraynaHC />} />
              <Route path="/all-high-court" element={<Allhighcourt/>}/>
              <Route path="/all-high-court-privilege" element={<Allhighcourtprivildge />} />
              <Route path="/user-dashboard" element={<UserDashboard/>}/>
            </Routes>
          </CaseProvider>
        </FolderProvider>
      </NotificationProvider>
    </div>
  );
};

export default App;