import React, { useState, useEffect } from 'react';
import '../assets/css/NotificationBar.css'; // Make sure to create the corresponding CSS file
import { FaTimes } from 'react-icons/fa';

const NotificationBar = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isAuthenticated');
        const notificationDismissed = sessionStorage.getItem('notificationDismissed');

        if (isLoggedIn && !notificationDismissed) {
            setVisible(true);
            const timer = setTimeout(() => {
                setVisible(false);
            }, 60000);

            return () => clearTimeout(timer);
        }
    }, []);

    const handleClose = () => {
        setVisible(false);
        sessionStorage.setItem('notificationDismissed', 'true');
    };

    if (!visible) return null;

    return (
        <div className="notification-bar">
            <div className="notification-para">
                <p>Check out our <span style={{ color: '#D6D3FB' }}>beta features </span>and stay tuned for more updates!</p>
                <button className="notification-close-button" onClick={handleClose}>
                    <FaTimes />
                </button>
            </div>
        </div>
    );
};

export default NotificationBar;
