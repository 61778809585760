import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./Authcontext"; // Import AuthProvider

ReactDOM.render(
  <Router>
    <GoogleOAuthProvider clientId="246105724090-95arttgdnp0sohiaqqtvpctr875kassq.apps.googleusercontent.com">
      <AuthProvider>
          <App />          
      </AuthProvider>
    </GoogleOAuthProvider>
  </Router>,
  document.getElementById("root")
);
