import React from 'react';
import '../assets/css/pricing.css';
import Navbar from './Navbar'
import FirstCirlcle from '../assets/images/pricing/first-circle.svg'
import SecondCirlce from '../assets/images/pricing/second-circle.svg'
import rightone from '../assets/images/pricing/rightcolotic.svg'
import leftone from '../assets/images/pricing/leftcoltic.svg'
import topselect from '../assets/images/pricing/top-select.png'
import { color } from 'framer-motion';
const PricingPlans = () => {
    return (
        <>

            <Navbar />
            <div className="pricing-plans">
                <h2> <span style={{ color: '#5D54F1' }}>Plans</span> & Pricing</h2>
                <div className="features">
                    <div className="feature-item">
                        <img src={topselect} alt="" />
                        <p>Plans for all firm sizes, with clear, no-hidden-fee pricing for confident budgeting</p>
                    </div>
                    <div className="feature-item">
                        <img src={topselect} alt="" />
                        <p>Our pricing plans includes access to a robust suite of tools and features tailored just for you</p>
                    </div>
                    <div className="feature-item">
                        <img src={topselect} alt="" />
                        <p>Offering dedicated customer 
                        support with every plan</p>
                    </div>
                </div>

                <div className="pricing-details">
                    <div className="pricing-plan free-plan">
                        <div className="pricing-content-wrapper">
                            <div className='pricing-main-content-1'>
                                <div className='pl-5 py-2 pricing-upper-content'>
                                    <h3 style={{ color: '#000' }}>FREE</h3>
                                    {/* <p style={{ fontFamily: 'raleway', fontSize: '28px', fontWeight: 'bold', color: '#5D54F1' }}></p> */}
                                    <p className='pricing-para'>Create an account and start for free. No credit card required.</p>
                                    <button onClick={()=>{window.location.href='/talkaboutcase'}}>Use Our Chatbot</button>
                                </div>
                                <ul>
                                    <li className="list-item">
                                        <img src={leftone} alt="" />
                                        <span>Get personalised responses</span>
                                    </li>
                                    <li className="list-item">
                                        <img src={leftone} alt="" />
                                        <span>Instant support from our advanced AI chatbot</span>
                                    </li>
                                    <li className="list-item">
                                        <img src={leftone} alt="" />
                                        <span>Available 24/7 to assist with queries</span>
                                    </li>
                                    <li className="list-item">
                                        <img src={leftone} alt="" />
                                        <span>View chat history and easily track previously accessed information</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="pricing-plan premium-plan">
                        <div className="pricing-content-wrapper">
                            <div className="pricing-main-content-2">
                            <div className="upper-content">
                                <h3>Premium Subscription</h3>
                                <div className='free-trial'>
                                    <p style={{color:'white', fontSize:'20px', fontWeight:'700'}}>Free Trial For First 15 Days</p>
                                </div>
                                <button onClick={()=>{window.location.href='/mycases'}}>Unlock Live Case Tracking</button>
                            </div>
                                <ul>
                                    <li className="list-item">
                                        <img src={rightone} alt="" />
                                        <span>Everything included in our FREE plan</span>
                                    </li>
                                    <li className="list-item">
                                        <img src={rightone} alt="" />
                                        <span>Unlock additional features like live case tracking, performance analytics, documentation, lawyer matching & predictive analytics</span>
                                    </li>
                                    <li className="list-item">
                                        <img src={rightone} alt="" />
                                        <span>Enjoy a 15 day free trial for all</span>
                                    </li>
                                    <li className="list-item">
                                        <img src={rightone} alt="" />
                                        <span>Subsequently INR 7,999 per month</span>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        </>
    );
};

export default PricingPlans;
