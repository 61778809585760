import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { toast } from 'react-toastify';
import axiosInstance from '../utils/axiosInstance';
import SupremeCoutDetail from './SupremeCoutDetail';
import illustration from '../assets/images/selectcourt/select-court-illustration.png';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backimg from '../assets/images/mycases/back-image.svg'
import unselected from '../assets/images/mycases/unselected-for-arrow.svg'
import unselecteddelete from '../assets/images/mycases/unselected-delete.svg'
import infoimage from '../assets/images/mycases/info-image.svg'
import search from '../assets/images/mycases/search-vector.svg'
import bottomimage from '../assets/images/mycases/bottom-add-case.svg'
import backbutton from '../assets/images/mycases/back-button.svg'
import { useCaseContext } from '../CaseContext';
import mobilePriviledge from '../assets/images/mycases/mobilePriviledge.svg'
import { FaTimes } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../assets/css/supremecourt.css';
import diary_number from '../assets/images/supreme_court/diarybutton.svg'
import case_type from '../assets/images/supreme_court/case_type.svg'
import supreme_court_illustration from '../assets/images/supreme_court/supreme_court_illustration.svg'
import supreme_court_illustration2 from '../assets/images/supreme_court/supreme_court_illustration2.svg'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const SupremeCourtPrivilede = () => {

    const { selecteSupremeCourtCase, setSelecteSupremeCourtCase } = useCaseContext();
    const [cases, setCases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoadingCases, setIsLoadingCases] = useState(true);
    const [showAddCase, setShowAddCase] = useState(false);
    const [caseType, setCaseType] = useState('');
    const [name, setName] = useState('');
    const [caseNumber, setCaseNumber] = useState('');
    const [year, setYear] = useState('');
    const [selectedOption, setSelectedOption] = useState('Diary Number');
    const [dairyNumber, setDairyNumber] = useState('');

    console.log(cases);

    const access_token = sessionStorage.getItem('access_token');

    const storedUserInfo = sessionStorage.getItem("user-info");
    const parsedUserInfo = storedUserInfo ? JSON.parse(storedUserInfo) : null;


    const caseTypes = [
        "SPECIAL LEAVE PETITION (CIVIL)",
        "SPECIAL LEAVE PETITION (CRIMINAL)",
        "CIVIL APPEAL",
        "CRIMINAL APPEAL",
        "WRIT PETITION (CIVIL)",
        "WRIT PETITION (CRIMINAL)",
        "TRANSFER PETITION (CIVIL)",
        "TRANSFER PETITION (CRIMINAL)",
        "REVIEW PETITION (CIVIL)",
        "REVIEW PETITION (CRIMINAL)",
        "TRANSFERRED CASE (CIVIL)",
        "TRANSFERRED CASE (CRIMINAL)",
        "SPECIAL LEAVE TO PETITION (CIVIL)",
        "SPECIAL LEAVE TO PETITION (CRIMINAL)",
        "WRIT TO PETITION (CIVIL)",
        "WRIT TO PETITION (CRIMINAL)",
        "ORIGINAL SUIT",
        "DEATH REFERENCE CASE",
        "CONTEMPT PETITION(CIVIL)",
        "CONTEMPT PETITION (CRIMINAL)",
        "TAX REFERENCE CASE",
        "SPECIAL REFERENCE CASE",
        "ELECTION PETITION (CIVIL)",
        "ARBITRATION PETITION",
        "CURATIVE PETITION (CIVIL)",
        "CURATIVE PETITION (CRL)",
        "REF. U/A 317(1)",
        "MOTION (CRL)",
        "DIARYNO AND DIARYYR",
        "SUO MOTO WRIT PETITION (CIVIL)",
        "SUO MOTO WRIT PETITION (CRIMINAL)",
        "SUO MOTO CONTEMPT PETITION (CIVIL)",
        "SUO MOTO CONTEMPT PETITION (CRIMINAL)",
        "REF. U/S 14 RTI",
        "REF. U/S 17 RTI",
        "MISCELLANEOUS APPLICATION",
        "SUO MOTO TRANSFER PETITION (CIVIL)",
        "SUO MOTO TRANSFER PETITION (CRIMINAL)",
        "Unknown"
    ];


    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2000 + 1 }, (v, k) => 2000 + k);

    useEffect(() => {
        fetchCases();
    }, []);

    const fetchCases = async () => {
        try {
            const response = await axiosInstance.get('sccourt/cases/', {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                }
            });
            setCases(response.data);
        } catch (error) {
            console.error('Failed to fetch cases:', error);
        } finally {
            setIsLoadingCases(false);
        }
    }

    const confirmDeleteCase = (caseId) => {
        confirmAlert({
            title: 'Confirm To Delete',
            message: 'Are you sure you want to delete this case?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteCase(caseId)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const DeleteCase = async (caseId) => {
        try {
            const response = await axiosInstance.delete(`sccourt/cases/${caseId}/`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },

            });
            fetchCases();
            toast("Case Deleted Successfully");
        } catch (err) {
            console.log(err);
        }
    }

    const handleCaseDetails = async (case_number) => {
        try {
            const response = await axiosInstance.get(`sccourt/supremecourt/${case_number}/`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    "Content-Type": 'application/json'
                }
            });
            console.log(response.data);
            setSelecteSupremeCourtCase(response.data);
        } catch (err) {
            console.log(err.message);
        }
    }


    const handlecase = async (e) => {
        e.preventDefault();

        if (!year) {
            toast.error('Please fill in all fields');
            return;
        }
        const data = {
            case_type: caseType,
            caseno: caseNumber,
            year: year,
            dairy_number: dairyNumber,
            case_nickname: name
        };

        const filteredData = {};
        Object.keys(data).forEach(key => {
            if (data[key]) {
                filteredData[key] = data[key];
            }
        });

        setLoading(true);

        try {
            const response = await axiosInstance.post('sccourt/cases/', filteredData, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                }
            });
            setCaseType('');
            setCaseNumber('');
            setYear('');
            setName('');
            setDairyNumber('');
            fetchCases();
            toast.success('Case added successfully. We are processing your case.');
        } catch (err) {
            if (err.response && err.response.data && err.response.data.detail) {
                toast.error(`Failed to submit form: ${err.response.data.detail}`);
            } else {
                toast.error('Failed to submit form');
            }
        } finally {
            setLoading(false);
        }
    };


    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };


    if (isLoadingCases) {
        return (
            <div className="loading">
                <Navbar />
                <div style={{ height: "70vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <ClipLoader color="#ffffff" size={150} css={override} />
                    <p style={{ color: "#ffffff", marginTop: 20, fontSize: 20, textAlign: "center" }}>
                        Loading case details...<br />Please wait as we retrieve the latest information.
                    </p>
                </div>
            </div>
        );
    }

    if (selecteSupremeCourtCase) {
        return <SupremeCoutDetail />;
    }

    if (cases.length > 0) {
        return (
            <>
                <Navbar />
                <ToastContainer />
                <div className="main-case-priviledge">
                    <div className="cases-privileged-container">
                        <div className="add-case-section">
                            <img src={backbutton} alt="" className='back-button' onClick={() => { window.location.href = '/mycases'; }} />
                            <h2> <span style={{ color: '#5D54F1' }}>Add</span> Case</h2>
                            <div className="supreme-court-buttons-case-list">
                                <button onClick={() => handleOptionClick('Diary Number')}>
                                    <img src={diary_number} alt="" />
                                    Diary Number</button>
                                <span>or</span>
                                <button onClick={() => handleOptionClick('Case Number')}>
                                    <img src={case_type} alt="" />
                                    Case Number</button>
                            </div>
                            {selectedOption === 'Diary Number' && (
                                <>
                                    <div className="form-group">
                                        <input
                                            id="caseType"
                                            name="Diary Number"
                                            placeholder='Diary Number'
                                            value={dairyNumber}
                                            onChange={(e) => setDairyNumber(e.target.value)}>
                                        </input>
                                    </div>

                                    <div className="form-group">
                                        <select
                                            id="year"
                                            name="year"
                                            value={year}
                                            onChange={(e) => setYear(e.target.value)}
                                        >
                                            <option value="">Select Year</option>
                                            {years.map((year, index) => (
                                                <option key={index} value={year}>{year}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            id="year"
                                            name="year"
                                            placeholder='Case Name'
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </>
                            )}
                            {selectedOption === 'Case Number' && (
                                <>
                                    <div className="form-group">
                                        <select
                                            id="caseType"
                                            name="caseType"
                                            value={caseType}
                                            onChange={(e) => setCaseType(e.target.value)}
                                        >
                                            <option value="">Select Case Type</option>
                                            {caseTypes.map((caseType, index) => (
                                                <option key={index} value={caseType}>{caseType}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="case-name"
                                            placeholder="Case Number"
                                            name="Case Number"
                                            value={caseNumber}
                                            onChange={(e) => setCaseNumber(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <select
                                            id="year"
                                            name="year"
                                            value={year}
                                            onChange={(e) => setYear(e.target.value)}
                                        >
                                            <option value="">Year</option>
                                            {years.map((year, index) => (
                                                <option key={index} value={year}>{year}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            id="year"
                                            name="year"
                                            placeholder='Case Name'
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </>
                            )}
                            <button className="submit-btn" onClick={handlecase}>
                                {loading ? <div className="loader"></div> : 'Submit'}
                            </button>
                            <p className="info-text mt-5">
                                <span className="icon"><img style={{ height: '20px', width: '20px' }} src={infoimage} alt="" /></span> You can access a detailed version of your case, complete with summary and timeline, by clicking on the tab labeled with the Case number.
                            </p>
                            <img src={bottomimage} alt="Book" className=".case-bottom-right-image" />
                        </div>
                        <div className="my-cases-section">
                            <div className='header-container'>
                                <h2><span style={{ color: '#5D54F1' }}>My</span> Cases</h2>
                                <div class="search-container">
                                    <input type="search" placeholder="Search Case" className="search-bar" />
                                    <span class="search-icon">
                                        <img src={search} alt="Search Icon" class="icon-img" />
                                    </span>
                                </div>
                            </div>
                            <div className="case-list">
                                {cases.map((caseItem) => (
                                    <div className="cases-item" style={{ display: 'flex' }}>
                                        <div key={caseItem.id} className='case-items' onClick={() => handleCaseDetails(caseItem.supremecourtcase)} style={{ display: 'flex', gap: '10px', width: '100%' }}>
                                            <div className='case-item'>
                                                {caseItem.case_type && (
                                                    <>
                                                        <span>CASE TYPE: {caseItem.case_type}</span>
                                                        {/* <span>CASE NO: {caseItem.caseno}</span> */}
                                                        <span>CASE NAME: {caseItem.case_nickname}</span>
                                                    </>
                                                )}
                                                {caseItem.dairy_number && (
                                                    <>
                                                        <span>DIARY NUMBER: {caseItem.dairy_number}</span>
                                                        <span>CASE NAME: {caseItem.case_nickname}</span>
                                                        {/* <span>CASE NO: {caseItem.caseno}</span> */}
                                                    </>
                                                )}
                                                {/* <span>CASE NAME: {caseItem.case_nickname}</span> */}
                                            </div>
                                        </div>
                                        <div className="case-actions">
                                            <button className="action-btn" aria-label="View Case">
                                                <img src={unselected} alt="View" className='case-img' />
                                            </button>
                                            <button className="action-btn" aria-label="Delete Case" onClick={(e) => { e.stopPropagation(); confirmDeleteCase(caseItem.id); }}>
                                                <img src={unselecteddelete} alt="Delete" className='case-img' />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* mobile add case section */}
                            <div className="mobilePrivildgeContainer">
                                <img src={backimg} alt="" className="back-image" />
                                <div className="addcaseBut">
                                    <button onClick={() => setShowAddCase(true)}>Add Case</button>
                                </div>
                            </div>
                            <div className={`mobile-top-privilegde ${showAddCase ? 'show' : ''}`}>
                                <div className='mobile-privileged-main-side '>
                                    <button className="close-button" onClick={() => setShowAddCase(false)}>
                                        <FaTimes />
                                    </button>
                                    <h1> <span style={{ color: '#5D54F1' }}>Add </span>Case</h1>
                                    <div className="supreme-court-buttons-case-list-mobile">
                                        <button onClick={() => handleOptionClick('Diary Number')}>
                                            <img src={diary_number} alt="" />
                                            Diary Number</button>
                                        <span>or</span>
                                        <button onClick={() => handleOptionClick('Case Number')}>
                                            <img src={case_type} alt="" />
                                            Case Number</button>
                                    </div>
                                    <div className="high-court-mobile-privileged-centered-box">
                                        <div class="mobile-privileged-container">
                                            <div className="high-court-form-container">
                                                <form onSubmit={handlecase}>
                                                    {selectedOption === 'Diary Number' && (
                                                        <>
                                                            <div className="form-group">
                                                                <label htmlFor="">Diary Number</label>
                                                                <input
                                                                    id="caseType"
                                                                    name="Diary Number"
                                                                    placeholder='Diary Number'
                                                                    value={dairyNumber}
                                                                    onChange={(e) => setDairyNumber(e.target.value)}>
                                                                </input>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="">Year</label>
                                                                <select
                                                                    id="year"
                                                                    name="year"
                                                                    value={year}
                                                                    onChange={(e) => setYear(e.target.value)}
                                                                >
                                                                    <option value="">Select Year</option>
                                                                    {years.map((year, index) => (
                                                                        <option key={index} value={year}>{year}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                            <label htmlFor="">Case Name</label>
                                                                <input
                                                                    id="year"
                                                                    name="year"
                                                                    placeholder='Case Name'
                                                                    value={name}
                                                                    onChange={(e) => setName(e.target.value)}
                                                                >
                                                                </input>
                                                            </div>
                                                        </>
                                                    )}
                                                    {selectedOption === 'Case Number' && (
                                                        <>
                                                            <div className="form-group">
                                                                <label htmlFor="">Case Type</label>
                                                                <select
                                                                    id="caseType"
                                                                    name="caseType"
                                                                    value={caseType}
                                                                    onChange={(e) => setCaseType(e.target.value)}
                                                                >
                                                                    <option value="">Select Case Type</option>
                                                                    {caseTypes.map((caseType, index) => (
                                                                        <option key={index} value={caseType}>{caseType}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="">Case Number</label>
                                                                <input
                                                                    type="text"
                                                                    id="case-name"
                                                                    placeholder="Case Number"
                                                                    name="Case Number"
                                                                    value={caseNumber}
                                                                    onChange={(e) => setCaseNumber(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="">Year</label>
                                                                <select
                                                                    id="year"
                                                                    name="year"
                                                                    value={year}
                                                                    onChange={(e) => setYear(e.target.value)}
                                                                >
                                                                    <option value="">Year</option>
                                                                    {years.map((year, index) => (
                                                                        <option key={index} value={year}>{year}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="">Case Name</label>

                                                                <input
                                                                    id="year"
                                                                    name="year"
                                                                    placeholder='Case Name'
                                                                    value={name}
                                                                    onChange={(e) => setName(e.target.value)}
                                                                >
                                                                </input>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="form-group laptop-submit">
                                                        <button type="submit" disabled={loading} onClick={handlecase}>
                                                            {loading ? <div className="loader"></div> : 'Submit'}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className='mobile-footer-content'>
                                                <div className="mobile-footer-image">
                                                    <button className="form-group mobile-submit" onClick={handlecase}>
                                                        {loading ? <div className="loader"></div> : 'Submit'}
                                                    </button>
                                                    <img src={mobilePriviledge} alt="" className='mobileFooterImage' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <Navbar />
                <ToastContainer />
                <div className="supreme-court-container">
                    <div className="supreme-court-container-content">
                        <div className="img-text">
                            <h1>
                                <img src={backbutton} alt="Back" style={{ cursor: 'pointer', position: 'absolute', left:'2%' }} onClick={() => { window.location.href = '/mycases'; }} 
                                    />
                                <span style={{ color: '#5D54F1' }}>Enter the following to</span> know your case status
                            </h1>
                        </div>
                        <p>Add your case using the following options</p>
                        <div className="supreme-court-buttons">
                            <button onClick={() => handleOptionClick('Diary Number')}>
                                <img src={diary_number} alt="" />
                                Diary Number</button>
                            <span>or</span>
                            <button onClick={() => handleOptionClick('Case Number')}>
                                <img src={case_type} alt="" />
                                Case Number</button>
                        </div>
                        <div className="form-container">
                            <form onSubmit={handlecase}>
                                {selectedOption === 'Diary Number' && (
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="caseType">Diary Number</label>
                                            <input
                                                id="caseType"
                                                name="caseType"
                                                placeholder='Diary Number'
                                                value={dairyNumber}
                                                onChange={(e) => setDairyNumber(e.target.value)}>
                                            </input>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="year">Year</label>
                                            <select
                                                id="year"
                                                name="year"
                                                value={year}
                                                onChange={(e) => setYear(e.target.value)}
                                            >
                                                <option value="">Select Year</option>
                                                {years.map((year, index) => (
                                                    <option key={index} value={year}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="year">Case Name</label>
                                            <input
                                                id="year"
                                                name="year"
                                                placeholder='Case Name'
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            >
                                            </input>
                                        </div>
                                    </>
                                )}
                                {selectedOption === 'Case Number' && (
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="year">Case Type</label>
                                            <select
                                                id="caseType"
                                                name="caseType"
                                                value={caseType}
                                                onChange={(e) => setCaseType(e.target.value)}
                                            >
                                                <option value="">Select Case Type</option>
                                                {caseTypes.map((caseType, index) => (
                                                    <option key={index} value={caseType}>{caseType}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="caseType">Case Number</label>
                                            <input
                                                id="caseType"
                                                name="caseType"
                                                placeholder='Case Number'
                                                value={caseNumber}
                                                onChange={(e) => setCaseNumber(e.target.value)}>
                                            </input>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="year">Year</label>
                                            <select
                                                id="year"
                                                name="year"
                                                value={year}
                                                onChange={(e) => setYear(e.target.value)}
                                            >
                                                <option value="">Select Year</option>
                                                {years.map((year, index) => (
                                                    <option key={index} value={year}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                )}
                                <div className="submit-laptop">
                                    <div className="laptop-submit" onClick={handlecase}>
                                        <button type="submit" disabled={loading}>
                                            {loading ? <div className="loader"></div> : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <button className="form-group mobile-submit" onClick={handlecase}>
                            {loading ? <div className="loader"></div> : 'Submit'}
                        </button>
                    </div>
                    <div className="supreme_court_bottom_illustration">
                        <img src={supreme_court_illustration} alt="New top image" />
                    </div>
                </div>
                <div className="supreme_court_bottom_right_illustration">
                    <img src={supreme_court_illustration2} alt="" />
                </div>
            </>
        );
    }
}

export default SupremeCourtPrivilede;