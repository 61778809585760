import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import DOMPurify from 'dompurify';
import backbutton from '../assets/images/mycases/back-button.svg';
import info from '../assets/images/mycases/info-image.svg';
import highTop from '../assets/images/mycases/high-court-top.png';
import { useCaseContext } from '../CaseContext';
import '../assets/css/highcourtcasedetails.css';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import chaticon from '../assets/images/mycases/caseDetailsChatButtonIcon.png'
import caseDetailswaiting from '../assets/images/comingSoon/CaseWaitingPage.svg'
import { SpinningCircles } from 'react-loading-icons'


const PanjabHarayanaCasedetail = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { selectPanjabCourtCase, setSelectPanjabCourtCase, goBackPanjabCourt, setPropmtsVisibel, propmtsVisibel } = useCaseContext();
    const [caseDetailsFetched, setCaseDetailsFetched] = useState(false);
    const [details, setDetails] = useState(selectPanjabCourtCase);
    const [caseStatus, setCaseStatus] = useState(selectPanjabCourtCase.status);
    const [soonDetails, setSoonDetails] = useState(selectPanjabCourtCase);

    const createMarkup = (htmlContent) => {
        return { __html: DOMPurify.sanitize(htmlContent) };
    };

    const access_token = sessionStorage.getItem('access_token');
    const navigate = useNavigate();

    const fetchCaseDetails = async () => {
        try {
            const response = await axiosInstance.get(`highcourt/punjabhcbank/${selectPanjabCourtCase.id}/`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                }
            });

            setCaseStatus(response.data.status);
            if (response.data.status === "COMPLETED_MERGING") {
                setSoonDetails(response.data);
                // toast.success('');
            }

            if (response.data.status === "COMPLETED") {
                setDetails(response.data);
                setCaseDetailsFetched(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!caseDetailsFetched) {
                fetchCaseDetails();
            }
        }, 2000);

        return () => clearInterval(intervalId);
    }, [caseDetailsFetched]);


    const casename = `${selectPanjabCourtCase.petitioner_vs_respondent}`;
    console.log(casename);

    const createChatRoom = async (cnr_number, is_true) => {
        try {
            const response = await axiosInstance.get('chatapp/chat-room/', {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                }
            });
            // const existingChatRooms = response.data;
            // console.log(response.data);

            // const cnrString = cnr_number.toString();

            // const casename = `${selectedHighCourtCase.petitioner_vs_respondent}`;
            // const chatRoomExists = existingChatRooms.some(chatRoom => {
            //     console.log(`Comparing ${chatRoom.chat_name} with ${cnrString} and ${chatRoom.chat_type} with "query"`);
            //     return chatRoom.chat_name === casename && chatRoom.chat_type === "query";
            // });

            // if (chatRoomExists) {
            //     navigate('/talkaboutcase');
            // } else {
            const result = await axiosInstance.post('chatapp/chat-room/', {
                punjabhighcourt_id: cnr_number,
                is_query: is_true
            }, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                }
            });
            console.log("Chat room created:", response.data);
            setPropmtsVisibel(true);
            console.log(propmtsVisibel);
            // setShowPromptsInChat(true);
            navigate('/talkaboutcase');
            // }
        } catch (err) {
            console.log(err);
        }
    };

    const formatText = (text) => {
        if (!text) return null;

        const lines = text.split('\n');

        return lines.map((line, index) => {
            if (!line.trim()) return null;

            // Handle headings
            const headingMatch = line.match(/^(#+)\s+(.*)/);
            if (headingMatch) {
                const level = headingMatch[1].length;
                const content = headingMatch[2].replace(/\*\*(.*?)\*\*/g, '$1');
                return <strong key={index}>{content}</strong>;
            }

            const numberedMatch = line.match(/^(\d+\.\s+)\*\*(.*?)\*\*$/);
            if (numberedMatch) {
                const numberPart = numberedMatch[1]; // e.g., "1. "
                const content = numberedMatch[2].trim(); // Get the text after ** and remove it
                return (
                    <div key={index}>
                        {numberPart}<strong>{content}</strong>
                    </div>
                );
            }

            // Handle list items with bold text
            if (line.startsWith('-**')) {
                const content = line.substring(3, line.length - 2).trim();
                return (
                    <ul>
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }
            if (line.startsWith('-**')) {
                const content = line.substring(8).trim();
                return (
                    <ul>
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }
            if (line.startsWith('   - **')) {
                const content = line.substring(7).trim().replace(/\*\*/g, ''); // Remove leading spaces and **
                return (
                    <ul >
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }
            if (line.startsWith('  - ')) {
                const content = line.substring(4).trim().replace(/\*\*/g, ''); // Remove leading spaces and **
                return (
                    <ul style={{ marginLeft: '10px' }}>
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }
            if (line.startsWith('   - ')) {
                const content = line.substring(4).trim().replace(/\*\*/g, ''); // Remove leading spaces and **
                return (
                    <ul style={{ marginLeft: '10px' }}>
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }

            if (line.startsWith('     - ')) {
                const content = line.substring(6).trim().replace(/\*\*/g, ''); // Remove leading spaces and **
                return (
                    <ul style={{ marginLeft: '10px' }}>
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }


            // Handle regular list items
            if (line.startsWith('-')) {
                const cleanLine = line.substring(1).trim();
                const contentWithoutBold = cleanLine.replace(/\*\*(.*?)\*\*/g, '$1');
                return <p style={{ marginLeft: '10px' }}><li key={index}>{contentWithoutBold}</li></p>;
            }

            if (line.startsWith('**') && line.endsWith('**')) {
                const cleanLine = line.substring(2, line.length - 2).trim(); // Remove the first and last two asterisks
                return <strong><p>{cleanLine}</p></strong>;
            }

            // Handle bold text within a paragraph
            const parts = line.replace(/\*\*$/, '').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').split(/(<strong>.*?<\/strong>)/).map((part, idx) => {
                if (part.startsWith('<strong>') && part.endsWith('</strong>')) {
                    const cleanText = part.substring(8, part.length - 9);
                    return <strong key={idx}>{cleanText}</strong>;
                } else {
                    return <span key={idx}>{part}</span>;
                }
            });

            return <p key={index} style={{ textAlign: 'justify' }}>{parts}</p>;
        }).filter(component => component !== null);
    };



    if (caseStatus === "RETRIEVING") {
        return (
            <>
                <Navbar />
                <div className="casedetails-app-container">
                    <div className="casedetails-content-container">
                        {/* </div> */}
                        <div className="casedetails-lower-section1">
                            <img src="https://caseminister.blob.core.windows.net/frontend/CaseWaitingPage.svg" alt="" />
                            <div className="casedetails-lower-section">
                                <h3>Your Case Is Now in Motion!</h3>
                                <p>Thank you for letting us assist you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="casedetails-right-section">
                        <div className="footer-right">
                            <h6>What’s next?</h6>
                            <ul>
                                <li>1.⁠ ⁠🕵️‍♂️ We're extracting the intricate details of your case.</li>
                                <li>2.⁠ ⁠🧠 These details are then sent to our state-of-the-art Multimodal AI for thorough analysis.</li>
                                <li>⁠3.🔔 You'll receive a prompt notification and an email update very soon.</li>
                            </ul>
                            <h6>While you wait,</h6>
                            <p>Why not explore our innovative AI Chatbot? It's designed to assist you with a wealth of legal knowledge and is trained on millions of cases and laws. Click here to dive in!</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Navbar />
            <div className="high-court-case-detail-container">
                <img src={highTop} alt=""
                    style={{
                        position: 'absolute',
                        width: '100%',
                        top: '13%',
                        zIndex: '-1',
                    }}
                />
                <div className="high-court-case-detail-container-contain">
                    <div className="case-detail-img-text text-center">
                        <img src={backbutton} alt="Back" onClick={goBackPanjabCourt} />
                        <div className="highcourt-case-main-head">
                            <h1> <span style={{ color: '#5D54F1' }}>View status</span> of case here</h1>
                        </div>
                    </div>
                    {details.complete_summary ? (
                        <>
                            <div className="chatButton">
                                <button className='chatbot-button' onClick={() => createChatRoom(selectPanjabCourtCase.id, "true")}>LET’S TALK ABOUT YOUR CASE <img src={chaticon} alt="" /></button>
                                <p>Try our legal chatbot in your preferred language to uncover valuable insights and hidden answers in your case</p>

                            </div>

                        </>
                    ) : (
                        <>
                            <div className="Ai-model">
                                <p>Our AI model is extracting your case details. Please wait...</p>
                                <SpinningCircles />
                            </div>
                        </>
                    )}
                    <div className="case-detail-containers">
                        <div className="case-detail-container">
                            <div className="court-section">
                                <p className='Propertyname'>Case type</p>
                            </div>
                            <div className="court-section">
                                <p>{soonDetails.case_type}</p>
                            </div>
                        </div>
                        <div className="case-detail-container">
                            <div className="court-section">
                                <p className='Propertyname'>Case Information</p>
                            </div>
                            <div className="court-section">
                                <p>{soonDetails.case_info}</p>
                            </div>
                        </div>
                        <div className="case-detail-container">
                            <div className="court-section">
                                <p className='Propertyname'>Petitioner Vs. Respondent</p>
                            </div>
                            <div className="court-section">
                                <p className='panjab-case-info'>{soonDetails.petitioner_vs_respondent}</p>
                            </div>
                        </div>
                    </div>
                    <div className="discuss-and-button">
                        <div className="high-court-info-text">
                            <img src={info} alt="" />
                            <p>To discuss about this case and get more information, click on our Chatbot button</p>
                        </div>
                        <div className="court-buttons">
                            <button
                                className='gradient-button'
                                onClick={() => window.open(soonDetails.merged_pdf, '_blank')}>
                                DOWNLOAD ORDERS
                            </button>
                            <button
                                className='gradient-button'
                                onClick={() => window.open(soonDetails.latest_order_pdf_link, '_blank')}
                            >
                                DOWNLOAD LATEST ORDERS
                            </button>
                        </div>
                    </div>
                    {details.complete_summary ? (
                        <div className="order-summary">
                            <div className="order-summary-header" onClick={() => setIsOpen(!isOpen)}>
                                <h2>Order Summary</h2>
                            </div>
                            <div className="order-summary-content">
                                <p>{formatText(details.complete_summary)}</p>
                            </div>
                        </div>
                    ) : (
                        <div className="Ai-model">
                            <p>Our AI model is extracting your case details. Please wait...</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default PanjabHarayanaCasedetail;
