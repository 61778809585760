import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import '../assets/css/footer.css';
import logo from '../assets/images/navbar/legal-tech-logo.svg'
const Footer = ({ scrollToOurservices }) => {
    return (
        <div class="footer">
            <div class="">
                <div class="row">
                    <div class="col-sm-3 column">
                        <div class="logo">
                            <img src={logo} alt="Logo" />
                        </div>
                        <p style={{marginTop:'10px'}}>&copy; 2023 Advanced Technology and Research Innovation Group. All rights reserved.</p>
                    </div>
                    {/* <div class="col-sm-3 column">
                        <h5>CASE MINISTER </h5>
                        <ul class="list-unstyled">
                            
                        <li className="mt-3">Advanced Technology and Research Innovation Group</li>
                        </ul>
                        
                    </div> */}
                    <div class="col-md-3 column">
                        <h5>CASE MINISTER</h5>
                        <ul class="list-unstyled">
                            <p>Advanced Technology and Research <br /> Innovation Group</p>
                            <div className="next-grp">
                                <p>Suite 01, 64 Nile Street <br />London  <br /> N1 7SR</p>
                            </div>
                        </ul>
                    </div>
                    <div class="col-md-3 column">
                        <h5>COMPANY</h5>
                        <ul class="list-unstyled">
                            <li><a href="/">Home</a></li>
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/terms-and-conditions">Terms</a></li>
                            <li><a href="/Contactus">Contact Us</a></li>
                            <li><a href="/faq">FAQs</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-3 column">
                        <h5>HELP</h5>
                        <ul class="list-unstyled">
                            <li>
                                <a href="#" onClick={scrollToOurservices}>Services</a>
                            </li>
                            <li><a href="#">Help & Support</a></li>
                            <li><a href="#">Signup</a></li>
                            {/* <li><a href="#">Message</a></li> */}
                        </ul>
                    </div>
                </div>
                {/* <div class="row">
                    <div class="col-12 copy">
                        <p>Advanced Technology and Research Innovation Group Suite 01, 64 Nile StreetLondon N1 7SR</p>
                    </div>
                </div> */}
            </div>
        </div> 
    );  
}; 
export default Footer;