import React, { useEffect, useRef, useState, useCallback } from 'react';
import '../assets/css/Chat.css';
import usericon from '../assets/images/chat/user-icon.svg';
import { useChat } from '../ChatContext';
import axiosInstance from '../utils/axiosInstance';
import importfile from '../assets/images/Chatbot/Chat_plus.svg';
import sendmesg from '../assets/images/Chatbot/Send_file.svg';
import mobileNewChat from '../assets/images/chat/mobileNewChat.svg';
import { useCaseContext } from '../CaseContext';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-modal';
import DragAndDrop from '../assets/images/chat/dragAndDrop.svg'
import { toast } from 'react-toastify';
import microphoneImage from '../assets/images/chat/icons8-microphone-30.png'
Modal.setAppElement('#root'); // Add this line to set the app element


const Chat = ({ userProfileImage, chatRoom, newChatStarted, }) => {
    const { handleNewChat } = useChat();
    const { messages, addMessage, setMessages, setShowPrompts, showPrompts, fetchChatHistory, chatRoomCreated, startNewChat, setConversationStarted, conversationStarted } = useChat();
    const { propmtsVisibel, setPropmtsVisibel } = useCaseContext();
    const msgEnd = useRef(null);
    const [input, setInput] = useState("");
    const [isPromptClick, setIsPromptClick] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const { chatRoom1, setChatRoom1, setSelectedChat } = useChat();
    const { selectedChat, isChatOpen, setIsChatOpen } = useChat();
    const [userHasInteracted, setUserHasInteracted] = useState(false);
    const { toggleSidebar, isOpen } = useCaseContext();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('');
    const [isThinking, setIsThinking] = useState(false);
    const [isNewChatRoom, setIsNewChatRoom] = useState(false);
    const [userScrolledUp, setUserScrolledUp] = useState(false);
    const [isListening, setIsListening] = useState(false);

    const chatMessages = messages[selectedChat] || [];

    console.log(selectedChat);
    console.log(chatMessages);

    const openModal = () => {
        setModalIsOpen(true);
        console.log("click")
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedFile(null);
    };

    const onDrop = useCallback((acceptedFiles) => {
        setSelectedFile(acceptedFiles[0]);
        setConversationStarted(true);
        setShowPrompts(false);
        // closeModal(); // Close the modal when a file is dropped
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    useEffect(() => {
        if (chatRoomCreated) { }
    }, [chatRoomCreated]);


    const access_token = sessionStorage.getItem('access_token');


    useEffect(() => {
        if (!userScrolledUp && msgEnd.current) {
            msgEnd.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [messages]);

    useEffect(() => {
        if (isChatOpen) {
            setShowPrompts(true);
        } else {
            setShowPrompts(false);
            setPropmtsVisibel(false)
        }
    }, [isChatOpen]);


    useEffect(() => {
        const sendOnPromptClick = async () => {
            if (isPromptClick) {
                await handleSend();
                setIsPromptClick(false);
                setConversationStarted(true);
                setShowPrompts(false);
            }
        };

        sendOnPromptClick();
    }, [isPromptClick, setConversationStarted]);

    useEffect(() => {
        const fetchChatrooms = async () => {
            try {
                const response = await axiosInstance.get('chatapp/chat-room/', {
                    headers: {
                        "Authorization": `Bearer ${access_token}`
                    }
                });
                const chatrooms = response.data;
                if (chatrooms.length === 0) {
                    setUserHasInteracted(true);
                    setShowPrompts(true);
                }
            } catch (error) {
                console.error('Failed to fetch chatrooms:', error);
            }
        };
        fetchChatrooms();
    }, [access_token]);

    const startnewchat = () => {
        handleNewChat(true);
    };

    const createNewChatRoom = async () => {
        try {
            const response = await axiosInstance.post('chatapp/chat-room/', {}, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 201) {
                const newChatRoomId = response.data.id;
                setChatRoom1(newChatRoomId);
                setIsChatOpen(false);
                setUserHasInteracted(false);

                // setSelectedChat(newChatRoomId);
                return newChatRoomId;
            }
        } catch (error) {
            console.error("Error creating new chat room:", error);
            return null;
        }
    };


    const pollForNameAssignment = async (chatRoomId) => {
        const interval = setInterval(async () => {
            try {
                const response = await axiosInstance.get(`chatapp/chat-room/${chatRoomId}/`, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.data && response.data.chat_name) {
                    clearInterval(interval);
                    await fetchChatHistory();
                }
            } catch (error) {
                clearInterval(interval);
                console.error("Error checking name assignment:", error);
            }
        }, 2000);
    };

    useEffect(() => {
        startNewChat();
        const fetchChatHistory = async () => {
            try {
                const response = await axiosInstance.get('chatapp/chat-details/', {
                    params: {
                        chatroom_id: selectedChat,
                    },
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.data && response.data.length === 0) {
                    // If chat history is empty, show the prompts
                    setShowPrompts(true);
                    setPropmtsVisibel(true);
                } else {
                    // If chat history is not empty, hide the prompts
                    setShowPrompts(false);
                    setPropmtsVisibel(false);

                    response.data.forEach(msg => {
                        addMessage(selectedChat, 'user', msg.question);
                        addMessage(selectedChat, 'assistant', msg.answer);
                    });
                }
            } catch (error) {
                console.error('Error fetching chat history:', error);
            }
        };
        fetchChatHistory();
    }, [selectedChat, access_token]);

    const handleSend = async () => {
        // if (!selectedFile) {
        //     toast.error('Please Select Proper File or Message ');
        //     return;
        // }
        let messageContent = input.trim();
        if (!messageContent && !selectedFile) {
            toast.error('Please enter a message or select a file to send.');
            setLoading(false);
            return;
        }
        setLoading(true);
        if (messageContent || selectedFile) {
            if (isChatOpen || userHasInteracted) {
                const createdChatRoomId = await createNewChatRoom();
                if (createdChatRoomId) {
                    setChatRoom1(createdChatRoomId);
                    setUserHasInteracted(false);
                    await sendMessage(messageContent, createdChatRoomId);
                    // setIsChatOpen(false);    
                }
            } else {
                await sendMessage(messageContent, selectedChat || chatRoom1);
            }
            setInput("");
            setSelectedFile(null);
            setConversationStarted(false)
            setLoading(false);
        }

    };
    const sendMessage = async (messageContent, chatRoomId) => {
        setConversationStarted(true);
        // setShowPrompts(false);

        if (selectedFile) {
            const formData = new FormData();
            formData.append('document', selectedFile);
            formData.append('chatroom_id', chatRoomId);
            setIsUploading(true);
            try {
                const fileApiResponse = await axiosInstance.post('chatapp/upload/', formData, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percentCompleted = Math.floor((loaded * 100) / total);
                        console.log(`Upload Progress: ${percentCompleted}%`); // Logging for debugging
                        setUploadProgress(percentCompleted);
                    }
                });

                if (fileApiResponse.status === 201) {
                    if (messageContent) {
                        await handleTextMessage(messageContent, chatRoomId);
                    }
                    toast.success('File uploaded successfully! You can ask questions about it.');
                    setUploadStatus('Uploaded');
                } else {
                    console.error(`HTTP error! Status: ${fileApiResponse.status}`);
                    setUploadStatus('Failed');
                    toast.error('Failed to upload file. Please try again later.');
                }
            } catch (error) {
                console.error('Error sending message with file:', error);

                if (error.response && error.response.data && error.response.data.document) {
                    toast.error(error.response.data.document[0]);
                } else {
                    toast.error('Error uploading file: ' + error.message);
                }

                setUploadStatus('Failed');
            } finally {
                setIsUploading(false);
                setUploadProgress(0);
                closeModal();
            }
        } else if (messageContent) {
            await handleTextMessage(messageContent, chatRoomId);
        }
        setInput("");
        setSelectedFile(null);
        setConversationStarted(false)
    };


    const handleTextMessage = async (messageContent, chatRoomId) => {
        const messageIdx = (messages[chatRoomId] || []).length;
        addMessage(selectedChat, 'user', messageContent);
        addMessage(selectedChat, 'assistant', 'Thinking...');

        try {
            const apiResponse = await axiosInstance.post('chatapp/chat-details/', {
                question: messageContent,
                chatroom_id: chatRoomId,
            }, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (apiResponse.status === 201) {
                const questionId = apiResponse.data.id;
                pollForAnswer(questionId, chatRoomId, messageIdx);
            } else {
                console.error(`HTTP error! Status: ${apiResponse.status}`);
                setMessages(prevMessages => ({
                    ...prevMessages,
                    [selectedChat]: prevMessages[selectedChat].map((msg, idx) =>
                        idx === messageIdx + 1 ? { ...msg, content: 'Error loading response', loading: false } : msg
                    )
                }));
            }
        } catch (error) {
            console.error('Error fetching response from API:', error);
            setMessages(prevMessages => ({
                ...prevMessages,
                [selectedChat]: prevMessages[selectedChat].map((msg, idx) =>
                    idx === messageIdx + 1 ? { ...msg, content: 'Error loading response', loading: false } : msg
                )
            }));
        }
    };

    const pollForAnswer = async (questionId, chatRoomId, messageIdx) => {
        // setIsThinking(true); 
        const interval = setInterval(async () => {
            try {
                const response = await axiosInstance.get(`chatapp/chat-details/${questionId}/`, {
                    params: { chatroom_id: chatRoomId },
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.data && response.data.answer) {
                    clearInterval(interval);
                    setIsThinking(false);

                    // Initialize the message with an empty string to be filled word by word
                    setMessages(prevMessages => ({
                        ...prevMessages,
                        [selectedChat]: prevMessages[selectedChat].map((msg, idx) =>
                            idx === messageIdx + 1 ? { ...msg, content: '', loading: true } : msg
                        )
                    }));
                    console.log(chatRoomId);
                    displayAnswerWordByWord(selectedChat, chatRoomId, response.data.answer, messageIdx);

                } else {
                    const partialResponse = response.data.partial_answer || '';
                    setMessages(prevMessages => ({
                        ...prevMessages,
                        [selectedChat]: prevMessages[selectedChat].map((msg, idx) =>
                            idx === messageIdx + 1 ? { ...msg, content: `${msg.content} ${partialResponse}`, loading: true } : msg
                        )
                    }));
                }
            } catch (error) {
                clearInterval(interval);
                console.error('Error polling for answer:', error);
                // setIsThinking(false);
                setMessages(prevMessages => ({
                    ...prevMessages,
                    [selectedChat]: prevMessages[selectedChat].map((msg, idx) =>
                        idx === messageIdx + 1 ? { ...msg, content: 'Error loading response', loading: false } : msg
                    )
                }));
            }
        }, 1000);
    };
    const displayAnswerWordByWord = (selectedChat, chatRoomId, answer, messageIdx) => {
        console.log('Starting displayAnswerWordByWord with selectedChat:', selectedChat);
        const words = answer.split(' ');
        let index = 0;
        let displayedContent = '';

        const displayNextWord = () => {
            if (index < words.length) {
                displayedContent += (index > 0 ? ' ' : '') + words[index];
                setMessages((prevMessages) => {
                    const chatMessages = prevMessages[selectedChat] || [];
                    return {
                        ...prevMessages,
                        [selectedChat]: chatMessages.map((msg, idx) =>
                            idx === messageIdx + 1
                                ? { ...msg, content: displayedContent, loading: false }
                                : msg
                        )
                    };
                });
                index++;
                setTimeout(displayNextWord, 100);
            } else {
                console.log('Completed displaying answer word by word.');
                console.log('Polling for name assignment with:', chatRoomId);
                pollForNameAssignment(chatRoomId);
            }
        };

        // Initialize the content of the message if it does not exist
        setMessages((prevMessages) => {
            const chatMessages = prevMessages[selectedChat] || [];
            const updatedMessages = chatMessages.map((msg, idx) =>
                idx === messageIdx + 1
                    ? { ...msg, content: '', loading: true }
                    : msg
            );

            return {
                ...prevMessages,
                [selectedChat]: updatedMessages
            };
        });

        // Start the word display process
        displayNextWord();
    };


    const handleEnter = async (e) => {
        if (e.key === 'Enter' && input.trim() !== "") {
            e.preventDefault();
            setIsPromptClick(true);
        }
    };

    const handlePromptClick = (prompt) => {
        setInput(prompt);
        setIsPromptClick(true);
        setConversationStarted(true);
        // handleSend();
    };


    const formatText = (text) => {
        if (!text) return null;
        const cleanText = text.replace(/【.*?】/g, '');

        const lines = cleanText.split('\n');

        return lines.map((line, index) => {
            if (!line.trim()) return null;

            // Handle headings
            const headingMatch = line.match(/^(#+)\s+(.*)/);
            if (headingMatch) {
                const level = headingMatch[1].length;
                const content = headingMatch[2].replace(/\*\*(.*?)\*\*/g, '$1');
                return <strong key={index}>{content}</strong>;
            }

            const numberedMatch = line.match(/^(\d+\.\s+)\*\*(.*?)\*\*$/);
            if (numberedMatch) {
                const numberPart = numberedMatch[1]; // e.g., "1. "
                const content = numberedMatch[2].trim(); // Get the text after ** and remove it
                return (
                    <div key={index}>
                        {numberPart}<strong>{content}</strong>
                    </div>
                );
            }

            // Handle list items with bold text
            if (line.startsWith('-**')) {
                const content = line.substring(3, line.length - 2).trim();
                return (
                    <ul>
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }
            if (line.startsWith('-**')) {
                const content = line.substring(8).trim();
                return (
                    <ul>
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }
            if (line.startsWith('   - **')) {
                const content = line.substring(7).trim().replace(/\*\*/g, ''); // Remove leading spaces and **
                return (
                    <ul >
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }
            if (line.startsWith('  - ')) {
                const content = line.substring(4).trim().replace(/\*\*/g, ''); // Remove leading spaces and **
                return (
                    <ul style={{ marginLeft: '10px' }}>
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }
            if (line.startsWith('   - ')) {
                const content = line.substring(4).trim().replace(/\*\*/g, ''); // Remove leading spaces and **
                return (
                    <ul style={{ marginLeft: '10px' }}>
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }

            if (line.startsWith('     - ')) {
                const content = line.substring(6).trim().replace(/\*\*/g, ''); // Remove leading spaces and **
                return (
                    <ul style={{ marginLeft: '10px' }}>
                        <li key={index} style={{ marginLeft: '10px' }}>{content}</li>
                    </ul>
                );
            }


            // Handle regular list items
            if (line.startsWith('-')) {
                const cleanLine = line.substring(1).trim();
                const contentWithoutBold = cleanLine.replace(/\*\*(.*?)\*\*/g, '$1');
                return <p style={{ marginLeft: '10px' }}><li key={index}>{contentWithoutBold}</li></p>;
            }

            // Handle bold text within a paragraph
            const parts = line.replace(/\*\*$/, '').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').split(/(<strong>.*?<\/strong>)/).map((part, idx) => {
                if (part.startsWith('<strong>') && part.endsWith('</strong>')) {
                    const cleanText = part.substring(8, part.length - 9);
                    return <strong key={idx}>{cleanText}</strong>;
                } else {
                    return <span key={idx}>{part}</span>;
                }
            });

            return <p key={index} style={{ textAlign: 'justify' }}>{parts}</p>;
        }).filter(component => component !== null);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (msgEnd.current) {
                const isScrolledToBottom = msgEnd.current.getBoundingClientRect().bottom <= window.innerHeight;
                setUserScrolledUp(!isScrolledToBottom);
            }
        };

        const chatContainer = msgEnd.current?.parentNode;
        if (chatContainer) {
            chatContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (chatContainer) {
                chatContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);


    const handleSpeechRecognition = () => {
        if (!('webkitSpeechRecognition' in window)) {
            toast.error("Speech recognition is not supported in this browser. Try using Chrome.");
            return;
        }

        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = 'en-US';
        recognition.continuous = false; 
        recognition.interimResults = false; 

        recognition.onstart = () => {
            setIsListening(true); 
        };

        recognition.onend = () => {
            setIsListening(false);
        };

        recognition.onresult = (event) => {
            const speechResult = event.results[0][0].transcript;
            setInput(speechResult); 
            // sendMessage(speechResult);
        };

        recognition.onerror = (event) => {
            toast.error('Speech recognition error: ' + event.error); 
        };

        recognition.start();
    };


    return (
        <>
            <div className="sidebar1">
                <div className="main">
                    <div className="chats">
                        <div className="chatbot-mobile-content">
                            <div className="" onClick={toggleSidebar}>
                                <i
                                    className={`fas ${isOpen ? "fa-chevron-left" : "fa-bars"}`}
                                ></i>
                            </div>
                            <p>AI ChatBot Minister</p>
                            <hr className="mobile-Chatbot-horizontal-line" />
                            <img src={mobileNewChat} alt="" onClick={startnewchat} />
                        </div>
                        <div className="all-chats">
                            {chatMessages.map((msg, index) => (
                                <div key={index} className={`chat ${msg.role}`}>
                                    <img className="chatimg" src={msg.role === 'user' ? "https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/user-icon.svg" : "https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/chatbot-log.svg"} alt="chat avatar" />
                                    {msg.type === 'file' ?
                                        <p className="chat-message">
                                            {msg.loading ? <span>thinking...</span> :
                                                <>
                                                    {msg.content} <br />
                                                    <a href={msg.fileUrl} target="_blank" rel="noopener noreferrer">Open File</a>
                                                </>}
                                        </p> :
                                        <p className="chat-message">
                                            {msg.loading ? <span>Thinking...</span> : formatText(msg.content)}
                                        </p>
                                    }
                                </div>
                            ))}
                        </div>
                        <div ref={msgEnd} />

                        {console.log(propmtsVisibel)}

                        {(showPrompts || propmtsVisibel) && (
                            <div>
                                <div className="mobile-prompts">
                                    <div className={`prompts ${conversationStarted ? 'hidden' : ''}`}>
                                        <button onClick={() => handlePromptClick("Hello! How can you assist me with my legal needs ?")}>Hello! How can you assist me with my legal needs ?</button>
                                        <button className='button2' onClick={() => handlePromptClick("Can you summarize the key points of my case ?")}>Can you summarize the key points of my case ?</button>
                                    </div>
                                    <div className={`prompts ${conversationStarted ? 'hidden' : ''}`}>
                                        <button onClick={() => handlePromptClick("What services do you offer ")}>What services do you offer ?</button>
                                        <button className='button2' onClick={() => handlePromptClick("How can you assist me with my case?")}>How can you assist me with my case?</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="chatFooter">
                    <div className="inp">
                        <div className="file-upload">

                            <div>
                                <button onClick={openModal} style={{ display: selectedFile ? 'none' : 'block', color: 'black', backgroundColor: 'transparent' }}>
                                    <img src={importfile} alt="" />
                                </button>
                                <button>
                                    <img src={microphoneImage} alt="" onClick={handleSpeechRecognition} disabled={loading} />
                                </button>
                                {/* {selectedFile && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginRight: '8px', color: 'black' }}>{selectedFile.name}</span>
                                        <button
                                            onClick={() => setSelectedFile(null)}
                                            style={{ marginLeft: 'auto', backgroundColor: 'black' }}
                                        >
                                            ×
                                        </button>
                                    </div>
                                )} */}
                            </div>
                        </div>
                        <input
                            type="text"
                            placeholder="Start typing...."
                            value={input}
                            onKeyDown={handleEnter}
                            onChange={(e) => setInput(e.target.value)}
                        />
                        <button className="send" onClick={() => { setIsPromptClick(true) }}>
                            <img src={sendmesg} alt="send" />
                        </button>
                    </div>
                    <div className='text-below-button'>
                        <p><span style={{ fontWeight: '600' }}>Disclaimer:</span> Information provided may be inaccurate and is not legal advice. </p>
                        <p> <span style={{ fontWeight: '600' }}>Note:</span> Information has been taken from eCourts.</p>
                    </div>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="File Upload Modal"
                    className="modal-overlay"
                    overlayClassName="modal-overlay"
                >
                    <div className="modal-content">
                        <div className="file-upload-modal">
                            <div className="modal-upper-section">
                                <h2 className="modal-heading">Upload Files</h2>
                                <button className="closeIcon" onClick={closeModal}>X</button>
                            </div>
                            <div className="modal-top">
                                <div className="modal-right-section">
                                    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                                        <input {...getInputProps()} />
                                        <img src={DragAndDrop} alt="" />
                                        {isDragActive ? (
                                            <p>Drop the files here ...</p>
                                        ) : (
                                            <div className="Browse_button">
                                                <p>Drop & Drag your files here
                                                    or</p>
                                                <button>Browse Files</button>
                                                {selectedFile && (
                                                    <div className="selected-files">
                                                        <ul>
                                                            {selectedFile.name}
                                                        </ul>
                                                    </div>
                                                )}
                                                <p className='belowPara'> <span style={{ fontWeight: '600' }}>Supported formats:</span> jpg, jpeg, png, gif, pdf
                                                    <br /><span style={{ fontWeight: '600' }}>Max Size:</span> 20 mb</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-buttons">
                                <button className="upload-button" onClick={handleSend} style={{ backgroundColor: '#000' }}>
                                    {loading ? 'please wait' : 'Upload'}</button>
                                <button className="upload-button" style={{ backgroundColor: 'transparent', color: 'black', border: '1px solid black', borderRadius: '10px' }} onClick={closeModal}>
                                    Cancel </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default Chat;
